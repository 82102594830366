import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Quote from "../Components/FounderMessage/Quote";
import Text from "../Components/FounderMessage/Text"

const FounderMessage = (props) => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    
    return <>
        <Grid container>
            <Quote />
            <Text />
        </Grid>
    </>
}

export default FounderMessage;