import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react';
import classes from '../../Assets/Styles/PileCubesPage.module.css';
import EnotecaImg from "../../Assets/Images/EnotecaImgg.png";
import StoryCircle from "../../Assets/Images/pilecubesStory.png";
import visitGoldCircle from "../../Assets/Images/visitGoldCircle.png";
import comingSoon from "../../Assets/Images/coming-sooon.png";
import windowDimensions from "../WindowDimension";

const EnotecaPage = (props) => {

    const [source, setSource] = useState(StoryCircle)
    const { windowWidth } = windowDimensions();


    return (
        <>
            <Grid container direction={"row"} marginTop={20}>
                <Grid item container md={1.4} xs={1} />
                {windowWidth > 900 ?

                    <Grid item container md={10.6} xs={11}>

                        <Grid item md={4.5} lg={5} paddingTop={3}>
                            <Grid item>
                                <span className={classes["Pilecubes"]}>
                                    Enoteca
                                </span>
                            </Grid>

                            <Grid item md={8}>
                                <span className={classes["Industry-SaaS"]}>
                                    Retail Digital Transformation
                                </span>
                            </Grid>

                            <Grid item paddingTop={4}>
                                <span className={classes["year"]}> Year </span>
                            </Grid>
                            <Grid item paddingTop={2} className={classes["date"]}>
                                2017
                            </Grid>

                            <Grid item paddingTop={4}>
                                <span className={classes["year"]}> Role </span>
                            </Grid>

                            <Grid item paddingTop={2} className={classes["role"]}>
                                <span>
                                    Roadmap - Product Map <br></br>
                                    Requirements Creation<br></br>
                                    Product and Information Architecture
                                </span>
                            </Grid>

                        </Grid>

                        <Grid item md={6} lg={5} position="relative" >
                            <Grid item 
                            // onMouseOut={() => { setSource(StoryCircle) }} onMouseOver={() => { setSource(visitGoldCircle) }}
                            >
                                <a href="https://stories.digitalprestige.com/StoryIn?id=F4061DAA-4CE3-ED11-AB7F-0207A10495E1"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: "none" }}>
                                    <img src={StoryCircle} width="20%" alt="story-design" className={classes["img-poistion"]} style={{ position: "absolute", right: 30, bottom: -30 }} />
                                </a>                            </Grid>
                            <img src={EnotecaImg} alt="Enoteca" width="100%" height="100%" />
                        </Grid>
                    </Grid>
                    :
                    <Grid item container md={10.5} xs={11}>
                        <Grid item md={5} xs={11} position="relative" >
                            <Grid item 
                            // onMouseOut={() => { setSource(StoryCircle) }} onMouseOver={() => { setSource(visitGoldCircle) }}
                            >
                                <a href="https://stories.digitalprestige.com/StoryIn?id=F4061DAA-4CE3-ED11-AB7F-0207A10495E1"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: "none" }}>
                                    <img src={StoryCircle} width="20%" alt="story-design" className={classes["img-poistion"]} style={{ position: "absolute", right: 30, bottom: -30 }} />
                                </a>
                            </Grid>
                            <img src={EnotecaImg} alt="Enoteca" width="100%" height="100%" />
                        </Grid>

                        <Grid item md={5} marginTop={5}>
                            <Grid item>
                                <span className={classes["Pilecubes"]}>
                                    Enoteca
                                </span>
                            </Grid>

                            <Grid item md={8}>
                                <span className={classes["Industry-SaaS"]}>
                                    Retail Digital Transformation
                                </span>
                            </Grid>

                            <Grid item paddingTop={4} >
                                <span className={classes["year"]}> Year </span>
                            </Grid>
                            <Grid item paddingTop={2} className={classes["date"]}>
                                2017
                            </Grid>

                            <Grid item paddingTop={4}>
                                <span className={classes["year"]}> Role </span>
                            </Grid>

                            <Grid item paddingTop={2} className={classes["role"]}>
                                <span>
                                    Roadmap - Product Map <br></br>
                                    Requirements Creation<br></br>
                                    Product and Information Architecture
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default EnotecaPage;