import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import classes from "../Assets/Styles/Home.module.css";
import { useNavigate } from "react-router-dom";



const MenuModal = (props) => {

    const [rotate, setRotate] = useState(false);
    const [rotate1, setRotate1] = useState(false);
    const [rotate2, setRotate2] = useState(false);

    useEffect(() => {
        handleOpen();
    }, [props.openPhoneMenuModal]);

    const handleOpen = () => {
        if (props.openPhoneMenuModal === true) {
            setOpen(true);
            setTimeout(() => { setRotate(true); }, 100)
            setTimeout(() => { setRotate1(true); }, 400)
            setTimeout(() => { setRotate2(true); }, 600)

        } else {
            setOpen(false);
            setRotate(false);
            setRotate1(false);
            setRotate2(false);
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        props.setOpenPhoneMenuModal(false);
    }
    let navigate = useNavigate();

    return (
        <>
            <Modal
                disableEnforceFocus
                keepMounted
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    style: { backgroundColor: "rgba(0, 0, 0, 0.14)" }
                }}
            >
                <Grid
                    width="100%"
                    height="100%"
                    container
                    style={{ outline: "none" }}
                    direction={"column"}
                >
                    <Grid item width="240px" className={!rotate ? classes["leftt"] : classes["leftt2"]} position="absolute" top="20%" zIndex={"0"} bgcolor="#000" height="400px">
                        <Grid item container onClick={() => { props.setOpenPhoneMenuModal(false); navigate("/dp-stories") }} className={classes["cursor"]} justifyContent="center" alignItems="center">
                            <Grid item className={classes["circle-menu2"]} />
                            <Grid item paddingLeft={1}>
                                <span className={classes["list"]}> Home</span>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item width="315px" className={rotate1 ? classes["rotate-first"] : classes["no-rotate"]} bgcolor="#ff461e" height="430px">
                        <Grid item container onClick={() => { props.setOpenPhoneMenuModal(false); navigate("/client-stories") }} paddingTop={2} className={classes["cursor1"]} justifyContent="center" alignItems="center">

                            <Grid item className={classes["circle-menu2"]} />
                            <Grid item paddingLeft={1}>
                                <span className={classes["list"]}> Client Stories</span>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item width="340px" position="absolute" zIndex={"3"} className={rotate2 ? classes["rotate-second"] : classes["no-rotate"]} bgcolor="#1F96C2" height="450px">
                        <Grid item container onClick={() => { props.setOpenPhoneMenuModal(false); navigate("/our-stories"); }} paddingTop={2.5} className={classes["cursor2"]} justifyContent="center" alignItems="center">
                            <Grid item className={classes["circle-menu2"]} />
                            <Grid item paddingLeft={1}>
                                <span className={classes["list"]}> Our Stories </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};

export default MenuModal;
