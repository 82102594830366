import Grid from "@mui/material/Grid";
import classes from "../../Assets/Styles/FounderMessage.module.css"
import FounderImg from "../../Assets/Images/founderMessageImage.jpg";
import rimeProfile1 from "../../Assets/Images/rimeProfile1.png";
import rimeProfile2 from "../../Assets/Images/rimeProfile2.png";
import Fade from '@mui/material/Fade';
import React, { useState, useEffect } from "react";
import windowDimensions from "../WindowDimension";


const Quote = (props) => {

    useEffect(() => {
        setTransition(true);
    }, []);

    const [transition, setTransition] = useState(false);
    const { windowWidth } = windowDimensions();


    return <>
        <Grid container alignItems="center" style={{ minHeight: '85vh' }} bgcolor="rgba(209, 181, 117)">
            {/* quote */}
            <Grid item container md={1.35} xs={1} />

            <Grid item container md={10.65} xl={9} xs={10.5} className={classes["text"]}>
                {windowWidth > 900
                    ?
                    <>
                        <Grid item container md={11} direction="row">
                            <Grid item container md={8}>
                                <Grid item marginTop={{ md: 7, xl: 10 }}>
                                    <span style={{ fontFamily: 'Nunito', fontSize: windowWidth > 1450 ? "270px" : "200px", margin: "0px", display: "inline-block", blockSize: "fit-content", lineHeight: "59px", height: "60px", color: "black" }}>"</span>

                                </Grid>
                                <Grid item container className={classes["methodology"]} alignItems="flex-end" >
                                    Methodology, <br></br>
                                    creativity and building
                                </Grid>

                            </Grid>
                            <Grid item container justifyContent={{ md: "start", xl: "center" }} md={4}>

                                <Fade in={transition} timeout={670}>
                                    <img src={rimeProfile2} className={classes["img-style"]} alt="FounderImg" />
                                </Fade>

                            </Grid>
                        </Grid>
                        <Grid item className={classes["methodology"]} >
                            technology teams is a passion.
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item container direction="row">

                            <Grid item container xs={6}>
                                <Grid item marginTop={{ xs: 2, sm: 5 }} >
                                    <span style={{ fontSize: windowWidth > 600 ? "155px" : "100px", margin: "0px", display: "inline-block", blockSize: "fit-content", lineHeight: "59px", height: "60px", color: "black" }}>"</span>

                                </Grid>
                                <Grid item container alignItems="flex-end" className={classes["img-text"]} >
                                    Methodology,
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="flex-end" alignItems="right" xs={6}>
                                <Fade in={transition} timeout={670}>
                                    <img src={rimeProfile2} className={classes["img-style"]} alt="FounderImg" />
                                </Fade>
                            </Grid>
                        </Grid>

                        <Grid>
                            creativity and building technology teams is a passion.
                        </Grid>
                    </>
                }
            </Grid>
        </Grid>
    </>
}

export default Quote;