import Grid from "@mui/material/Grid";
import "../../../Assets/Styles/OurStoriesId.css";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import blueDesign2 from "../../../Assets/Images/blueDesign2.png";
import blackNextArrow from "../../../Assets/Images/blackNextArrow.png";
import goldNextArrow from "../../../Assets/Images/goldNextArrow.png";
import closedBucket from "../../../Assets/Images/closedBucket.png";
import attachementIcon from "../../../Assets/Images/attachementIcon.png";
import openedBucket from "../../../Assets/Images/openBucket.png";
import pdfIcon from "../../../Assets/Images/pdfIcon.png";
import CircularProgress from "@mui/material/CircularProgress";
import goBack from "../../../Assets/Images/goBack.png";
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import storiesText from "../../../Assets/Images/storiesTextPdf.png";
import imageToBase64 from 'image-to-base64/browser';
import windowDimensions from "../../WindowDimension";

import * as ourServices from '../../../Services/our-story-services.proxy';

const ourStoryById = ourServices.ourStoryById;
const nextInsightStory = ourServices.nextInsightStory;

const InsightsById = (props) => {

    let navigate = useNavigate();

    const { windowWidth, windowHeight } = windowDimensions();

    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const query = useQuery();
    const storyId = query.get("Id");

    const downloadPdfBoxHeight = -196;
    const [loadingAni, setLoadingAni] = useState(false);
    const [nextImageSrc, setNextImageSrc] = useState(blackNextArrow);
    const [ourStory, setOurStory] = useState({});
    const [openBucket, setOpenBucket] = useState(false);
    const [anchorElShowAttachements, setAnchorElShowAttachements] = useState(null);
    const openShowAttachement = Boolean(anchorElShowAttachements);
    const [attachementWidth, setAttachementWidth] = useState(windowWidth > 500 ? 85 : 65);
    const [attachementHeight, setAttachementHeight] = useState(windowWidth > 500 ? 84 : 65)
    const [readMore, setReadMore] = useState(false);
    const [downloadingPdf, setDownloadingPdf] = useState(false);

    useEffect(() => {

        let isApiSubscribed = true;
        window.scrollTo(0, 0);
        setLoadingAni(true);
        setNextImageSrc(blackNextArrow);
        ourStoryById(storyId).then((response) => {
            if (!response.error && isApiSubscribed) {
                setOurStory(response)
                setLoadingAni(false);
            }
        })

        return () => {
            isApiSubscribed = false;
        };

    }, [storyId])

    const handleNextStory = () => {
        nextInsightStory(storyId).then((response) => {
            if (!response.error) {
                navigate(`/Insight?Id=${response}`)
            }
        })
    }

    const showAttachementsHandler = (event) => {
        setOpenBucket(!openBucket);
        setAnchorElShowAttachements(event.currentTarget);
    }

    const handleCloseAttachements = () => {
        setAnchorElShowAttachements(null);
        setOpenBucket(!openBucket)
    }

    const downloadPdfHandler = () => {
        var doc = new jsPDF('landscape', 'mm', [310, 310]);

        doc.setFont('PlayfairDisplay');
        doc.setFontSize(25)
        doc.text('DIGITAL PRESTIGE', 150, 10, 'center');
        doc.addImage(storiesText, 167, 6, 28, 15);
        doc.setFillColor("#87c9fb");
        doc.rect(0, 50, 310, 120, 'F');
        doc.addImage(blueDesign2, 9, 70)
        imageToBase64(`${ourStory.ExecutionPhoto?.URL}?cacheblock=true`) // Path to the image
            .then(
                (response) => {
                    doc.addImage(response, 65, 60, 170, 100)
                    doc.setFont('Helvetica');
                    doc.setFontSize(48);
                    doc.text(ourStory.Title, 150, 240, { align: "center", maxWidth: 267 });
                    doc.addPage();
                    var x = document.querySelector("#introduction");
                    x.style.fontSize = "8px";
                    x.style.fontFamily = "Helvetica";
                    x.style.fontWeight = "100"
                    doc.html(x, {
                        callback: function (doc) {
                            var introductionTextHeight = 0;
                            if (15 + 7 + x.offsetHeight > 162) {
                                doc.addPage();
                                doc.setFillColor("#87c9fb");
                                doc.rect(35, 15, 240, 125, 'F');
                                imageToBase64(`${ourStory.ProcessPhoto.URL}?cacheblock=true`) // Path to the image
                                    .then((response) => {
                                        doc.addImage(response, 126, 27, 127, 100);
                                        doc.setFontSize(30);
                                        doc.setFont('Helvetica');
                                        doc.text("PROCESS", 35, 165, { align: "left", maxWidth: 240 });
                                        var x = document.querySelector("#process");
                                        x.style.fontSize = "8px";
                                        x.style.fontFamily = "Helvetica"
                                        x.style.fontWeight = "100"
                                        var processTextHeight = x.offsetHeight;
                                        doc.html(x, {
                                            callback: function (doc) {
                                                var executionTitle = document.querySelector("#text");
                                                executionTitle.style.fontSize = "12px";
                                                executionTitle.style.fontFamily = "Helvetica";
                                                doc.html(executionTitle, {
                                                    callback: function (doc) {
                                                        var executionText = document.querySelector("#execution");
                                                        executionText.style.fontSize = "8px";
                                                        executionText.style.fontFamily = "Helvetica"
                                                        executionText.style.fontWeight = "100"
                                                        doc.html(executionText, {
                                                            callback: function (doc) {
                                                                doc.save(`${ourStory.Title}.pdf`);
                                                                setDownloadingPdf(false);
                                                            },
                                                            x: 35,
                                                            y: 800 + processTextHeight + 42,
                                                        });
                                                    },
                                                    x: 35,
                                                    y: 800 + processTextHeight + 15,
                                                });
                                            },
                                            x: 35,
                                            y: 800
                                        });
                                    })
                            }
                            else {
                                introductionTextHeight = 169;
                                doc.setFillColor("#87c9fb");
                                doc.rect(35, introductionTextHeight, 240, 125, 'F');
                                imageToBase64(`${ourStory.ProcessPhoto.URL}?cacheblock=true`) // Path to the image
                                    .then((response) => {
                                        doc.addImage(response, 126, introductionTextHeight + 13, 127, 100);
                                        doc.addPage();
                                        doc.setFont('Helvetica');
                                        doc.setFontSize(30);
                                        doc.text("PROCESS", 35, 20, { align: "left", maxWidth: 240 });
                                        var x = document.querySelector("#process");
                                        x.style.fontSize = "8px";
                                        x.style.fontFamily = "Helvetica"
                                        x.style.fontWeight = "100"
                                        var processTextHeightt = x.offsetHeight;
                                        doc.html(x, {
                                            callback: function (doc) {
                                                var executionTitle = document.querySelector("#text");
                                                executionTitle.style.fontSize = "12px";
                                                executionTitle.style.fontFamily = "helvetica";
                                                doc.html(executionTitle, {
                                                    callback: function (doc) {
                                                        var executionText = document.querySelector("#execution");
                                                        executionText.style.fontSize = "8px";
                                                        executionText.style.fontFamily = "Helvetica"
                                                        executionText.style.fontWeight = "100"
                                                        doc.html(executionText, {
                                                            callback: function (doc) {
                                                                doc.save(`${ourStory.Title}.pdf`);
                                                                setDownloadingPdf(false);
                                                            },
                                                            x: 35,
                                                            y: 653 + processTextHeightt + 47,
                                                        });
                                                    },
                                                    x: 35,
                                                    y: 653 + processTextHeightt + 20,
                                                });

                                            },
                                            x: 35,
                                            y: 653
                                        });
                                    })
                            }
                        },
                        x: 35,
                        y: 325,
                    });
                }
            )
    }

    const goBackHandler = () => {
        navigate(-1);
    }

    return (
        <>
            <Grid container overflow={"hidden"} position="relative">
                {loadingAni ?
                    <Grid item container paddingBottom={15} justifyContent="center" alignItems="center" height={windowHeight}>
                        <CircularProgress size={40} sx={{ color: "#1F96C2" }} />
                    </Grid>
                    :
                    <>
                        <Grid item container direction="column">
                            <Grid item paddingLeft={{ xs: 6, md: 4 }} paddingTop={{ xs: 2, md: 0 }} paddingBottom={{ xs: 2, sm: 5, md: 0 }} onClick={() => goBackHandler()}>
                                <img src={goBack} width={windowWidth > 899 ? "auto" : "8%"} alt="go back" style={{ cursor: "pointer" }} />
                            </Grid>

                            <Grid item container justifyContent="center" >

                                <Grid item container position="relative" xs={12} justifyContent="center" alignItems="center" height={{ sm: "470px", md: "500px", xs: "310px", lg: "470px", xl: "675px" }} bgcolor="#87c9fb">
                                    <Grid item xs={8.4} md={6.6} zIndex={2} height={{ xs: "245px", sm: "400px", md: "415px", lg: "370px", xl: "595px" }} className={"img-contain"}>
                                        <img id="photo" src={ourStory.ExecutionPhoto && ourStory.ExecutionPhoto?.URL} alt="cover image" width="100%" height="100%" />
                                    </Grid>
                                    <img src={blueDesign2} className={"blue-design2"} alt="blue design" />

                                </Grid>


                                <Grid item container zIndex={2} md={8.5} xs={10} alignContent="center" justifyContent="center" paddingTop={{ xs: 8, md: 16, lg: 20 }} className={"story-title"}>
                                    {ourStory.Title}
                                </Grid>

                                <Grid item container paddingTop={{ md: 30, xs: 12 }} xs={9.5} md={8.5} gap={{ md: 20, xs: 13 }}>
                                    <Grid item paddingTop={{ xs: 2, md: 0 }} xs={12} className={"Idea-text"}>
                                        <div style={{ position: "absolute", left: "-3000px" }}>
                                            <div id="introduction">
                                                <span style={{ overflowWrap: "break-word", maxlength: "20" }} dangerouslySetInnerHTML={{ __html: ourStory.Introduction }} />
                                            </div>
                                        </div>
                                        <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: ourStory.Introduction }} />
                                    </Grid>
                                </Grid>

                                <  Grid item container justifyContent="center" alignItems="center" marginTop={{ md: 20, xs: 13 }} xs={12} md={9} height={{ xs: "300px", sm: "455px", md: "465px", lg: "490px", xl: "570px" }} bgcolor="#87c9fb">
                                    <Grid item md={2.5} sm={2} xs={0} lg={2} xl={2.5} />
                                    <Grid item md={8} xs={9} sm={7} lg={7} xl={6} >
                                        <Grid item xs={12} height={{ xs: "245px", sm: "325px", md: "350px", lg: "363px", xl: "390px" }} className={"Rectangle-948"}>
                                            <img src={ourStory.ProcessPhoto && ourStory.ProcessPhoto.URL} alt="cover image" width="100%" height="100%" />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item container paddingTop={{ md: 20, xs: 10 }} xs={9.5} md={8.5} gap={{ md: 23, xs: 13 }}>
                                    <Grid item container>
                                        <Grid item xs={12} alignSelf="flex-start" className={"Idea"}>
                                            PROCESS
                                        </Grid>

                                        <Grid item paddingTop={7} xs={12} className={"Idea-text"}>
                                            <div style={{ position: "absolute", left: "-3000px" }}>
                                                <div id="process">
                                                    <span style={{ overflowWrap: "break-word", maxlength: "20" }} dangerouslySetInnerHTML={{ __html: ourStory.Process }} />
                                                </div>
                                            </div>
                                            <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: ourStory.Process }} />
                                        </Grid>
                                    </Grid>


                                    <Grid item container alignItems="center" position="relative">
                                        <Grid item xs={6} className={"Idea"}>
                                            <div style={{ position: "absolute", left: "-3000px" }}>
                                                <div id="text">
                                                    EXECUTION
                                                </div>
                                            </div>
                                            EXECUTION
                                        </Grid>

                                        <Grid item xs={6} container justifyContent="flex-end" className={"Idea"}>
                                            {ourStory.AttachementIds?.length > 0 ? openBucket ?
                                                <Grid item container direction="column" style={{ cursor: "pointer" }} justifyContent="center" onClick={(e) => { showAttachementsHandler(e) }} alignItems="center" bgcolor="#ececec" width={attachementWidth} height={attachementHeight}>
                                                    <Grid item >
                                                        <img src={openedBucket} width={windowWidth > 500 ? "35px" : "27px"} height={windowWidth > 500 ? "35px" : "27px"} alt="closed bucket" />
                                                    </Grid>
                                                    <Grid item className={"attachments"}>
                                                        attachements
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid item container direction="column" style={{ cursor: "pointer" }} justifyContent="center" onClick={(e) => { showAttachementsHandler(e) }} alignItems="center" bgcolor="#ececec" width={attachementWidth} height={attachementHeight}>
                                                    <Grid item>
                                                        <img src={closedBucket} width={windowWidth > 500 ? "35px" : "27px"} height={windowWidth > 500 ? "35px" : "27px"} alt="closed bucket" />
                                                    </Grid>
                                                    <Grid item className={"attachments"}>
                                                        attachements
                                                    </Grid>
                                                </Grid>
                                                :
                                                <></>
                                            }
                                        </Grid>

                                        <Grid item xs={12} paddingTop={5} className={readMore ? "Idea-text" : "clamped-Idea-text"}>
                                            <div style={{ position: "absolute", left: "-3000px" }}>
                                                <div id="execution">
                                                    <span style={{ overflowWrap: "break-word", maxlength: "20" }} dangerouslySetInnerHTML={{ __html: ourStory.Execution }} />
                                                </div>
                                            </div>
                                            <span style={{ overflowWrap: "break-word", maxlength: "20" }} dangerouslySetInnerHTML={{ __html: ourStory.Execution }} />

                                        </Grid>

                                        {!readMore ?
                                            <Grid item position="absolute" bottom={downloadPdfBoxHeight} container direction="column" justifyContent="center" alignItems="center" gap={0.5} marginTop={4}>
                                                <Grid item onClick={() => { setReadMore(true) }} className={"read-more"}>
                                                    READ MORE
                                                </Grid>

                                                <Grid item className={"or"}>
                                                    or
                                                </Grid>

                                                <Grid item onClick={(e) => { setDownloadingPdf(true); setTimeout(() => { downloadPdfHandler() }, 50) }} className={"pdf-box"}>
                                                    {downloadingPdf ?
                                                        <Grid item container width="100px" height="106px" justifyContent="center" alignItems="center" className={"pdf-box2"}>
                                                            Downloading...
                                                        </Grid> :
                                                        <>
                                                            <Grid item>
                                                                <Grid item className={"DOWNLOAD"}>
                                                                    DOWNLOAD
                                                                </Grid>
                                                                <Grid item container paddingTop={0.5} justifyContent="center">
                                                                    <img src={pdfIcon} style={{ cursor: "pointer" }} alt="closed bucket" />
                                                                </Grid>
                                                            </Grid></>}
                                                </Grid>
                                            </Grid>

                                            :

                                            <Grid item container direction="column" justifyContent="center" alignItems="center" gap={1.5} marginTop={4}>
                                                {downloadingPdf ?
                                                    <Grid item container width="120px" height="120px" justifyContent="center" alignItems="center" className={"pdf-box2"}>
                                                        Downloading...
                                                    </Grid>
                                                    :
                                                    <Grid item onClick={() => { setDownloadingPdf(true); setTimeout(() => { downloadPdfHandler() }, 50) }} className={"pdf-box"}>
                                                        <Grid item className={"DOWNLOAD"}>
                                                            DOWNLOAD
                                                        </Grid>
                                                        <Grid item container paddingTop={0.5} justifyContent="center">
                                                            <img src={pdfIcon} alt="closed bucket" />
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* {ourStory?.Total === 1 && */}

                                <Grid item container paddingTop={!readMore ? 35 : 15} paddingBottom={5} paddingRight={windowWidth < 600 ? 3 : 10} justifyContent="flex-end">
                                    <Grid item xs={6} lg={4}>
                                        <img src={nextImageSrc}
                                            width="100%"
                                            onClick={() => { handleNextStory() }}
                                            onMouseOver={(e) => { e.target.style.cursor = "pointer"; setNextImageSrc(goldNextArrow) }}
                                            onMouseOut={() => { setNextImageSrc(blackNextArrow) }}
                                            alt="next story"
                                        />
                                    </Grid>
                                </Grid>
                            {/* } */}
                        </Grid>
                    </>
                }
            </Grid>

            <Menu
                anchorEl={anchorElShowAttachements}
                id="account-menu"
                open={openShowAttachement}
                onClose={handleCloseAttachements}
                PaperProps={{
                    elevation: 2,
                    sx: {
                        overflow: 'visible',
                        boxShadow: '0 0',
                        padding: 0,
                        margin: 0,
                        backgroundColor: "transparent",
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Grid item container style={{ maxWidth: windowWidth > 500 ? "388px" : "219px" }} direction={"row"} gap={1}>
                    {ourStory.AttachementIds?.map((attachement, index) => (
                        <>
                            <Link key={index} href={attachement.URL} color="black" underline="none">
                                <Grid item paddingTop={1} paddingBottom={1} width={attachementWidth} height={attachementHeight} bgcolor="#ececec" onClick={() => handleCloseAttachements()} style={{ cursor: "pointer" }}>
                                    <Tooltip title={attachement.Name} placement="bottom">
                                        <Grid item xs={12} container justifyContent="center" alignItems="center">
                                            <img src={attachementIcon} width={windowWidth < 500 ? "32px" : ""} height={windowWidth < 500 ? "35px" : ""} alt={"attachement"} />
                                        </Grid>
                                    </Tooltip>
                                    <Grid item className={"attachement"}>
                                        {attachement.Name}
                                    </Grid>
                                </Grid>
                            </Link>
                        </>
                    ))}
                </Grid>
            </Menu>
        </>
    )
}

export default InsightsById;