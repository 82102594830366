import Grid from "@mui/material/Grid";
import React, { useEffect, useRef } from 'react'
import classes from '../../Assets/Styles/WhoAreWe.module.css';
import windowDimensions from "../WindowDimension";

const HowWeWork = (props) => {
    
    let ref = useRef();

    const {windowWidth} = windowDimensions();

    useEffect(() => {
        props.setHowWeWorkPosition(ref?.current?.clientHeight);
    }, [windowWidth, props.scrollToContactUsForm])

    return (<>
        <Grid container ref={ref} zIndex="1" direction={"row"} maxWidth={'xl'}>
            <Grid item container md={1.5} xs={1} />
            <Grid item container md={10.5} xs={11}>

                <Grid item paddingBottom={{ xs: 3, md: 0 }} paddingTop={{xs:8, md:1}} xs={12} md={2.5} lg={2} >
                    <span className={classes["WHO-ARE-WE"]}>
                        HOW WE WORK?
                    </span>
                </Grid>

                <Grid item  md={9.5} lg={10}>
                    <Grid item>
                        <span className={classes["NOT-ANOTHER-FULL-STACK-PRODUCT-COMPANY"]}>
                            Work{" "}
                            <span className={classes['company']}>Strategy</span>
                        </span>
                    </Grid>
                    <Grid item paddingTop={{ md: 6, xs: 4 }} paddingBottom={{ xs: 20, md: 25 }} xs={11} md={8.5} lg={8} sm={10.5}>

                        <span className={classes["WhoAreWe-text"]}>
                            We work in a customer centric manner. Our process oriented teams reduce error margins and deliver faster.<br></br> <br></br>
                            The user experience and design squads customize your product specifically for your industry. Value innovation is our goal.
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>)
}

export default HowWeWork;