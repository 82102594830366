import React from "react";
import { useLottie } from "lottie-react";
import BodyAnimation2 from "../../Assets/Animations/BodyAnimation2";


export default function LoadingAnimation() {
  const options = {
    animationData: BodyAnimation2,
    loop: true,
    autoplay: true,
  };

  const style={
      position:"absolute",
      padding: 0,
      left:-50,
      width:"45%",
      zIndex:"-1",
      
  }

  const { View } = useLottie(options, style);
  
  return (
    <>
      {View}
    </>
  );
}