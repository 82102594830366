import Grid from "@mui/material/Grid";
import classes from "../../../Assets/Styles/ClientStories.module.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import nextRedArrow from "../../../Assets/Images/nextRedArrow.png";
import nextBlackArrow from "../../../Assets/Images/nextBlackArrow.png";
import previousBlackArrow from "../../../Assets/Images/previousBlackArrow.png";
import previousRedArrow from "../../../Assets/Images/previousRedArrow.png";
import CircularProgress from "@mui/material/CircularProgress";
import windowDimensions from "../../WindowDimension";

import * as clientServices from '../../../Services/client-story-services.proxy';

const clientStoriesTitlesList = clientServices.clientStoriesTitlesList;

const Home = (props) => {

    const [pageNumber, setPageNumber] = useState(1);

    let navigate = useNavigate();
    
    const { windowWidth, windowHeight } = windowDimensions();
    const [nextImageSrc, setNextImageSrc] = useState(nextBlackArrow);
    const [previousImageSrc, setPreviousImageSrc] = useState(previousBlackArrow);
    const [clientStories, setClientStories] = useState([]);
    const [pageSize, setPageSize] = useState(8);
    const [moveAni, setFadeAni] = useState(false);
    const [loadingAni, setLoadingAni] = useState(false);
    const [arryLength, setArryLength] = useState("");

    const data = {
        PageSize: pageSize,
        PageNumber: pageNumber
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pageNumber, loadingAni])

    useEffect(() => {

        let isApiSubscribed = true;
        setTimeout(() => { setFadeAni(true); }, 0.1)
        setLoadingAni(true);
        setNextImageSrc(nextBlackArrow);
        setPreviousImageSrc(previousBlackArrow);
        clientStoriesTitlesList(data).then((response) => {
            if (!response.error && isApiSubscribed) {
                setClientStories(response.ClientStories);
                setArryLength(response.Total);
                setLoadingAni(false);

            }
        })
        return () => {
            isApiSubscribed = false;
        };

    }, [pageNumber])

    return (
        <>
            <Grid container>
                {loadingAni ?
                    <Grid item container paddingBottom={15} justifyContent="center" alignItems="center" height={windowHeight + 50}>
                        <CircularProgress size={40} sx={{ color: "#c8a047" }} />
                    </Grid>
                    :
                    <>
                        <Grid item container padding={{ xs: "12% 9% 3% 9%", md: "4% 8% 3% 8%", lg: "2% 8% 1% 8%", xl: "4% 11% 3% 11%" }}>
                            {pageNumber === 1 &&
                                <Grid item container justifyContent="center" bgcolor="#ff461e">
                                    <Grid item paddingTop={{ xs: 2.5, xl: 3.5 }} paddingBottom={{ xs: 0, md: 2.5, xl: 3.5 }} className={classes["Client-Stories"]}>
                                        CLIENT STORIES
                                    </Grid>

                                    <Grid item container alignItems="center" justifyContent="center" padding={{ xs: "0 4% 7% 4%", md: "0 4% 1.7% 4%" }} gap={{ md: 2, lg: 2.5 }}>
                                        {clientStories ? arryLength === 1 ?
                                            <>
                                                {clientStories?.slice(0, 1).map((item, index) => (
                                                    <Grid item md={8} xs={10} sm={9} lg={7} xl={6} key={index}>
                                                        <Grid item md={11.5} marginTop={2.5} alignSelf="flex-start" className={classes["img-grid"]}>
                                                            <Link to={`/client-stories/${item.Id}`}>
                                                                <img src={item.CoverPhoto && item.CoverPhoto?.URL} width="100%" height="100%" lt="cover image" />
                                                            </Link>
                                                        </Grid>
                                                        <Grid item container alignSelf="flex-start" xs={10} gap={1} md={11.5} marginTop={1} sm={9} className={classes["tech2"]} >
                                                            {item.Tags?.map((tag, index) => (
                                                                <span key={index} className={classes["Rectangle-989"]}> {tag} </span>
                                                            ))}
                                                        </Grid>
                                                        <Grid item marginTop={0.5} >
                                                            <Link to={`/client-stories/${item.Id}`} className={classes["podcast-title"]}>
                                                                {item.Title}
                                                            </Link>
                                                        </Grid>

                                                        <Grid item md={11} paddingTop={1} className={classes["client-story-brief"]}>
                                                            <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: item.Brief }} />
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {clientStories ? clientStories?.slice(0, 2)?.map((item, index) => (
                                                    <>
                                                        <Grid item xs={12} md={5.8} lg={5.5} xl={5.5} container key={index} alignSelf="flex-start" justifyContent="center">
                                                            <Grid item md={11.5} xs={10} sm={9} marginTop={{ xs: 2.5, md: 0 }} className={classes["img-grid2"]}>
                                                                <Link to={`/client-stories/${item.Id}`}>
                                                                    <img src={item.CoverPhoto && item.CoverPhoto?.URL} width="100%" height="100%" alt="cover image" />
                                                                </Link>
                                                            </Grid>
                                                            <Grid item container xs={10} marginTop={1} md={11.5} sm={9} gap={1} alignSelf="flex-start" className={classes["tech2"]} >
                                                            {item.Tags?.map((tag, index) => (
                                                                <span key={index} className={classes["Rectangle-989"]}> {tag} </span>
                                                            ))}
                                                        </Grid>
                                                            <Grid item xs={10} marginTop={0.5} md={11.5} sm={9} >
                                                                <Link to={`/client-stories/${item.Id}`} className={classes["podcast-title"]}>
                                                                    {item.Title}
                                                                </Link>
                                                            </Grid>

                                                            <Grid item xs={10} paddingTop={{ xs: 0.5, md: 1 }} md={11.5} sm={9} className={classes["client-story-brief"]}>
                                                                <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: item.Brief }} />
                                                            </Grid>
                                                        </Grid>
                                                    </>

                                                ))
                                                    :
                                                    <></>}
                                            </>
                                            :
                                            <></>
                                        }
                                    </Grid>
                                </Grid>}

                            <Grid item container paddingTop={pageNumber > 1 ? 3 : 10} gap={{ sm: 2, md: 1.5, lg: 2, xl: 2.4 }} justifyContent="center">
                                {clientStories && pageNumber === 1 ? arryLength > 2 ?
                                    <>
                                        {clientStories?.slice(2, arryLength)?.map((item, index) => (
                                            <>
                                                <Grid item container key={index} alignSelf="flex-start" md={3.85} sm={5.8} paddingBottom={5}>
                                                    <Grid item className={classes["Rectangle-982"]}>
                                                        <Link to={`/client-stories/${item.Id}`}>
                                                            <img src={item.CoverPhoto && item.CoverPhoto?.URL} width="100%" height="100%" lt="cover image" />
                                                        </Link>

                                                    </Grid>
                                                    <Grid item container gap={1} alignSelf="flex-start" marginTop={1.5} className={classes["tech"]} >
                                                            {item.Tags?.map((tag, index) => (
                                                                <span key={index} className={classes["Rectangle-988"]}> {tag} </span>
                                                            ))}
                                                        </Grid>
                                                    <Grid item container alignSelf="flex-start" paddingTop={0.7} xs={12} >
                                                        <Link to={`/client-stories/${item.Id}`} className={classes["THE-TITLE-OF-THE-PODCAST"]} >
                                                            {item.Title}
                                                        </Link>
                                                    </Grid>

                                                    <Grid item paddingTop={1} xs={12} className={classes["small-description"]}>
                                                        <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: item.Brief }} />
                                                    </Grid>
                                                </Grid>
                                            </>))}
                                    </>
                                    :
                                    <>
                                    </>
                                    : <>
                                        {clientStories?.map((item, index) => (
                                            <>
                                                <Grid item container key={index} alignSelf="flex-start" md={3.85} sm={5.8} paddingBottom={5}>
                                                    <Grid item className={classes["Rectangle-982"]} onClick={() => { navigate(`/client-stories/${item.Id}`) }}>
                                                        <img src={item.CoverPhoto && item.CoverPhoto?.URL} width="100%" height="100%" lt="cover image" />

                                                    </Grid>
                                                    <Grid item container gap={1} alignSelf="flex-start" marginTop={1.5} className={classes["tech"]} >
                                                            {item.Tags?.map((tag, index) => (
                                                                <span key={index} className={classes["Rectangle-988"]}> {tag} </span>
                                                            ))}
                                                        </Grid>
                                                    <Grid item container alignSelf="flex-start" paddingTop={0.7} xs={12} onClick={() => { navigate(`/client-stories/${item.Id}`) }} className={classes["THE-TITLE-OF-THE-PODCAST"]}>
                                                        {item.Title}
                                                    </Grid>

                                                    <Grid item paddingTop={1} xs={12} className={classes["small-description"]}>
                                                        <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: item.Brief }} />
                                                    </Grid>
                                                </Grid>
                                            </>))}
                                    </>
                                }

                            </Grid>

                        </Grid>
                        {pageSize > arryLength && pageNumber === 1 ?
                            <></>
                            :
                            <Grid item container paddingBottom={8}>
                                <Grid item md={1} xs={1} />

                                <Grid item md={5} xs={5}>
                                    {pageNumber > 1 &&
                                        <img src={previousImageSrc}
                                            style={{ cursor: "pointer" }}
                                            width={"27%"}
                                            onClick={() => { setLoadingAni(true); setPageNumber(pageNumber - 1) }}
                                            onMouseOver={() => { setPreviousImageSrc(previousRedArrow) }}
                                            onMouseOut={() => { setPreviousImageSrc(previousBlackArrow) }}
                                            alt="blackArrow" />
                                    }
                                </Grid>

                                <Grid item container justifyContent="flex-end" md={5} xs={5}>
                                    {pageNumber * pageSize >= arryLength ? <></> :
                                        <img src={nextImageSrc}
                                            style={{ cursor: "pointer" }}
                                            width={"27%"}
                                            onClick={() => { setLoadingAni(true); setPageNumber(pageNumber + 1) }}
                                            onMouseOver={() => { setNextImageSrc(nextRedArrow) }}
                                            onMouseOut={() => { setNextImageSrc(nextBlackArrow) }}
                                            alt="blackArrow" />
                                    }
                                </Grid>
                            </Grid>
                        }
                    </>}
            </Grid>
        </>
    )
}

export default Home;