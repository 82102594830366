import React, { useState, useEffect } from 'react';
import WebFont from "webfontloader";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Navbar from './Routes/Navbar';
import Footer from './Routes/Footer';
import AboutUsPage from './Components/AboutUs/AboutUsPage';
import DPMenu from './Pages/MyModal';
import TermsAndConditions from './Pages/TermsAndConditions';
import OurMissionPage from './Pages/OurMissionPage';
import CaseStudies from './Pages/CaseStudies';
import DPStories from './Pages/DPStories';
import ClientStories from './Pages/ClientStories';
import ClientStoryId from './Pages/ClientStoryId';
import FounderMessage from './Pages/FounderMessage';
import Services from "./Pages/Services";
import Industries from "./Pages/Industries";
import Grid from "@mui/material/Grid";
import windowDimensions from "./Components/WindowDimension";
import StickyContactUs from './Components/StickyContactUs';
import { Helmet, HelmetProvider } from "react-helmet-async";
import OurStories from "./Pages/OurStories";
import BTSList from "./Pages/BTSList";
import InsightsList from "./Pages/InsightsList";
import InsightsById from "./Pages/InsightsById";
import BTSById from "./Pages/BTSById";
import StoriesMenu from "./Pages/MenuModal";


const style = {
  position: 'fixed',
  zIndex: "15",
  bottom: "34px",
  right: "-20px"
}
function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "Great Vibes", "Nunito"],
      },
    });

  }, []);

  const { windowWidth } = windowDimensions();
  const [height, setHeight] = useState(0);
  const [navHeight, setNavHeight] = useState(0);
  const [hideNav, setHideNav] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [moveVerticalLines, setMoveVerticalLines] = useState(true);
  const [scrollToContactUsForm, setScrollToContactUsForm] = useState(false);
  const [contactFormPosition, setContactFormPosition] = useState(0);
  const [hideContactUsTab, sethideContactUsTab] = useState(true);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (scrollToContactUsForm) {
      const extraPosition = windowWidth < 899 ? windowWidth < 400 ? 200 : 130 : 100
      window.scrollTo({ top: contactFormPosition + navHeight + extraPosition, behavior: 'smooth' });
      setScrollToContactUsForm(false)
    }

  }, [scrollToContactUsForm, contactFormPosition, navHeight])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
      setHideNav(false);
    }
  })

  useEffect(() => {
    if (hideNav) sethideContactUsTab(true)
    else sethideContactUsTab(false)
  }, [hideNav])

  const [scrolled, setScrolled] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [myModalBoldButton, setMyModalBoldButton] = useState(true);
  const [openPhoneMenuModal, setOpenPhoneMenuModal] = useState(false);

  return (

    <Router>
      <HelmetProvider>
        <Helmet>
          <title> {`DP Home Page`} </title>
          <meta property="og:description"
            content="We turn your problem into an idea, your idea into a solution, and your solution into a digital product. We launch your minimum viable product (MVP) or scale your digital product embracing Agile to increase development speed, expand collaboration, and foster the ability to better respond to market trends." />                <meta name="image" property="og:image" content="https://testimgg.s3.eu-west-1.amazonaws.com/DPLogo.png" />
          <meta property="og:url" content="https://www.digitalprestige.com/" />
          <meta
            name="keywords"
            content="Digital Prestige, Digital Transformation, Tech Organziation, Launch tech innovation, Product company, Quality software, Lebanon, Beirut, UAE, Dubai, MENA, end-to-end development, technological solutions, software delivery, global standards, Digial culture, leadership excellence, software delivery, Software Development Life Cycle, agile methodology, project management, agile manifesto, start-ups companies, delivery processes, oriented teams, idea, minimum viable products, MVP, digital prestige academy, agile courses, agile academy, agile workshop, agile process development, agile team worshop, digital culture development, agile release planning workshop, Agile manifesto, start-ups companies, delivery processes, oriented teams, minimum viable product, MVP, expand collaboration, Product creation, Business design, Work Strategy, saas, software platforms, mobile applications, aws, java, nodejs, react, kuwait, typescript, strategy, vision, technical expertise, tech services, ERP, hosting, tech experts, we development, cloud, digital services, websites, leading digital transformation, developing software, retail digital transformation, scrum"
          />
          <link rel="canonical" href={`/`} />
        </Helmet>
      </HelmetProvider>


      {!hideContactUsTab &&
        <Grid item style={style}>
          <StickyContactUs scrollToContactUsForm={scrollToContactUsForm} setScrollToContactUsForm={setScrollToContactUsForm} />
        </Grid>}

      {!hideNav ? <Navbar myModalBoldButton={myModalBoldButton} setMyModalBoldButton={setMyModalBoldButton} setOpenModal={setOpenModal} hideNav={hideNav} setHideNav={setHideNav} height={height} scrollPosition={scrollPosition} setNavHeight={setNavHeight} /> : <></>}
      <DPMenu myModalBoldButton={myModalBoldButton} setMyModalBoldButton={setMyModalBoldButton} hideNav={hideNav} openModal={openModal} setOpenModal={setOpenModal} setHideNav={setHideNav} />
      <StoriesMenu setOpenPhoneMenuModal={setOpenPhoneMenuModal} openPhoneMenuModal={openPhoneMenuModal} />

      <Routes>
        <>
          <Route
            exact
            path="/"
            element={<>
              <AboutUsPage setScrollToContactUsForm={setScrollToContactUsForm} scrollToContactUsForm={scrollToContactUsForm} setContactFormPosition={setContactFormPosition} setHeight={setHeight} />
            </>} />

          <Route
            exact
            path="/privacy-policy-and-terms"
            element={<>
              <TermsAndConditions />
            </>} />

          <Route
            exact
            path="/our-values"
            element={<> <OurMissionPage setHeight={setHeight} /> </>}
          />

          <Route
            exact
            path="/a-message-from-the-founder"
            element={<> <FounderMessage />
            </>}
          />

          <Route
            exact
            path="/case-studies"
            element={<> <CaseStudies setHeight={setHeight} />
            </>}
          />


          <Route
            exact
            path="/client-stories"
            element={<><ClientStories setOpenPhoneMenuModal={setOpenPhoneMenuModal} setMoveVerticalLines={setMoveVerticalLines} setHideNav={setHideNav} scrolled={scrolled} /></>}
          />

          <Route
            exact
            path="/client-stories/:storyId"
            element={<ClientStoryId setOpenPhoneMenuModal={setOpenPhoneMenuModal} setMoveVerticalLines={setMoveVerticalLines} setHideNav={setHideNav} setScrolled={setScrolled} scrolled={scrolled} />}
          />

          <Route
            exact
            path="/services"
            element={<Services setHeight={setHeight} />}

          />

          <Route
            exact
            path="/our-stories"
            element={<OurStories setOpenPhoneMenuModal={setOpenPhoneMenuModal} setMoveVerticalLines={setMoveVerticalLines} setHideNav={setHideNav} />}
          />

          <Route
            exact
            path="/industries"
            element={<Industries navHeight={navHeight} />}
          />

          <Route
            exact
            path="/BTS-List"
            element={<BTSList setOpenPhoneMenuModal={setOpenPhoneMenuModal} setMoveVerticalLines={setMoveVerticalLines} setHideNav={setHideNav} />}
          />

          <Route
            exact
            path="/Insights-List"
            element={<InsightsList setOpenPhoneMenuModal={setOpenPhoneMenuModal} setMoveVerticalLines={setMoveVerticalLines} setHideNav={setHideNav} />}
          />

          <Route
            exact
            path="/BTS"
            element={<BTSById setOpenPhoneMenuModal={setOpenPhoneMenuModal} setMoveVerticalLines={setMoveVerticalLines} setHideNav={setHideNav} />}
          />

          <Route
            exact
            path="/Insight"
            element={<InsightsById setOpenPhoneMenuModal={setOpenPhoneMenuModal} setMoveVerticalLines={setMoveVerticalLines} setHideNav={setHideNav} />}
          />

          <Route
            exact
            path="*"
            element={<> <Navigate to="/" /></>}
          />
        </>
      </Routes>

      {!hideNav ? <Footer setMoveVerticalLines={setMoveVerticalLines} setHideNav={setHideNav} /> : <></>}
    </Router>
  );
}

export default App;
