import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react';
import classes from '../../Assets/Styles/PileCubesPage.module.css';
import LereumImg from "../../Assets/Images/LereumImgg.png";
import StoryCircle from "../../Assets/Images/pilecubesStory.png";
import comingSoon from "../../Assets/Images/coming-sooon.png";


const LereumPage = (props) => {
    const [source, setSource] = useState(StoryCircle)

    return (
        <>
            <Grid container direction={"row"} marginTop={20}>
                <Grid item container md={1.4} xs={1} />
                <Grid item container md={10.5} xs={11}>

                    <Grid item md={6.2} lg={5} xs={11} position="relative" >
                        <Grid item onMouseOut={() => { setSource(StoryCircle) }} onMouseOver={() => { setSource(comingSoon) }}>
                            <img src={source} width="20%" alt="story-design" className={classes["img-poistion"]} style={{ position: "absolute", right: 30, bottom: -30 }} />
                        </Grid>
                        <img src={LereumImg} alt="LereumImg" width="100%" height="100%" />
                    </Grid>

                    <Grid item marginTop={{ xs: 5, md: 0 }} marginLeft={{md:7, lg: 10, xs: 0 }} xs={11} lg={5} md={5}>
                        <Grid item>
                            <span className={classes["Pilecubes"]}>
                                Lereum
                            </span>
                        </Grid>

                        <Grid item md={8}>
                            <span className={classes["Industry-SaaS"]}>
                                Crypto Trading Platform
                            </span>
                        </Grid>

                        <Grid item paddingTop={4} >
                        <span className={classes["year"]}> Year </span>
                        </Grid>
                        <Grid item paddingTop={2} className={classes["date"]}>
                            2021
                        </Grid>
                        <Grid item paddingTop={4}>
                            <span className={classes["year"]}> Role </span>
                        </Grid>

                        <Grid item md={8} paddingTop={2} className={classes["role"]}>
                            <span>
                                Roadmap - Product Map <br></br>
                                Requirements Creation - Team Agile Training <br></br>
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default LereumPage;