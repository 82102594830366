import Grid from "@mui/material/Grid";
import classes from "../Assets/Styles/TermsAndConditions.module.css";
import { Helmet, HelmetProvider } from "react-helmet-async";


const TermsAndConditions = (props) => {

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title> {`Privacy Policy and Terms`} </title>
                    <meta property="og:description"
                        content="We turn your problem into an idea, your idea into a solution, and your solution into a digital product. We launch your minimum viable product (MVP) or scale your digital product embracing Agile to increase development speed, expand collaboration, and foster the ability to better respond to market trends." />                <meta name="image" property="og:image" content="https://testimgg.s3.eu-west-1.amazonaws.com/DPLogo.png" />
                    <meta property="og:url" content="https://www.digitalprestige.com/" />
                    <link rel="canonical" href={`/privacy-policy-and-terms`} />
                </Helmet>
            </HelmetProvider>
            <Grid container paddingTop={5}>
                <Grid item container md={1.4} xs={1} />
                <Grid item container md={8.7} xs={10}>
                    <Grid item xs={12} className={classes["privacy-policy"]}>
                        PRIVACY POLICY
                    </Grid>
                    <Grid
                        paddingTop={3}
                        className={classes["text"]}
                        item
                    >
                        <Grid item paddingBottom={2}>
                            Last updated on 05/11/2021
                        </Grid>

                        <Grid item paddingBottom={2}>
                            Thank you for visiting the website of DIGITAL PRESTIGE (hereinafter referred to as high end digital world and high quality performance for companies to deliver their own digital dream). We recognize the importance of your privacy and are pleased to provide this privacy policy (hereinafter referred to as "Privacy Policy") to let you know how we collect and use information about visitors to our website.
                        </Grid>
                        <Grid item paddingBottom={0.7}>
                            Our offices are located at:
                        </Grid>
                        <Grid item paddingBottom={0.7}>
                            Beirut Digital District, BDD Building #1294,                                </Grid>
                        <Grid item paddingBottom={0.7}>
                            5th floor, Nassif Al Yaziji Street Al Bashoura,
                        </Grid>
                        <Grid item paddingBottom={2}>
                            Beirut, Lebanon

                        </Grid>
                        <Grid item paddingBottom={2}>
                            You can reach us via e-mail at <a href="mailto:admin@digitalprestige.com" style={{ textDecoration: 'underline' }}>admin@digitalprestige.com </a>
                        </Grid>

                        <Grid item paddingBottom={2}>
                            By using our website or interacting with us offline, you agree to the collection and use of your information as described in this Privacy Policy. In case you have any doubts, inquiries, or you simply want to make use of your rights and preferences, you can contact us in the manner determined in the "Contact" section of this document.
                        </Grid>
                        <Grid item marginBottom={2} style={{ borderBottom: '2px solid #c8a047' }}>

                        </Grid>

                        <Grid item paddingBottom={2}>
                            INFORMATION WE COLLECT AND HOW WE USE IT
                        </Grid>
                        <Grid item paddingBottom={2}>
                            Visitor Information
                        </Grid>

                        <Grid item paddingBottom={2}>
                            In general, you do not have to provide information to use our website. Nevertheless, DIGITAL PRESTIGE collects information, via the website, when that information is voluntarily provided by the visitor by completing the contact form. DIGITAL PRESTIGE cannot guarantee that this information will be kept strictly confidential, and we recommend that visitors do not provide or submit private or confidential information by completing the contact form.
                        </Grid>

                        <Grid item paddingBottom={2}>
                            The information we collect through the website may be stored in the DIGITAL PRESTIGE contact database where it may be combined with pre-existing contact information in that database.                                </Grid>

                        <Grid item paddingBottom={2}>
                            We use the information collected primarily to respond to your inquiries or to contact you. We may also use your information for other purposes as long as these are associated with the circumstances in which the information was provided to us.
                        </Grid>

                        <Grid item paddingBottom={2}>
                            Information We Collect By Automated Means
                        </Grid>

                        <Grid item paddingBottom={2}>
                            Our website uses Google Analytics. This means that we collect information that your browser sends when you visit our website ("Logs"). Log data may include information such as your computer's IP address, cookies, the type of browser you use, pages of our website that you visit, the time spent on those pages, access times and dates, among other statistics. For more information about data collected by Google Analytics, please consult the following link and/or Google's privacy policy. We use this information to monitor and analyze the use of our website so that we can gradually improve its functionality and adjust it to the needs of our visitors.
                        </Grid>

                        <Grid item paddingBottom={2}>
                            Google provides tools to allow you to disable the collection of certain information through Google Analytics at <a href='https://tools.google.com/dlpage/gaoptout' style={{ textDecoration: 'underline' }}> https://tools.google.com/dlpage/gaoptout </a> You should know that if you use this option, the disabling will be effective on the specific browser and device from which you operated. DIGITAL PRESTIGE is not responsible for the effectiveness of these tools or the accuracy of any policy or statement made about them by other sites.
                        </Grid>

                        <Grid item paddingBottom={2}>
                            As many other websites do, we use "cookies" to collect information. Cookies are small data files that we can transfer to your computer's hard drive for data logging purposes. In practice, we use cookies to enable certain functionalities on our website, to understand how you interact with the website and to generate usage and data traffic profiles on our website. Please, note that you can instruct your browser - by changing its settings - to stop accepting cookies or even to alert you before accepting cookies from any website you visit. However, in case you decide not to accept cookies, some of the functions of our website may not work.


                        </Grid>
                        <Grid item paddingBottom={2}>
                            Information from Other Sources
                        </Grid>
                        <Grid item >
                            We also collect information from other sources. The following are the categories of sources we collect information from:
                        </Grid>
                        <Grid item paddingBottom={2}>
                            <ul>
                                <li > Consumers or users of the website.</li>
                                <li  ><span > Social networks when you engage with our content, reference our website, or grant us permission to access information from the social networks. </span></li>
                                <li>Publicly-available sources, including data in the public domain.</li>
                            </ul>
                        </Grid>

                        <Grid item paddingBottom={2}>
                            Who do we share your information with?
                        </Grid>

                        <Grid item >
                            DIGITAL PRESTIGE shares your information in accordance with the practices described in this Privacy Policy. Entities whom may receive your information include the following:
                        </Grid>

                        <Grid item paddingBottom={2}>
                            <ul>
                                <li>Service Providers. We engage companies and individuals to perform services on our behalf for business purposes, including without limitation, to facilitate our website, provide the website on our behalf, and perform website-related services (e.g., without limitation, maintenance services, database management, web analytics and improvement of the website’s features) or to assist us in analyzing how our website is used. These service providers may have access to your information. These service providers are not authorized by us to use, retain, or disclose the information except as necessary to perform services on our behalf or to comply with legal requirements.</li>
                                <li> Related Companies. The information we collect may be shared with any of the companies that are part of the DIGITAL PRESTIGE Group. However, none of these companies are authorized to use, retain, or share your information for purposes different than those set out in this Privacy Policy.</li>
                                <li>Requirements for Resolving Legal Disputes. We may share your information to cooperate with governments, judicial authorities or even private third parties, when in our sole discretion we consider that such information may be necessary or appropriate to resolve claims or lawsuits that are aimed to: i) protecting the property and/or rights of DIGITAL PRESTIGE or third parties, ii) protecting public safety or health, iii) investigating the possible commission of crimes or illegal activities.</li>
                                <li> Mergers or Acquisitions. DIGITAL PRESTIGE reserves the right to transfer the information collected, in the event of a sale or transfer of all or part of its business or assets.</li>
                            </ul>
                        </Grid>

                        <Grid item paddingBottom={2}>
                            Notwithstanding the above, we may share information that does not identify you (anonymized information) except that such practice is prohibited by applicable law.
                        </Grid>
                        <Grid item marginBottom={2} style={{ borderBottom: '2px solid #c8a047' }}>

                        </Grid>

                        <Grid item paddingBottom={2}>
                            SOCIAL MEDIA AND TECHNOLOGY INTEGRATIONS
                        </Grid>

                        <Grid item>
                            Our website may have links to other sites, platforms, and/or services on which DIGITAL PRESTIGE has no control over the collection of your data. Also, we integrate technologies operated or controlled by other entities into our website. Some examples of this are:
                        </Grid>

                        <Grid item paddingBottom={2}>
                            <ul>
                                <li>Links. Our website may include hyperlinks to websites, platforms, or other services that are not operated or controlled by DIGITAL PRESTIGE</li>
                                <li>"Likes", "Shares" & "Logging-In". We may integrate "pixels" or "SDKs" into our website to allow you to like / share content, or even "log in" to your social media account. If you choose to do so, we may receive information from the social network informing us that you have authorized the sharing of certain content. Please be aware that your social networks may be collecting information from you independently due to the referred integration.</li>
                                <li>Brand Sites. We may offer our services and/or content through social networks. Any information you provide when linking to our content will be treated in accordance with the provisions of this Privacy Policy. In turn, if you refer to our website on social networks (for example by using a hashtag associated with DIGITAL PRESTIGE in a tweet or post), we may use your reference on our website or social networks.</li>
                            </ul>
                        </Grid>

                        <Grid item paddingBottom={2}>
                            DIGITAL PRESTIGE is not responsible for the practices employed by websites linked to our website or for the content, products, or services offered on these sites. Information collected, processed, and stored by such sites is subject to the policies and practices of the entities responsible for those sites. Therefore, we encourage all visitors to review the privacy policies of such web pages at the time of their first visit or inquiry to the site.
                        </Grid>

                        <Grid item marginBottom={2} style={{ borderBottom: '2px solid #c8a047' }}>

                        </Grid>

                        <Grid item paddingBottom={2}>
                            STORAGE AND TRANSFER OF DATA
                        </Grid>
                        <Grid item paddingBottom={2}>
                            We implement and maintain reasonable administrative, technical, and physical measures to safeguard the information we collect through our website. Nevertheless, transmission via the internet is not completely secure and we cannot guarantee the security of information about you. DIGITAL PRESTIGE is based in Lebanon and the information we collect is governed by Lebanese law. If you are accessing the website from another country, please be aware that DIGITAL PRESTIGE's website may collect information that may be transferred to, processed, stored, and used in Lebanese and other jurisdictions. Data protection laws in Lebanon may be different from those of your country of residence. Your use of the website or provision of any information therefore constitutes your consent to the transfer to and from, processing, usage, sharing, and storage of information about you in Lebanon and other jurisdictions as set out in this Privacy Policy.
                        </Grid>

                        <Grid item marginBottom={2} style={{ borderBottom: '2px solid #c8a047' }}>

                        </Grid>

                        <Grid item paddingBottom={2}>
                            YOUR RIGHTS AND CHOICES
                        </Grid>
                        <Grid item paddingBottom={2}>
                            If you would like to update your contact information or preferences, or if you would like to have your information removed from our mailing list, please contact us by e-mailing us at <a href="mailto:admin@digitalprestige.com" style={{ textDecoration: 'underline' }}>admin@digitalprestige.com </a>.
                        </Grid>

                        <Grid item paddingBottom={2}>
                            Also, every visitor of our website has the right - as determined by applicable law- to i) access the information we collect about you; ii) review, correct, and/or delete that information. To protect the security of our visitors' information, DIGITAL PRESTIGE reserves the right to request reasonable measures to prove the identity of the requestor.

                        </Grid>
                        <Grid item paddingBottom={2}>
                            Most browsers accept cookies by default. You can instruct your browser, by changing its settings, to decline or delete cookies. If you use different browsers on your devices, you will need to instruct each browser separately. Your ability to limit cookies is subject to your browser´ settings and limitations.
                        </Grid>
                        <Grid item paddingBottom={2}>
                            Your browser may allow you to automatically transmit a “Do Not Track” signal to online services you visit. Note, however, there is no industry consensus as to what site and app operators should do with regard to these signals. Accordingly, unless and until the law is interpreted to require us to do so, we do not monitor or take action with respect to “Do Not Track” signals. For more information on “Do Not Track,” visit <a href={'http://www.allaboutdnt.com'} style={{ textDecoration: 'underline' }}> http://www.allaboutdnt.com</a>.
                        </Grid>
                        <Grid item paddingBottom={2}>
                            Please be aware that if you disable or remove tracking technologies, some parts of the website may not function correctly.
                        </Grid>

                        <Grid item paddingBottom={2}>
                            CHANGES TO THIS PRIVACY POLICY
                        </Grid>
                        <Grid item paddingBottom={2}>
                            DIGITAL PRESTIGE may occasionally update or change this Privacy Policy from time to time and without delivering prior notice to you.
                        </Grid>
                        <Grid item paddingBottom={2}>
                            We will post a notice on our website’s privacy page to notify you if our Privacy Policy is revised. You may determine if the Privacy Policy has been revised by referring to the "Last Updated" date at the top of the Privacy Policy.
                        </Grid>
                        <Grid item paddingBottom={2}>
                            DIGITAL PRESTIGE urges you to review its Privacy Policy frequently
                        </Grid>
                        <Grid item marginBottom={2} style={{ borderBottom: '2px solid #c8a047' }}>

                        </Grid>
                        <Grid item paddingBottom={2}>
                            HOW TO CONTACT US
                        </Grid>

                        <Grid item paddingBottom={2}>
                            We welcome any questions, concerns, or comments regarding the Privacy Policy, our data processing practices, and/or the degree of compliance with applicable legal regulations. We ask you to bring any questions or comments to <a href="mailto:admin@digitalprestige.com" style={{ textDecoration: 'underline' }}>admin@digitalprestige.com</a> or mail us to the following postal address
                        </Grid>

                        <Grid item paddingBottom={0.7}>
                            Beirut Digital District, BDD Building #1294,
                        </Grid>
                        <Grid item paddingBottom={0.7}>
                            5th floor, Nassif Al Yaziji Street Al Bashoura,
                        </Grid>
                        <Grid item paddingBottom={0.7}>
                            Beirut, Lebanon
                        </Grid>

                        <Grid item paddingBottom={0.7}>
                            If you have a disability and would like to access this Privacy Policy in an alternative format, we ask that you write to us at <a href="mailto:admin@digitalprestige.com" style={{ textDecoration: 'underline' }}>admin@digitalprestige.com</a>
                        </Grid>
                        <Grid item marginBottom={5} style={{ borderBottom: '2px solid #c8a047' }}>

                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        </>
    );
};

export default TermsAndConditions;
