import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from "react-router-dom";
import HowWeWork from './HowWeWork';
import Page1 from './page1';
import WhatWeDo from './WhatWeDo';
import WhoAreWe from './WhoAreWe';
import ContactUsPage from './ContactUsPage';
import { Helmet, HelmetProvider } from "react-helmet-async";


const AboutUsPage = (props) => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const query = useQuery();
    const contactUsParam = query.get("ContactUs");

    const [whoAreWePosition, setWhoAreWhePosition] = useState(0);
    const [whatWeDoPosition, setWhatWeDoPosition] = useState(0);
    const [page1Position, setPage1Position] = useState(0);
    const [howWeWorkPosition, setHowWeWorkPosition] = useState(0);

    let hash = window.location.hash;

    useEffect(()=>{

        if (hash) {
            // Fragment exists
            hash = hash.substring(1);
            if(hash === "ContactUs")
            setTimeout(()=>{
                props.setScrollToContactUsForm(true)
            },100)
          }
    },[hash])

    useEffect(() => {
        props.setContactFormPosition(whoAreWePosition + whatWeDoPosition + page1Position + howWeWorkPosition)
    }, [whoAreWePosition, howWeWorkPosition, whatWeDoPosition, page1Position])

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title> {`DP Home Page`} </title>
                    <meta name="description" content="We turn your problem into an idea, your idea into a solution, and your solution into a digital product. We launch your minimum viable product (MVP) or scale your digital product embracing Agile to increase development speed, expand collaboration, and foster the ability to better respond to market trends." />
                    <meta name="image" property="og:image" content="https://testimgg.s3.eu-west-1.amazonaws.com/DPLogo.png" />
                    <meta property="og:url" content="https://www.digitalprestige.com/" />
                    <meta
                        name="keywords"
                        content="Digital Prestige, Digital Transformation, Tech Organziation, Launch tech innovation, Product company, Quality software, Lebanon, Beirut, UAE, Dubai, MENA, end-to-end development, technological solutions, software delivery, global standards, Digial culture, leadership excellence, software delivery, Software Development Life Cycle, agile methodology, project management, agile manifesto, start-ups companies, delivery processes, oriented teams, idea, minimum viable products, MVP, digital prestige academy, agile courses, agile academy, agile workshop, agile process development, agile team worshop, digital culture development, agile release planning workshop, Agile manifesto, start-ups companies, delivery processes, oriented teams, minimum viable product, MVP, expand collaboration, Product creation, Business design, Work Strategy, saas, software platforms, mobile applications, aws, java, nodejs, react, kuwait, typescript, strategy, vision, technical expertise, tech services, ERP, hosting, tech experts, we development, cloud, digital services, websites, leading digital transformation, developing software, retail digital transformation, scrum" />
                    <link rel="canonical" href={`/`} />
                </Helmet>
            </HelmetProvider>

            <Page1 setPage1Position={setPage1Position} setHeight={props.setHeight} />
            <WhoAreWe setWhoAreWhePosition={setWhoAreWhePosition} />
            <WhatWeDo setWhatWeDoPosition={setWhatWeDoPosition} />
            <HowWeWork setHowWeWorkPosition={setHowWeWorkPosition} />
            <ContactUsPage />
        </>
    )
}

export default AboutUsPage;