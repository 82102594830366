import Grid from "@mui/material/Grid";
import React from 'react'
import classes from '../../Assets/Styles/OurExperience.module.css';
import BodyAnimation3 from "./BodyAnimation3";
import BodyAnimation31 from "./BodyAnimation31";


const OurExperience = (props) => {

    return (
        <>
            <Grid container marginTop={{ md: 20, xs: 17 }} zIndex={"1"} direction={"row"} maxWidth={'xl'}>
                <Grid item container md={1.5} xs={1} />
                <Grid item container md={10} xs={11}>
                    <Grid item container md={6} >
                        <Grid item>
                            <Grid item>
                                <span className={classes["NOT-ANOTHER-FULL-STACK-PRODUCT-COMPANY"]}>
                                    Our {" "}
                                    <span className={classes['company']}> Mission</span>

                                </span>
                            </Grid>
                            <Grid item paddingTop={{ md: 14, xs: 8 }} xs={11} md={8} sm={10.5}>

                                <span className={classes["WhoAreWe-text"]}>
                                    To be among the leading digital transformation companies
                                    in MENA (Middle East And North Africa),
                                    to digitize small, medium and large organizations.
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container md={6} paddingTop={{ xs: 14, md: 0 }}>
                        <Grid item>
                            <span className={classes["NOT-ANOTHER-FULL-STACK-PRODUCT-COMPANY"]}>
                                Our {" "}
                                <span className={classes['company']}> Experience</span>

                            </span>
                        </Grid>
                        <Grid item paddingTop={{ md: 14, xs: 8 }} xs={11}  md={9} lg={8} sm={10.5}>

                            <span className={classes["WhoAreWe-text"]} >
                                Through our 25 years of technological and methodological expertise, we want to deliver high achievements for our clients,
                                who range from start-ups to multinational companies.
                            </span>
                        </Grid>
                        <Grid item className={classes["grid-index"]} display={{ xs: "none", md: "none", xl: "flex" }}>
                            <BodyAnimation31 />
                        </Grid>

                        <Grid item paddingTop={{ xs: 15, sm: 0 }} className={classes["grid-index"]} display={{ xs: "flex", md: "flex", xl: "none" }}>
                            <BodyAnimation3 />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default OurExperience;