import Grid from "@mui/material/Grid";
import React, { useState, useEffect, useRef } from 'react'
import BodyAnimation1 from "./BodyAnimation1";
import BodyAnimation11 from "./BodyAnimation11";
import classes from '../../Assets/Styles/WhoAreWe.module.css';
import windowDimensions from "../WindowDimension";

const WhatWeDo = (props) => {

    let ref = useRef();

    const {windowWidth} = windowDimensions();

    useEffect(() => {
        props.setWhatWeDoPosition(ref?.current?.clientHeight);
    }, [windowWidth, props.scrollToContactUsForm])
    
    return (<>
        <Grid container ref={ref} zIndex={"1"} marginTop={20} direction={"row"} maxWidth={'xl'}>

            <Grid item container md={1.5} xs={1} />
            <Grid item container md={10.5} xs={11}>
                <Grid item paddingBottom={{ xs: 3, md: 0 }} paddingTop={1} xs={12} md={2.5} lg={2} >
                    <span className={classes["WHO-ARE-WE"]}>
                        WHAT WE DO?
                    </span>
                </Grid>

                <Grid item md={9.5} lg={10}>
                    <Grid item>
                        <span className={classes["NOT-ANOTHER-FULL-STACK-PRODUCT-COMPANY"]}>
                            Agile {" "}
                            <span className={classes['company']}>Methodology</span>

                        </span>
                    </Grid>
                    <Grid item paddingTop={{ md: 6, xs: 4 }} xs={11} md={8.5} lg={8} sm={10.5}>

                        <span className={classes["WhoAreWe-text"]} >
                            We turn your problem into an idea, your idea into a solution, and your solution into a digital product.<br></br> <br></br>
                            We launch your minimum viable product (MVP) or scale your digital product embracing Agile to increase development speed, expand collaboration, and foster the ability to better respond to market trends.
                        </span>
                    </Grid>

                    <Grid item>
                        {windowWidth > 750 ?
                            <Grid item container className={classes["ani-position"]}> <BodyAnimation1 /></Grid>
                            :
                            <Grid item container paddingRight={3} paddingBottom={3} justifyContent={"center"}> <BodyAnimation11 /></Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    </>)
}

export default WhatWeDo;