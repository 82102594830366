import Grid from "@mui/material/Grid";
import "../../../Assets/Styles/OurStoriesId.css";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import beginWithAnIdea from "../../../Assets/Images/beginWithAnIdea11.png";
import blueDesign2 from "../../../Assets/Images/blueDesign2.png";
import blackNextArrow from "../../../Assets/Images/blackNextArrow.png";
import goldNextArrow from "../../../Assets/Images/goldNextArrow.png";
import CircularProgress from "@mui/material/CircularProgress";
import goBack from "../../../Assets/Images/goBack.png";
import windowDimensions from "../../WindowDimension";

import * as ourServices from '../../../Services/our-story-services.proxy';

const ourStoryById = ourServices.ourStoryById;
const nextBtsStory = ourServices.nextBtsStory;

const BTSById = (props) => {

    let navigate = useNavigate();

    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const query = useQuery();
    const storyId = query.get("Id");

    const { windowWidth, windowHeight } = windowDimensions();

    const [loadingAni, setLoadingAni] = useState(false);
    const [nextImageSrc, setNextImageSrc] = useState(blackNextArrow);
    const [ourStory, setOurStory] = useState({});

    useEffect(() => {

        let isApiSubscribed = true;
        window.scrollTo({ top: 0 });
        setLoadingAni(true)
        setNextImageSrc(blackNextArrow);

        ourStoryById(storyId).then((response) => {
            if (!response.error && isApiSubscribed) {
                setOurStory(response)
                setLoadingAni(false);
            }
        })
        return () => {
            isApiSubscribed = false;
        };

    }, [storyId])

    const handleNextStory = () => {
        nextBtsStory(storyId).then((response) => {
            if (!response.error) {
                navigate(`/BTS?Id=${response}`)
            }
        })
    }

    const goBackHandler = () => {
        navigate(-1);
    }

    return (
        <>
            <Grid container position="relative">
                {loadingAni ?
                    <Grid item container paddingBottom={15} justifyContent="center" alignItems="center" height={windowHeight}>
                        <CircularProgress size={40} sx={{ color: "#1F96C2" }} />
                    </Grid>
                    :
                    <>
                        <Grid item container direction="column">

                            <Grid item container justifyContent="center" paddingTop={2} position="relative">
                                <img src={blueDesign2} className={"blue-design"} width={windowWidth > 600 ? "80%" : "100%"} alt="blue design" />
                                <Grid item xs={10}>
                                    <img src={goBack} onClick={() => { goBackHandler() }} width={windowWidth > 900 ? "auto" : "8%"} alt="go back" style={{ cursor: "pointer" }} />

                                    <img width="100%" src={beginWithAnIdea} alt="opening image" />
                                </Grid>

                                <Grid item container md={10} xs={10} alignContent="center" justifyContent="center" paddingTop={{ xs: 10, sm: 19, md: 26, lg: 32, xl: 40 }} className={"story-title"}>
                                    {ourStory.Title}
                                </Grid>

                                <Grid item container paddingTop={{ md: 30, xs: 12 }} xs={9.5} md={8.5} gap={{ md: 20, xs: 13 }}>
                                    <Grid item container>
                                        <Grid item paddingTop={{ xs: 2, md: 0 }} xs={12} className={"Idea-text"}>
                                            <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: ourStory.Introduction }} />
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid item container justifyContent="center" alignItems="center" marginTop={{ md: 20, xs: 13 }} xs={12} md={9} height={{ xs: "300px", sm: "455px", md: "465px", lg: "490px", xl: "570px" }} bgcolor="#87c9fb">
                                    <Grid item md={2.5} sm={2} xs={0} lg={2} xl={2.5} />
                                    <Grid item md={8} xs={9} sm={7} lg={7} xl={6} >
                                        <Grid item height={{ xs: "245px", sm: "325px", md: "350px", lg: "363px", xl: "390px" }} className={"Rectangle-948"}>
                                            <img src={ourStory.ExecutionPhoto && ourStory.ExecutionPhoto?.URL} alt="cover image" width="100%" height="100%" />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item container paddingTop={{ md: 20, xs: 10 }} xs={9.5} md={8.5} gap={{ md: 20, xs: 13 }}>

                                    <Grid item paddingTop={{ xs: 2, md: 0 }} xs={12} className={"Idea-text"}>
                                        <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: ourStory.Details }} />
                                    </Grid>
                                </Grid>

                                <Grid item container xs={12} justifyContent="center" alignItems="center" marginTop={{ md: 20, xs: 15 }} height={{ sm: "500px", md: "600px", xs: "300px", lg: "650px", xl: "700px" }} bgcolor="#87c9fb">
                                    <Grid item xs={9} height={{ xs: "245px", sm: "400px", md: "470px", lg: "520px", xl: "620px" }} className={"img-contain"}>
                                        <img src={ourStory.ProcessPhoto && ourStory.ProcessPhoto?.URL} alt="cover image" width="100%" height="100%" />
                                    </Grid>
                                </Grid>

                            </Grid>

                            {ourStory?.Total !== 1 &&
                                <Grid item container paddingTop={15} paddingBottom={5} paddingRight={windowWidth < 600 ? 3 : 10} justifyContent="flex-end">
                                    <Grid item xs={6} lg={4}>
                                        <img src={nextImageSrc}
                                            width="100%"
                                            onClick={() => { handleNextStory() }}
                                            onMouseOver={(e) => { e.target.style.cursor = "pointer"; setNextImageSrc(goldNextArrow) }}
                                            onMouseOut={() => { setNextImageSrc(blackNextArrow) }}
                                            alt="next story"
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </>
                }
            </Grid>
        </>
    )
}

export default BTSById;