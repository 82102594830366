import Grid from "@mui/material/Grid";
import Home from "./Home";
import ClientStoriesNavbar from "./ClientStoriesNavbar";
import Footer from "../../../Routes/StoriesFooter";
import React, { useState, useEffect, useRef } from 'react';
import Fade from '@mui/material/Fade';


const FullPage = (props) => {


    return (
        <Fade in={!props.fadeIn} timeout={600} zIndex={0}>
            <Grid container >
                <ClientStoriesNavbar setOpenPhoneMenuModal={props.setOpenPhoneMenuModal} />
                <Home />
                <Footer setHideNav={props.setHideNav} />
            </Grid>
        </Fade>
    )
}

export default FullPage;