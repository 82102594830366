import React from 'react';
import classes from "../../Assets/Styles/Industries.module.css";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";


const Industries = (props) => {

    const handleClose = () => {
        props.setOpenIndustries(false)
    }
    return (
        <Modal
            disableEnforceFocus
            keepMounted
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={handleClose}
            onClick={handleClose}
            closeAfterTransition
            style={{ top: props.navHeight, overflowY: "hidden" }}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                style: { backgroundColor: "transparent" },
            }}
        >
            <Grid container direction="column" className={props.scrollPosition > 0 ? classes["bordered-main-grid"] : classes["main-grid"]} top={props.open ? 0 : "-500px"}>
                <Grid item container padding="2% 4% 2% 12%">
                    <Grid item md={3} paddingBottom={4.2} paddingTop={4}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Capital Markets</span>
                        </Link>
                    </Grid>

                    <Grid item md={3} paddingTop={4}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Automative </span>
                        </Link>
                    </Grid>

                    <Grid item md={3} paddingTop={4}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Logistics </span>
                        </Link>
                    </Grid>
                    <Grid item md={3} paddingTop={4}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Food & Beverages </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Insurance </span>
                        </Link>
                    </Grid>

                    <Grid item md={3} paddingBottom={4.2}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Agribusiness </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Health Care </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Travel & Hospitality </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Real Estate </span>
                        </Link>
                    </Grid>

                    <Grid item md={3} paddingBottom={4.2}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Banking </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Ministries </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Beauty & Personal Care </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Construction </span>
                        </Link>
                    </Grid>

                    <Grid item md={3} paddingBottom={4.2}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Public Service </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Law Firm </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Software & Platform </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Manufacturing </span>
                        </Link>
                    </Grid>

                    <Grid item md={3} paddingBottom={4.2}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Higher Education </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Telecom </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Retail & Distribution </span>
                        </Link>
                    </Grid>

                    <Grid item md={3} paddingBottom={4.2}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Utilities </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> High Tech </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Communication & Media </span>
                        </Link>
                    </Grid>

                    <Grid item md={3}>
                        <Link to={"/industries"} className={classes["text"]}>
                            <span> Consumer Goods & Services </span>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default Industries;