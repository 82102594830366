import Grid from "@mui/material/Grid";
import classes from '../../Assets/Styles/ContactUs.module.css';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material/styles";
import phoneImg from "../../Assets/Images/phoneImg.png";
import paperPlane from "../../Assets/Images/paperPlane.png";
import React, { useState, useEffect, useRef } from "react";
import windowDimensions from "../WindowDimension";

import * as dpServices from "../../Services/dp-services.proxy";

const sendInquiryEmail = dpServices.sendInquiryEmail;

const CssTextField = styled(TextField)({

    "&& .MuiFormHelperText-root": {
        color: "red",
        height: 0,
        fontSize: "11px"
    },
    "&& .MuiFormHelperText-root:focused": {
        color: "red",
        borderColor: "transparent",
    },
    "&&:hover fieldset": {
        borderColor: 'transparent',
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
        "& > fieldset": {
            borderColor: "transparent",
        }
    },

});

const ContactUsPage = (props) => {

    const ref = useRef();
    const [gridWidth, setGridWidth] = useState(0);
    const [gridHeight, setGridHeight] = useState(0);
    const [inquiryMessageSent, setInquiryMessageSent] = useState(false);
    const { windowWidth } = windowDimensions();

    useEffect(() => {
        setGridWidth(ref?.current?.clientWidth);
        setGridHeight(ref?.current?.clientHeight);
    }, [windowWidth, inquiryMessageSent])

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [inquiries, setInquiries] = useState("");
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [phoneNumberError, setPhoneNumberError] = useState(null);
    const [inquiriesError, setInquiriesError] = useState(null);

    const data = {
        Email: email,
        Name: name,
        Inquiry: inquiries,
        PhoneNumber: phoneNumber
    }

    useEffect(() => {
        if (inquiryMessageSent) {
            setTimeout(() => {
                setInquiryMessageSent(false)
            }, 4000)
        }
    }, [inquiryMessageSent])

    const sendEmailHandler = () => {
        if (!name) {
            setNameError(true)
        }
        else {
            setNameError(false)
        }
        if (!phoneNumber) {
            setPhoneNumberError(true)
        }
        else {
            setPhoneNumberError(false)
        }
        if (!email) {
            setEmailError(true)
        }
        else {
            setEmailError(false)
        }
        if (!inquiries) {
            setInquiriesError(true)
        }
        else {
            setInquiriesError(false)
        }
        if (name && inquiries && email && phoneNumber) {
            sendInquiryEmail(data).then((response) => {
                if (!response.error) {
                    setEmail("");
                    setPhoneNumber("");
                    setInquiries("");
                    setName("");
                    setInquiryMessageSent(true);
                }
            })
        }
    }

    return (<>
        <Grid container direction={"row"} maxWidth={'xl'} paddingBottom={{ xs: 20, md: 25 }}>
            <Grid item container md={1.2} lg={1.4} xs={1} />
            <Grid item container md={10.8} lg={10.6} xs={11}>
                <Grid item xs={12} md={3.5} lg={3} container alignItems="center" justifyContent={{ xs: "center", md: "flex-start" }} >
                    <Grid item position="relative">
                        <span className={classes["Do-you-have-a-project-in-mind"]}>
                            <span className={classes["text-style-1"]}>Do you have <br></br></span>
                            a project in mind?
                        </span>
                        <Grid item className={classes["underline"]} />
                    </Grid>

                </Grid>

                <Grid item container xs={11} marginTop={{ xs: 9, md: 0 }} md={8.2} lg={7.2} ref={ref} position="relative">
                    <Grid item container xs={12} className={classes["top-grid"]} padding={{ xs: "20px 20px 25px 20px", md: "30px 35px 30px 20px" }}>
                        {windowWidth > 899
                            ?
                            <Grid item container xs={12}>
                                <Grid item container direction="row-reverse" paddingRight="18px" className={classes["Reach-out-to-us"]}>
                                    REACH OUT TO US.
                                </Grid>
                            </Grid>
                            :
                            <></>}
                        <Grid item container xs={12} alignItems="flex-start" paddingTop={3}>
                            <Grid item container xs={12} md={5} paddingTop={1}>
                                {windowWidth > 899 ?
                                    <>
                                        <Grid item container xs={10.5}>
                                            <Grid item container alignSelf="flex-start" justifyContent="center" paddingBottom={3} xs={4}>
                                                <img src={paperPlane} alt="paper plane" />
                                            </Grid>
                                            <Grid item container xs={8} alignSelf="flex-end" direction="row-reverse" className={classes["email-us"]}>
                                                E-mail us at
                                            </Grid>
                                        </Grid>

                                        <Grid item container direction="row-reverse" xs={10.5}>
                                            <Grid item className={classes["email"]}>
                                                admin@digitalprestige.com
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={10.5} paddingTop={6}>
                                            <Grid item container alignSelf="flex-start" justifyContent="center" xs={4}>
                                                <img src={phoneImg} alt="phone image" />
                                            </Grid>
                                            <Grid item xs={8} paddingTop={4}  >
                                                <Grid item container justifyContent="flex-end" xs={12}>
                                                    <span className={classes["Call-us-at"]}>Call us at</span>
                                                </Grid>

                                                <Grid item container justifyContent="flex-end" xs={12}>
                                                    <span className={classes["phone-num"]}>+961 3 402 735</span>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <Grid item container xs={12}>
                                        <Grid item container xs={7}>
                                            <Grid item container xs={11.5}>
                                                <Grid item container alignSelf="flex-start" justifyContent="center" paddingBottom={3} xs={4}>
                                                    <img src={paperPlane} alt="paper plane" />
                                                </Grid>
                                                <Grid item container xs={7.5} sm={6} alignSelf="flex-end" direction="row-reverse" className={classes["email-us"]}>
                                                    E-mail us at
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={5}>
                                            <Grid item container xs={12}>
                                                <Grid item container alignSelf="flex-start" justifyContent="center" xs={4}>
                                                    <img src={phoneImg} alt="phone image" />
                                                </Grid>
                                                <Grid item container xs={8} alignSelf="flex-end" justifyContent="flex-end">
                                                    <Grid item>
                                                        <span className={classes["Call-us-at"]}>Call us at</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={12} alignItems="center">
                                            <Grid item container xs={7}>
                                                <Grid item container direction="row-reverse" xs={11} sm={10}>
                                                    <Grid item className={classes["email"]}>
                                                        admin@digitalprestige.com
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item container xs={5}>
                                                <Grid item container direction="row-reverse" xs={12}>
                                                    <Grid item>
                                                        <span className={classes["phone-num"]}>+961 3 402 735</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                            </Grid>

                            {windowWidth < 900 ?
                                <Grid item container xs={12} paddingTop={{ xs: 3, md: 0 }} paddingBottom={1.8}>
                                    <Grid item container direction="row-reverse" className={classes["Reach-out-to-us"]}>
                                        REACH OUT TO US.
                                    </Grid>
                                </Grid> :
                                <></>}

                            <Grid item container xs={12} md={7} gap={{ xs: 1.3, md: 1.7 }}>
                                {inquiryMessageSent ?
                                    <Grid item container xs={12} className={classes["message-sent"]} marginTop={10} justifyContent="center" alignItems="center">
                                        <Grid item>
                                            Thank you. We have received your inquiry. <br></br> Our team will get in touch with you shortly
                                        </Grid>
                                    </Grid>
                                    : <>
                                        <Grid item container xs={12}>
                                            <CssTextField
                                                value={name}
                                                inputProps={{
                                                    style: {
                                                        height: "5px",
                                                    },
                                                }}
                                                fullWidth
                                                hiddenLabel
                                                focused
                                                helperText={nameError ? "Missing Field" : ""}
                                                placeholder="Full Name"
                                                sx={{ input: { color: "#black", fontSize: "15px" }, backgroundColor: "white" }}
                                                onChange={(e) => {
                                                    if (e.target.value.trim().length < 51) {
                                                        setName(e.target.value);
                                                        if (e.target.value !== "") {
                                                            setNameError(false)
                                                        }
                                                        else {
                                                            setNameError(false)
                                                        }
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item container xs={12} gap={{ xs: 1.3, md: 1 }}>
                                            <Grid item xs={12} md={5.8} lg={5.87}>
                                                <CssTextField
                                                    inputProps={{
                                                        style: {
                                                            height: "5px",
                                                        },
                                                    }}
                                                    value={email}
                                                    fullWidth
                                                    hiddenLabel
                                                    focused
                                                    helperText={emailError ? "Missing Field" : ""}
                                                    placeholder="E-mail Address"
                                                    sx={{ input: { color: "#black", fontSize: "15px" }, backgroundColor: "white" }}
                                                    onChange={(e) => {
                                                        if (e.target.value.trim().length < 65) {
                                                            setEmail(e.target.value);
                                                            if (e.target.value !== "") {
                                                                setEmailError(false)
                                                            }
                                                            else {
                                                                setEmailError(false)
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={5.8} lg={5.87}>
                                                <CssTextField
                                                    inputProps={{
                                                        style: {
                                                            height: "5px",
                                                        },
                                                    }}
                                                    value={phoneNumber}
                                                    fullWidth
                                                    placeholder="Phone Number"
                                                    helperText={phoneNumberError ? "Missing Field" : ""}
                                                    focused
                                                    sx={{
                                                        input: { color: "#black", fontSize: "15px" },
                                                        backgroundColor: "white"
                                                    }}
                                                    hiddenLabel
                                                    onChange={(e) => {
                                                        if (e.target.value.trim().length < 21) {
                                                            setPhoneNumber(e.target.value);
                                                            if (e.target.value !== "") {
                                                                setPhoneNumberError(false)
                                                            }
                                                            else {
                                                                setPhoneNumberError(false)
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={12}>
                                            <CssTextField
                                                inputProps={{
                                                    style: {
                                                        height: "100px",
                                                    },
                                                }}
                                                value={inquiries}
                                                fullWidth
                                                multiline
                                                hiddenLabel
                                                focused
                                                helperText={inquiriesError ? "Missing Field" : ""}
                                                placeholder="Leave a Message"
                                                sx={{
                                                    input: { color: "#black", fontSize: "15px" },
                                                    backgroundColor: "white"
                                                }}
                                                onChange={(e) => {
                                                    if (e.target.value.trim().length < 501) {
                                                        setInquiries(e.target.value);
                                                        if (e.target.value !== "") {
                                                            setInquiriesError(false)
                                                        }
                                                        else {
                                                            setInquiriesError(false)
                                                        }
                                                    }
                                                }} />
                                        </Grid>

                                        <Grid item container xs={12} justifyContent="center" paddingBottom={{ xs: 4, md: 0 }} paddingTop={{ xs: 3, md: 0.8 }}>
                                            <Grid item>
                                                <button onClick={sendEmailHandler} className={classes["Send"]}> Send </button>
                                            </Grid>
                                        </Grid></>}

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes["black-grid"]} width={gridWidth} height={gridHeight} />
                </Grid>
            </Grid>
        </Grid>
    </>)
}

export default ContactUsPage;
