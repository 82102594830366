import React from "react";
import { useLottie } from "lottie-react";
import VideoAnimation from "../../Assets/Animations/test2"

export default function LoadingAnimation() {
    const options = {
        animationData: VideoAnimation,
        loop: true,
        autoplay: true,
    };

    const style = {
        position: "absolute",
        zIndex: "-2",
        objectFit: "cover",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        width: "100%",
        height: "100%",
    }

    const { View } = useLottie(options, style);
    return (
        <>
            {View}
        </>
    );
}