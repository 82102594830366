import Grid from "@mui/material/Grid";
import React, { useState, useEffect, useRef } from 'react';
import classes from '../../Assets/Styles/page1.module.css';
import BodyAnimation1 from "./VideoAnimation";
import { Link } from "react-router-dom";
import img from "../../Assets/Images/bgImage1.png";
import img2 from "../../Assets/Images/bgImage11.png";
import flesh from "../../Assets/Images/flesh.png"
import windowDimensions from "../WindowDimension";


const Page1 = (props) => {

    const ref = useRef()
    const [hover, setHover] = useState(false);
    const { windowWidth, windowHeight } = windowDimensions();

    useEffect(() => {
        props.setHeight(ref?.current?.clientHeight);
        props.setPage1Position(windowWidth > 899 ? 0.9 * windowHeight : 0.8 * windowHeight);
    }, [windowWidth])

    const style = {
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-Repeat",
        backgroundSize: "cover",
    }

    const style2 = {
        backgroundImage: `url(${img2})`,
        backgroundRepeat: "no-Repeat",
        backgroundSize: "cover",
    };

    const style3 = {
        zIndex: "1",
    }

    return (<>
        <Grid ref={ref} container style={windowWidth > 550 ? windowWidth < 900 ? style2 : style3 : style} direction={"row"} height={{ xs: "80vh", md: "90vh" }}>
            {windowWidth > 900 ?
                <>
                    <Grid item className={classes["overlay"]} />
                    <BodyAnimation1 />
                </>
                :
                <Grid item className={classes["overlay"]} />}

            <Grid item container md={1.5} xs={1} />
            {windowWidth > 1000 ?
                <Grid item container className={classes["container"]} xs={11} md={10.5} >
                    <Grid item container  paddingBottom='2%'>
                        <span className={classes["Launch-your-next-tech-innovation"]}>
                            Launch your next <br></br>
                            <span> tech innovation </span>
                        </span>
                    </Grid>
                    <Grid item container position="relative" paddingTop={1.7}>
                        <Link to="/a-message-from-the-founder">
                            <Grid item onMouseOver={() => { setHover(true) }} onMouseOut={() => { setHover(false) }} className={classes["message-from-founder"]} alignItems="center" >
                                <Grid className={classes["fixed-grid"]} item position="absolute" >
                                </Grid>
                                <Grid className={classes["ani-grid"]} width={hover ? "450px" : "67px"} item position="absolute">
                                    <img style={{ position: "absolute", marginLeft: "47px", marginTop: "8px", width: "50px" }} src={flesh} alt="flesh" />
                                </Grid>
                                <Grid item marginLeft={15} position="absolute" className={classes["founder"]} >
                                    A message from our founder
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>
                </Grid>
                :
                <Grid item container className={classes["container"]} xs={11} md={10.5}>
                    <Grid item container paddingBottom={5}>
                        <span className={classes["Launch-your-next-tech-innovation"]}>
                            Launch your  next
                            tech innovation
                        </span>
                    </Grid>
                    <Link to="/a-message-from-the-founder">
                        <Grid item container paddingBottom={5} onMouseOver={() => { setHover(true) }} onMouseOut={() => { setHover(false) }} className={classes["message-from-founder"]} alignItems="center" position="relative">
                            <Grid className={classes["fixed-grid"]} item>
                            </Grid>
                            <Grid className={classes["ani-grid"]} width={hover ? windowWidth > 650 ? "450px" : "333px" : "67px"} item position="absolute">
                                <img style={{ position: "absolute", marginLeft: "38px", marginTop: "8px", width: "50px" }} src={flesh} alt="flesh" />
                            </Grid>
                            <Grid item marginLeft={13} width={windowWidth < 651 ? "218px" : "333px"} position="absolute" className={classes["founder"]} >
                                A message from our founder
                            </Grid>
                        </Grid>
                    </Link>
                </Grid>
            }

        </Grid>
    </>)
}

export default Page1;