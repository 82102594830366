import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import classes from "../Assets/Styles/MyModal.module.css";
import menupic1 from "../Assets/Images/menupic1.png"
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import windowDimensions from "../Components/WindowDimension"

const MyModal = (props) => {
    let navigate = useNavigate();
    const { windowHeight } = windowDimensions();
    const [cssStyle, setCssStyle] = useState("nav-button");
    const [cssStyle2, setCssStyle2] = useState("nav-button");
    const [cssStyle3, setCssStyle3] = useState("nav-button");
    const [servicesButtonStyle, setServicesButtonStyle] = useState("nav-button");
    const [industriesButtonStyle, setIndustriesButtonStyle] = useState("nav-button");
    const [openServices, setOpenServices] = useState(false);
    const [openIndustries, setOpenIndustries] = useState(false);

    useEffect(() => {
        if (window.location.pathname.toString() === "/") {
            setCssStyle("services");
            setCssStyle2("normal-services");
            setCssStyle3("normal-services");
            setServicesButtonStyle("normal-services");
            setIndustriesButtonStyle("normal-services")
        }
        if (window.location.pathname.toString() === "/our-values") {
            setCssStyle("normal-services");
            setCssStyle2("services");
            setCssStyle3("normal-services");
            setServicesButtonStyle("normal-services");
            setIndustriesButtonStyle("normal-services");
        }
        if (window.location.pathname.toString() === "/case-studies") {
            setCssStyle("normal-services");
            setCssStyle2("normal-services");
            setCssStyle3("services");
            setServicesButtonStyle("normal-services");
            setIndustriesButtonStyle("normal-services");
        }
        if (window.location.pathname.toString() === "/privacy-policy-and-terms") {
            setCssStyle("normal-services");
            setCssStyle2("normal-services");
            setCssStyle3("normal-services");
            setServicesButtonStyle("normal-services");
            setIndustriesButtonStyle("normal-services")
        }
        if (window.location.pathname.toString() === "/industries") {
            setCssStyle("normal-services");
            setCssStyle2("normal-services");
            setCssStyle3("normal-services");
            setServicesButtonStyle("normal-services");
            setIndustriesButtonStyle("services")
        }
        if (window.location.pathname.toString() === "/services") {
            setCssStyle("normal-services");
            setCssStyle2("normal-services");
            setCssStyle3("normal-services");
            setServicesButtonStyle("services");
            setIndustriesButtonStyle("normal-services")
        }
    })
    const [transition, setTransition] = useState(false);

    useEffect(() => {
        if (props.hideNav) {
            setTransition(true);
        }

        setOpenIndustries(false);
        setOpenServices(false);

    }, [props.hideNav, props.openModal])

    return (
        <Modal
            disableEnforceFocus
            keepMounted
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.openModal ? props.openModal : false}
            closeAfterTransition
            style={{ top: 0 }}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                style: { backgroundColor: "transparent" },
            }}
        >
            <Grid
                width="100%"
                container
                height="100%"
                className={classes["main-grid"]}
                top={props.openModal ? "0px" : "-1000px"}
                paddingBottom={windowHeight > 730 ? "500px" : "300px"}
            >
                <Grid container direction={"row"} >
                    <Grid item alignItems="center" container>
                        <Grid item container xs={0.7} />
                        <Grid
                            item
                            container
                            xs={6}
                            className={classes["hover-grid"]}
                            paddingTop={5}
                        >
                            <Grid
                                item
                                paddingLeft={1}
                                onClick={() => {
                                    navigate("/");
                                    props.setOpenModal(false);
                                }}
                            >
                                <img width={"120px"} src={menupic1} alt={"Black Arrow"} />
                            </Grid>
                            <Grid
                                item
                                paddingLeft={1}
                                onClick={() => {
                                    props.setOpenModal(false)
                                }}
                            >
                                <ExpandMoreIcon cursor="pointer" className={classes["material"]} fontSize='large' />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container >
                    <Grid container marginTop={8} alignItems={"center"}>
                        <Grid item container justifyContent="center" xs={12}>
                            <Grid item container xs={12} borderTop="solid 0.5px #c9c9c9" padding="1% 1% 1% 5%" borderBottom="solid 0.5px #c9c9c9" alignItems={"center"} onClick={() => { setOpenServices(!openServices); setOpenIndustries(false) }}>
                                <Grid item container className={classes[servicesButtonStyle]} justifyContent="flex-start" xs={8}>
                                    SERVICES
                                </Grid>
                                <Grid item container xs={4} justifyContent="flex-end" alignItems={"center"} >
                                    {openServices ?
                                        <ExpandLessIcon fontSize={"large"} />
                                        :
                                        <ExpandMoreIcon fontSize={"large"} />
                                    }
                                </Grid>

                            </Grid>
                        </Grid>
                        {openServices ?
                            <Grid item container padding="5px 30px 2px 30px" alignItems="center" justifyContent="flex-start">
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Custom Software Development</span>
                                    </Link>
                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Product Development</span>
                                    </Link>
                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Digital Commerce</span>
                                    </Link>
                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Application Services</span>
                                    </Link>

                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Technology Innovation</span>
                                    </Link>

                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Machine Learning</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Web Platform</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Complimentary Marketing
                                            Services</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Mobile Platform</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Blockchain</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Automation</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Artificial Intelligence</span>
                                    </Link>

                                </Grid>


                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> SaaS</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Enterprise Solution</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Technology Process Advisory</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Agile Transformation Advisory</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Industry Digitization</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Cloud</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2} paddingBottom={2}>
                                    <Link to={"/services"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}> Enterprise Content
                                            Management System</span>
                                    </Link>

                                </Grid>
                            </Grid>
                            :
                            <></>
                        }
                        <Grid item container padding="1% 1% 1% 5%" borderTop={openServices ? "solid 0.5px #c9c9c9" : ""} borderBottom="solid 0.5px #c9c9c9" xs={12} onClick={() => { setOpenServices(false); setOpenIndustries(!openIndustries) }} alignItems={"center"}>
                            <Grid item xs={8} className={classes[industriesButtonStyle]} container justifyContent="flex-start">
                                INDUSTRIES
                            </Grid>
                            <Grid item container xs={4} justifyContent="flex-end" alignItems={"center"} >
                                {openIndustries ?
                                    <ExpandLessIcon fontSize={"large"} />
                                    :
                                    <ExpandMoreIcon fontSize={"large"} />
                                }
                            </Grid>

                        </Grid>
                        {openIndustries ?
                            <Grid item container padding="5px 30px 2px 30px" alignItems="center" justifyContent="flex-start" >
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Capital Markets</span>
                                    </Link>
                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Automative</span>
                                    </Link>

                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Capital Markets</span>
                                    </Link>
                                    Logistics
                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Food & Beverages</span>
                                    </Link>

                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Insurance</span>
                                    </Link>

                                </Grid>
                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Agribusiness</span>
                                    </Link>
                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Health Care</span>
                                    </Link>
                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Travel & Hospitality</span>
                                    </Link>
                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Real Estate</span>
                                    </Link>
                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Banking</span>
                                    </Link>
                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Ministries</span>
                                    </Link>
                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Beauty & Personal Care</span>
                                    </Link>
                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Construction</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Public Service</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Law Firm</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Software & Platform</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Manufacturing</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Higher Education</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Telecom</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Retail & Distribution</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Utilities</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>High Tech</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Communication & Media</span>
                                    </Link>

                                </Grid>

                                <Grid item xs={12} paddingTop={2} paddingBottom={2}>
                                    <Link to={"/industries"} className={classes["text"]}>
                                        <span onClick={() => {
                                            props.setHideNav(false); props.setOpenModal(false);
                                            props.setMyModalBoldButton(false);
                                        }}>Consumer Goods & Services</span>
                                    </Link>

                                </Grid>
                            </Grid>
                            : <></>
                        }
                        <Grid item container justifyContent="center" xs={12}>
                            <Grid item container xs={12} padding="1% 1% 1% 5%" borderBottom="solid 0.5px #c9c9c9" alignItems={"center"} onClick={() => {
                                navigate("/our-values"); props.setMyModalBoldButton(false); props.setHideNav(false); props.setOpenModal(false); window.scrollTo(0,0)
                            }}>
                                <Grid item container className={classes[cssStyle2]} justifyContent="flex-start" xs={8}
                                >
                                    OUR VALUES
                                </Grid>
                                <Grid item container xs={4} justifyContent="flex-end" alignItems={"center"} >
                                    <ExpandMoreIcon fontSize={"large"} sx={{ color: "white" }} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container justifyContent="center" xs={12}>
                            <Grid item container xs={12} padding="1% 1% 1% 5%" borderBottom="solid 0.5px #c9c9c9" alignItems={"center"} onClick={() => {
                                props.setHideNav(false); props.setOpenModal(false);
                                navigate("/case-studies"); props.setMyModalBoldButton(false); window.scrollTo(0,0)
                            }}>
                                <Grid item container className={classes[cssStyle3]} justifyContent="flex-start" xs={8}
                                >
                                    OUR CLIENTS
                                </Grid>
                                <Grid item container xs={4} justifyContent="flex-end" alignItems={"center"} >
                                    <ExpandMoreIcon fontSize={"large"} sx={{ color: "white" }} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container justifyContent="center" xs={12}>
                            <Grid item container xs={12} padding="1% 1% 1% 5%" borderBottom="solid 0.5px #c9c9c9" alignItems={"center"} onClick={() => {
                                navigate("/"); props.setHideNav(false);
                                props.setOpenModal(false); props.setMyModalBoldButton(false); window.scrollTo(0,0)
                            }}>
                                <Grid item container className={classes[props.myModalBoldButton ? "normal-services" : cssStyle]} justifyContent="flex-start" xs={8}
                                >
                                    ABOUT DIGITAL PRESTIGE
                                </Grid>
                                <Grid item container xs={4} justifyContent="flex-end" alignItems={"center"} >
                                    <ExpandMoreIcon fontSize={"large"} sx={{ color: "white" }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default MyModal;
