import Grid from "@mui/material/Grid";
import classes from "../../Assets/Styles/FounderMessage.module.css"
import FounderLinkedIn from "../../Assets/Images/FounderLinkedIn.png";


const Text = (props) => {
    return <>
        <Grid container paddingTop={5} bgcolor="rgba(209, 181, 117)">
            <Grid item container md={1.35} xs={1} />

            <Grid item container md={10.5} lg={10} xl={9} xs={11} className={classes["text"]}>

                <Grid item >
                    In the midst of this exciting wave of digitalization, delicacy in the execution of the businesses’
                    technology ideas and innovations, became an urgent find for many executives.
                </Grid>
                <Grid item marginTop={7} xs={11.5}>
                    Digital Prestige is much more than a technology launcher. It is a place where tech innovation teams are formed, trained and nurtured, the way I learned, in the past two decades, that building technology is a collaborative process, with a strive for excellence in creating tools for the future."

                </Grid>

                <Grid item container md={11.5} paddingRight={{xs:3, md:0}} paddingBottom={6} textAlign="right" justifyContent="flex-end" paddingTop={5}>
                    <br></br>
                    Rime Diab <br></br>
                    Founder & CEO <br></br>
                    <Grid item container justifyContent="flex-end" alignItems="center">
                        <a
                            href="https://www.linkedin.com/in/rimediab/"
                            target="_blank"
                            rel="noreferrer"

                        >
                            <img src={FounderLinkedIn} className={classes["img-style2"]} alt="LinkedIn" />
                        </a>
                        <a
                            href="https://www.linkedin.com/in/rimediab/"
                            target="_blank"
                            style={{textDecoration:"none", color:"white"}}
                            rel="noreferrer">
                            <Grid item paddingBottom={{ md: 2, xs: 1 }} paddingLeft={2}> LinkedIn </Grid>
                        </a>

                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    </>
}

export default Text;