import React, { useRef, useEffect } from "react";
import { motion, useMotionValue, useAnimation } from "framer-motion";
import styled from "@emotion/styled";
import windowDimensions from "../../../WindowDimension";

const StyledCard = styled(motion.div)`
  position: absolute;
`;

const Card = ({ children, moveAwayBy, setConstrained, constrained, moveAni, style, onVote, id, ...props }) => {
  
  const cardElem = useRef(null);
  const { windowWidth, windowHeight } = windowDimensions();

  const x = useMotionValue(0);
  const controls = useAnimation();
  useEffect(() => {
    controls.start({
      x: 0
    });
    setConstrained(true)
  }, [moveAni])


  const flyAway = (min) => {
    setConstrained(false);
    const childPosition = windowWidth< 500 ? cardElem.current.getBoundingClientRect().width/4 : cardElem.current.getBoundingClientRect().width/2 ;
    const childWidth = cardElem.current.getBoundingClientRect().width;

    if (
      windowWidth / 2 - childPosition / 2 >
      cardElem.current.getBoundingClientRect().x + childWidth
    ) {
      setConstrained(false);
      controls.start({
        x: -moveAwayBy,
      });
      onVote(false)
    }
    else if (windowWidth / 2 - childPosition / 2 <
      cardElem.current.getBoundingClientRect().x) {
      setConstrained(false);
      controls.start({
        x: moveAwayBy
      });
      onVote(true)
      setConstrained(true)
    }
    else {
      setConstrained(true)
    }
    setConstrained(true)
  };

  return (
   
      <StyledCard
        animate={controls}
        transition={{ ease: "easeIn", duration: 0.7 }}
        dragConstraints={constrained && { left: 0, right: 0, top: 0, bottom: 0 }}
        dragElastic={1}
        ref={cardElem}
        style={{ x }}
        onDragEnd={() => flyAway(500)}
        whileTap={{ scale: 1.1 }}
        {...props}
      >
        {children}
      </StyledCard>
  );
}

export default Card;