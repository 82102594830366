import Grid from "@mui/material/Grid";
import React, { useState, useEffect, useRef } from 'react';
import classes from '../../Assets/Styles/CaseStudiesPage1.module.css';
import Animation from "./animation";
import img from "../../Assets/Images/page3phoneimg1.png";
import useWindowDimensions from "../WindowDimension";

const Page1 = (props) => {

    const ref = useRef(null);
    const {windowWidth} = useWindowDimensions();

    useEffect(() => {
        props.setHeight(ref.current.clientHeight);
    }, [])

    const style = {
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-Repeat",
        backgroundSize: "cover",

    };
    const style2 = {
        zIndex: "1",
    }

    return (
        <>
            <Grid ref={ref} container style={windowWidth > 800 ? style2 : style} direction={"row"} height={{ xs: "75vh", md: "90vh" }}>
                <Grid item className={classes["overlay"]} />
                <Grid item container md={1.4} xs={1} />
                <Grid item container className={classes["container"]} xs={11} md={10.5} >
                    <Grid item xs={11.7} xl={10} container paddingBottom={5} >
                        <span className={classes["title"]}>
                            A selection of products  in various stages of  ongoing development
                        </span>
                    </Grid>
                </Grid>

                {windowWidth > 800
                    ?
                    <Grid item >
                        <Animation />
                    </Grid>
                    :
                    <></>}

            </Grid>
        </>
    )
}

export default Page1;