import React, { useState, useEffect, useRef } from 'react';
import '../Assets/Styles/Footer.css';
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import linkedin from "../Assets/Images/linkedin.svg";
import instagram from "../Assets/Images/instagram.svg";
import location from "../Assets/Images/location.svg";
import circleDesign1 from '../Assets/Images/circleDesign1.png'
import circleDesign2 from '../Assets/Images/circleDesign2.png'
import windowDimensions from "../Components/WindowDimension";


const style2 = {
    width: "70%",
    right: 0,
}

const Footer = (props) => {
    let navigate = useNavigate();
    const ref = useRef();

    const { windowWidth, windowHeight } = windowDimensions();

    const [isLeb, setIsLeb] = useState(true);
    const [isUAE, setIsUAE] = useState(false);
    const [whereToFindUsGridHeight, setWhereToFindUsGridHeight] = useState(0);

    useEffect(() => {
        setWhereToFindUsGridHeight(ref?.current?.clientHeight);
    }, [])

    return (
        <>
            <Grid
                container
                width="100%"
                bgcolor="#000"
                paddingLeft={{ lg: "12%", md: "12%", xs: "8%" }}
                paddingBottom={4}>

                <Grid item container >
                    <Grid item xs={6} paddingTop={5} className={"Let-us-make-it-a-reality"}>
                        Let us make it a reality
                    </Grid>

                    {/* <Grid item container justifyContent="flex-end" xs={6} paddingRight={{ xs: 1, md: 25 }}>
                        <img src={circleDesign2} alt="design2" />
                    </Grid> */}
                </Grid>

                <Grid item paddingTop={{ md: 1, xs: 2 }} borderBottom="solid 5px #c8a047" width="85%">
                </Grid>

                <Grid item container>
                    <Grid item container md={4.2}>
                        <Grid paddingTop={4.5} xs={12} item className={'WHERE-TO-FIND-US'}>
                            WHERE TO FIND US
                        </Grid>

                        <Grid item container paddingTop={2} paddingBottom={1}>
                            <Grid item md={1.4} xs={1.3}>
                                <a href="https://www.google.com/maps/place/Berytech+BDD+1294/@33.8902815,35.5061737,17z/data=!3m1!4b1!4m5!3m4!1s0x151f16e205de00eb:0x6c6cb556938a6bf2!8m2!3d33.8902815!4d35.5061737" target="_blank">
                                    <img className={'location2'} src={location} alt="location" />
                                </a>
                            </Grid>

                            {isLeb ?
                                <Grid ref={ref} item>
                                    <span className={"location"}>
                                        Digital Prestige SAL<br />
                                        Al Bashoura, Nassif Al Yaziji Street<br />
                                        Beirut Digital District, Building #1294, 5th floor<br />
                                        Beirut, Lebanon
                                    </span>
                                </Grid>
                                :
                                <Grid item md={10} height={whereToFindUsGridHeight} className={"coming-soon"}>
                                    <span className={"location"}>
                                        Digital Prestige International<br />
                                        DMCC<br />
                                        Dubai, UAE
                                    </span>
                                </Grid>}

                            <Grid item container paddingBottom={{ xs: 6, md: 0 }} paddingTop={2}>

                                <Grid item xs={2.5} sm={1.6} md={3}>
                                    <span
                                        style={{ cursor: "pointer", backgroundColor: isLeb ? "#c7a048" : "transparent", color: isLeb ? "#000" : "#fff" }}
                                        className={`dp-link-circle-country dp-footer-country-iso-font ${isLeb ? "dp-link-circle-active-country" : ""
                                            }`}

                                        onMouseOver={(e) => { e.target.style.backgroundColor = "#c7a048"; e.target.style.color = "#000" }}
                                        onMouseOut={(e) => { isLeb ? e.target.style.backgroundColor = "#c7a048" : e.target.style.backgroundColor = "transparent"; isLeb ? e.target.style.color = "#000" : e.target.style.color = "#fff" }}
                                        // style={isLeb ? style2 : style}
                                        onClick={(e) => {
                                            setIsLeb(true);
                                            e.target.style.backgroundColor = "#c7a048";
                                            setIsUAE(false);
                                        }}
                                    >
                                        LEB
                                    </span>
                                </Grid>
                                <Grid item xs={3}>
                                    <span
                                        className={`dp-link-circle-country  dp-footer-country-iso-font ${isUAE ? "dp-link-circle-active-country" : ""
                                            }`}

                                        onMouseOver={(e) => { e.target.style.backgroundColor = "#c7a048"; e.target.style.color = "#000" }}
                                        onMouseOut={(e) => { isUAE ? e.target.style.backgroundColor = "#c7a048" : e.target.style.backgroundColor = "transparent"; isUAE ? e.target.style.color = "#000" : e.target.style.color = "#fff" }}
                                        style={{ cursor: "pointer", backgroundColor: isUAE ? "#c7a048" : "transparent", color: isUAE ? "#000" : "#fff" }}
                                        onClick={(e) => {
                                            setIsLeb(false);
                                            setIsUAE(true);
                                        }}
                                    >
                                        UAE
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container md={3} direction="column">
                        <Grid paddingTop={{ md: 4.5, xs: 0 }} item className={'WHERE-TO-FIND-US'}>
                            CONTACT US
                        </Grid>

                        <Grid item paddingTop={2} onMouseOut={(e) => { e.target.style.color = "white" }} onMouseOver={(e) => { e.target.style.color = "#c7a048" }} >
                            <a className="mail-style" href="mailto:admin@digitalprestige.com" > admin@digitalprestige.com</a>
                        </Grid>
                        {/* <Grid item paddingTop={1} onMouseOut={(e) => { e.target.style.color = "white" }} onMouseOver={(e) => { e.target.style.color = "#c7a048" }} >
                            <a className="mail-style" href="mailto:admin@digitalprestige.com" > careers@digitalprestige.com</a>
                        </Grid> */}


                        <Grid item container paddingTop={2}>
                            <Grid item xs={2} sm={1.6} md={2.4}>
                                <a
                                    href="https://www.linkedin.com/company/digitalprestige/mycompany/?viewAsMember=true"
                                    target="_blank"
                                    rel="noreferrer"

                                >
                                    <img src={linkedin} className="logo" width="38" height="38" alt="LinkedIn" />
                                </a>
                            </Grid>

                            <Grid item xs={2} sm={1.6} md={0.5}>
                                <a
                                    href="https://www.instagram.com/digitalprestige.mena/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={instagram} className="logo" width="38" height="38" alt="Instagram" />
                                </a>
                            </Grid>
                            {/* {windowWidth < 900 &&
                                <Grid item xs={8} sm={8}>
                                    <img src={circleDesign1} style={style2} alt="design" />
                                </Grid>
                            } */}
                        </Grid>

                    </Grid>

                    {/* <Grid item container direction="column" md={4.8}>
                        <Grid paddingTop={{ xs: 0, md: 4.5 }} item className={'WHERE-TO-FIND-US'}>
                            VISIT
                        </Grid>

                        <Grid item paddingTop={2}>
                            <a className={"dp-academy"} href="https://academy.digitalprestige.com/" target="_blank" rel="noreferrer">
                                DP ACADEMY
                            </a>
                        </Grid>
                        <a href="https://stories.digitalprestige.com/" target='_blank' rel="nonreferrer" style={{textDecoration:"none"}}>
                            <Grid item paddingTop={2}>

                                <Grid item container >
                                    <Grid item className={"dp"} position="relative">
                                        DIGITAL PRESTIGE
                                        <Grid item className={"dp-stories"} position="absolute" top="40%" right="0%">
                                            Stories
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </a>
                    </Grid> */}

                </Grid>
                <Grid item alignItems="center" paddingTop={{ xs: 3, md: 2 }} paddingBottom={{ xs: 5, md: 0 }} container md={10}>
                    <Grid item md={6}>
                        <span className="terms">
                            <span
                                onClick={() => { navigate("/privacy-policy-and-terms"); window.scrollTo({ top: 0, behavior: 'smooth' }); }}
                            >
                                Privacy Policy Terms and Conditions
                            </span>


                        </span>{windowWidth < 500 ? <br></br> : <></>}
                        <span className="terms2"> I  © {new Date().getFullYear()} DIGITAL PRESTIGE</span>
                    </Grid>
                    {/* {windowWidth > 900 &&
                        <Grid item container md={5} justifyContent="flex-end">
                            <Grid item >
                                <img src={circleDesign1} width="90%" alt="design1" />
                            </Grid>
                        </Grid>} */}
                </Grid>
            </Grid>
        </>

    )
}

export default Footer;