import axios from 'axios';
import * as constFile from '../dpConst';

const HOME_PAGE_LIST = constFile.HOME_PAGE_LIST;
export const homePageList = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + HOME_PAGE_LIST}`, {}
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}

const BTS_PAGINATED_LIST = constFile.BTS_PAGINATED_LIST;
export const btsPaginatedList = (data) => {
    return axios
        .post(`${process.env.REACT_APP_URL + BTS_PAGINATED_LIST}`, data
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}

const INSIGHT_PAGINATED_LIST = constFile.INSIGHT_PAGINATED_LIST;
export const insightPaginatedList = (data) => {
    return axios
        .post(`${process.env.REACT_APP_URL + INSIGHT_PAGINATED_LIST}`, data
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}

const OUR_STORY_BY_ID = constFile.OUR_STORY_BY_ID;
export const ourStoryById = (id) => {
    return axios
        .get(`${process.env.REACT_APP_URL + OUR_STORY_BY_ID}`,
        {
            params: {
                Id: id
            },
        },
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}

const NEXT_BTS_STORY = constFile.NEXT_BTS_STORY;
export const nextBtsStory = (id) => {
    return axios
        .get(`${process.env.REACT_APP_URL + NEXT_BTS_STORY}`,
        {
            params: {
                Id: id
            },
        },
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}

const NEXT_INSIGHT_STORY = constFile.NEXT_INSIGHT_STORY;
export const nextInsightStory = (id) => {
    return axios
        .get(`${process.env.REACT_APP_URL + NEXT_INSIGHT_STORY}`,
        {
            params: {
                Id: id
            },
        },
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}

const SHUFFLED_BTS_STORY = constFile.SHUFFLED_BTS_STORY;
export const shuffledBTSStory = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + SHUFFLED_BTS_STORY}`, {}
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}