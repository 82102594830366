import Grid from "@mui/material/Grid";
import classes from "../../../Assets/Styles/OurStoriesHomePage.module.css";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import readAndDiscover from "../../../Assets/Images/readAndDiscover.png";
import nextBlackArrow from "../../../Assets/Images/newBlackArrow.png";
import nextBlueArrow from "../../../Assets/Images/blueNextButton.png";
import newPreviousBlackArrow from "../../../Assets/Images/newPreviousBlackArrow.png";
import previousBlueArrow from "../../../Assets/Images/previousBlueArrow.png";
import goBack from "../../../Assets/Images/goBack.png";
import CircularProgress from "@mui/material/CircularProgress";
import windowDimensions from "../../WindowDimension";

import * as ourServices from '../../../Services/our-story-services.proxy';

const btsPaginatedList = ourServices.btsPaginatedList;

const BTSList = (props) => {

    const { windowWidth, windowHeight } = windowDimensions();

    const [pageSize, setPageSize] = useState(windowWidth > 600 ? 9 : 3);
    const [pageNumber, setPageNumber] = useState(1);
    const [btsList, setBtsList] = useState([]);
    const [totalBtsStories, setTotalBtsStories] = useState(0)
    const [loadingAni, setLoadingAni] = useState(false);
    const [btsNextArrowSrc, setBtsNextArrowSrc] = useState(nextBlackArrow);
    const [btsPreviousArrowSrc, setBtsPreviousArrowSrc] = useState(newPreviousBlackArrow)
    const [goBackImgSrc, setGoBackImgSrc] = useState(goBack)

    const data = {
        PageSize: pageSize,
        PageNumber: pageNumber
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pageNumber, loadingAni])

    useEffect(() => {

        let isApiSubscribed = true;
        window.scrollTo(0, 0);
        setLoadingAni(true)
        btsPaginatedList(data).then((response) => {
            if (!response.error && isApiSubscribed) {
                setBtsList(response.BTSStories);
                setTotalBtsStories(response.Total)
                setLoadingAni(false);
            }
        })

        return () => {
            isApiSubscribed = false;
        };

    }, [pageNumber])



    return (
        <>
            <Grid container padding={{ xs: "11% 0% 2% 0%", sm: "11% 2% 2% 2%", md: "5.5% 1.3% 2% 1.3%", lg: "1.7% 6% 2% 6%" }} >

                <Grid item container justifyContent="center" alignItems="center">
                    <Grid item xs={11} md={12}>
                        <Link to={"/our-stories"}>
                            <img src={goBack} style={{ cursor: "pointer", marginBottom: "15px" }} width={windowWidth > 900 ? "auto" : "8%"} alt="go back" />
                        </Link>
                        <img src={readAndDiscover} width="100%" alt="read and discover" />
                    </Grid>
                    <Grid item container xs={11} md={12} justifyContent="flex-end">
                        <Grid item className={classes["BEHIND-THE-SCENES-STORIES"]}>
                            {windowWidth > 500 ? "WITH OUR BEHIND THE SCENES STORIES" :
                                <span>WITH OUR BEHIND THE <br></br><span>SCENES STORIES</span></span>}
                        </Grid>
                    </Grid>
                </Grid>

                {loadingAni ?
                    <Grid item container justifyContent="center" alignItems="center" paddingTop={20} paddingBottom={20}>
                        <CircularProgress size={40} sx={{ color: "#1F96C2" }} />
                    </Grid>
                    :
                    <>
                        <Grid item container paddingBottom={totalBtsStories ? totalBtsStories < pageSize ? 13 : 0 : 0} paddingTop={{ xs: 17, md: 25 }} gap={{ sm: 1.5, md: 1.2, lg: 2, xl: 2.4 }} justifyContent="center">
                            {btsList?.map((item) => (
                                <Grid item container key={item.Id} alignSelf="flex-start" xs={11} md={3.85} sm={5.8} paddingBottom={8}>
                                    <Grid item className={classes["Rectangle-982"]}>
                                        <Link to={`/BTS?Id=${item.Id}`} >
                                            <img src={item.CoverPhoto && item.CoverPhoto?.URL} width="100%" height="100%" lt="cover image" />
                                        </Link>
                                    </Grid>
                                    <Grid item container gap={1} alignSelf="flex-start" marginTop={2} className={classes["tech"]} >
                                        {item.Tags?.map((tag, index) => (
                                            <span key={index} className={classes["Rectangle-988"]}> {tag} </span>
                                        ))}
                                    </Grid>

                                    <Grid item container alignSelf="flex-start" paddingTop={0.7} xs={12} >
                                        <Link to={`/BTS?Id=${item.Id}`} className={classes["THE-TITLE-OF-THE-PODCAST"]}>
                                            {item.Title}
                                        </Link>
                                    </Grid>

                                    <Grid item paddingTop={1} xs={12} className={classes["small-description"]}>
                                        {item.Brief}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                        {pageSize > totalBtsStories && pageNumber === 1 ?
                            <></>
                            :
                            <Grid item container paddingBottom={13} paddingTop={3}>
                                <Grid item container xs={6} justifyContent="flex-start">
                                    {pageNumber > 1 ?
                                        <Grid item onMouseOver={() => { setBtsPreviousArrowSrc(previousBlueArrow) }} onMouseOut={() => { setBtsPreviousArrowSrc(newPreviousBlackArrow) }} onClick={() => { setLoadingAni(true); setPageNumber(pageNumber + 1) }} style={{ cursor: "pointer" }}>
                                            <img src={btsPreviousArrowSrc} width="100%" alt="next arrow" />
                                        </Grid>
                                        :
                                        <></>}
                                </Grid>

                                <Grid item container xs={6} justifyContent="flex-end">
                                    {pageNumber * pageSize >= totalBtsStories ? <></>
                                        :
                                        <Grid item onMouseOver={() => { setBtsNextArrowSrc(nextBlueArrow) }} onMouseOut={() => { setBtsNextArrowSrc(nextBlackArrow) }} onClick={() => { setLoadingAni(true); setPageNumber(pageNumber - 1) }} style={{ cursor: "pointer" }}>
                                            <img src={btsNextArrowSrc} width="100%" alt="next arrow" />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </>}
            </Grid>
        </>
    )
}

export default BTSList;