import React, { useState, useEffect, useRef } from 'react';
import '../Assets/Styles/StoriesFooter.css';
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import linkedin from "../Assets/Images/linkedin.svg";
import instagram from "../Assets/Images/instagram.svg";
import rotatedStory from "../Assets/Images/rotatedStory.png";
import windowDimensions from "../Components/WindowDimension";


const style = {
    position: "absolute",
    top: "0%",
    right: "12%"
}

const style2 = {
    position: "absolute",
    right: "-15%",
    top: "7%"
}


const StoriesFooter = (props) => {
    let navigate = useNavigate();
    const ref = useRef();

    const { windowWidth, windowHeight } = windowDimensions();
    const [isLeb, setIsLeb] = useState(true);
    const [isUAE, setIsUAE] = useState(false);
    const [whereToFindUsGridHeight, setWhereToFindUsGridHeight] = useState(0);

    useEffect(() => {
        setWhereToFindUsGridHeight(ref?.current?.clientHeight);
    }, [])

    return (
        <>
            <Grid
                container
                width="100%"
                bgcolor="#c8a047"
                overflow="hidden"
                paddingLeft={{ lg: "6%", md: "6%", xs: "5%" }}
                position="relative">
                {windowWidth > 900 ?
                    <Grid item className={"stories"} style={style}>
                        Stories
                    </Grid> :
                    <Grid item className={"stories"} style={style2}>
                        <img src={rotatedStory} alt="rotated img" />
                    </Grid>}


                <Grid item container >
                    <Grid item xs={9.5} sm={12} paddingTop={4} className={"idea"}>
                        Apprise your story
                    </Grid>

                </Grid>

                <Grid item paddingTop={{ md: 2, xs: 2 }} borderBottom="solid 5px #000" width={windowWidth > 656 ? "525px" : "90%"} />

                <Grid item container>
                    <Grid item container md={4.7}>
                        <Grid paddingTop={4.5} item className={'WHERE-TO-FIND-US'}>
                            WHERE TO FIND US
                        </Grid>

                        <Grid item container paddingTop={2} paddingBottom={1}>
                            {isLeb ?
                                <Grid ref={ref} item>
                                    <span className={"location"}>
                                        Digital Prestige SAL<br />
                                        Al Bashoura, Nassif Al Yaziji Street<br />
                                        Beirut Digital District, Building #1294, 5th floor<br />
                                        Beirut, Lebanon
                                    </span>
                                </Grid>
                                :
                                <Grid item md={10} height={whereToFindUsGridHeight} className={"coming-soon"}>
                                    <span className={"location"}>
                                        Digital Prestige International<br />
                                        DMCC<br />
                                        Dubai, UAE
                                    </span>
                                </Grid>}

                            <Grid item container paddingBottom={{ xs: 3, md: 4 }} paddingTop={2}>

                                <Grid item xs={2.5} sm={1.6} md={3}>
                                    <span
                                        className={`dp-link-circle-country dp-footer-country-iso-font ${isLeb ? "dp-link-circle-active-country" : ""
                                            }`}
                                        onMouseOver={(e) => {
                                            e.target.style.cursor = "pointer"
                                        }}
                                        onClick={(e) => {
                                            setIsLeb(true);
                                            setIsUAE(false);
                                        }}
                                    >
                                        LEB
                                    </span>
                                </Grid>
                                <Grid item xs={3}>
                                    <span
                                        className={`dp-link-circle-country  dp-footer-country-iso-font ${isUAE ? "dp-link-circle-active-country" : ""
                                            }`}
                                        onMouseOver={(e) => {
                                            e.target.style.cursor = "pointer"
                                        }}
                                        onClick={(e) => {
                                            setIsLeb(false);
                                            setIsUAE(true);
                                        }}
                                    >
                                        UAE
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item zIndex="1" container md={3.3} paddingTop={{ xs: 2, md: 0 }} direction="column">
                        <Grid paddingTop={{ md: 4.5, xs: 0 }} item className={'WHERE-TO-FIND-US'}>
                            CONTACT US
                        </Grid>

                        <Grid item paddingTop={2} >
                            <a className={"mail-style"} href="mailto:admin@digitalprestige.com" > admin@digitalprestige.com</a>
                        </Grid>

                        <Grid item container paddingTop={3}>
                            <Grid item xs={2.5} sm={1.6} md={3.5}>
                                <a
                                    href="https://www.linkedin.com/company/digitalprestige"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={linkedin} className="logo" width="38" height="38" alt="LinkedIn" />
                                </a>
                            </Grid>

                            <Grid item xs={2} sm={1.6} md={0.5}>
                                <a
                                    href="https://www.instagram.com/digitalprestige.mena/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={instagram} className="logo" width="38" height="38" alt="Instagram" />
                                </a>
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* <Grid item container direction="column" paddingBottom={4} md={4}>

                        <Grid paddingTop={{ md: 4.5, xs: 6 }} item className={'WHERE-TO-FIND-US'}>
                            VISIT
                        </Grid>
                        <Grid item paddingTop={2}>
                            <a style={{ textDecoration: "none" }} href="https://academy.digitalprestige.com/" target="_blank" rel="noreferrer">
                                <span className="DP-ACADEMY">
                                    <span className="text-style-1">DP </span>
                                    ACADEMY
                                </span>
                            </a>

                        </Grid>
                        <Grid item container justifyContent="flex-start" paddingTop={2}>
                            <span className={"dp-academy"} onClick={() => { props.setHideNav(false); window.open("/") }}>
                                <Grid item>
                                    DIGITAL
                                </Grid>
                                <Grid item>
                                    PRESTIGE
                                </Grid>
                            </span>
                        </Grid>
                    </Grid> */}

                    <Grid item alignItems="center" paddingTop={{ xs: 3, md: 0 }} paddingBottom={{ xs: 5, md: 3 }} container md={10}>
                        <Grid item md={6}>
                            <span className="termss">
                                <span
                                    onClick={() => { navigate("/privacy-policy-and-terms"); props.setHideNav(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}
                                >
                                    Privacy Policy Terms and Conditions
                                </span>

                            </span>{windowWidth < 500 ? <br></br> : <></>}
                            <span className="terms2"> I  © {new Date().getFullYear()} DIGITAL PRESTIGE</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>

    )
}

export default StoriesFooter;