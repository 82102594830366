import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react'
import classes from '../../Assets/Styles/OurPhases.module.css';
import Carousel from 'react-material-ui-carousel'
import windowDimensions from "../WindowDimension";


const items = [
    {
        title: "Plan",
        name: "Create Roadmap",
        description: "In this phase, we create the product roadmap. Start the customer journey by understanding the business needs of the stakeholders and the value proposition being developed. Define the product vision, so that the software reflects all needed aspects."
    },
    {
        title: "Identify",
        name: "Product Requirements",
        description: "The core of this phase is to document the product features based on the product mapping. We identify all workflows and conduct workshops to validate and finalize the user journey."
    },
    {
        title: "Design",
        name: "Architecture & launch",
        description: "In this phase, we gather all product documentation to define the technology architecture, begin UX/UI design and start developing the software. We launch your first MVP or your scaled digital product within a limited time period."
    },
    {
        title: "Grow",
        name: "Post Launch Planning",
        description: "We provide the growth and scalability plan with required resources, for achieving all milestones."
    }
]


const OurPhases = (props) => {

    const {windowWidth} = windowDimensions();

    return (
        <>
            <Grid container position="relative" marginTop={{ md: 20, xs: 15 }} paddingBottom={25} direction={"row"} maxWidth={'xl'}>
                <Grid item container md={1.5} xs={1} />
                <Grid item container md={9.5} xs={11} direction="column" >
                    <Grid item marginBottom={{ xs: 2, md: 10 }} className={classes["our-phases"]}>
                        <span> Our <span className={classes["our-phases2"]}> Phases</span> </span>
                    </Grid>
                    {windowWidth > 900
                        ?
                        <Grid item container direction="row" >
                            <Grid item container direction="column" zIndex={"1"} backgroundColor="white" md={2.7} xl={2.8} className={classes["box"]}>
                                <Grid item container justifyContent="center" alignItems="center" className={classes["Plan"]}>
                                    Plan
                                </Grid>

                                <Grid item container height="45px" justifyContent="center" alignItems="center" marginTop={{ xs: 0, md: 2 }}>
                                    <span className={classes["Create-Roadmap"]}>Create Roadmap </span>
                                </Grid>

                                <Grid item container justifyContent="center" alignItems="center" paddingTop={{ xs: 0, md: 3 }} className={classes["In-this-phase"]}>
                                    <span>
                                        In this phase, we create the product roadmap. Start the customer journey by
                                        understanding the business needs of the stakeholders and the value proposition
                                        being developed. Define the product vision, so that the software reflects all
                                        needed aspects.
                                    </span>
                                </Grid>
                            </Grid>

                            <Grid item marginLeft={1.1} zIndex={"1"} backgroundColor="white" container direction="column" md={2.7} xl={2.8} className={classes["box"]}>
                                <Grid item container justifyContent="center" alignItems="center" className={classes["Plan"]}>
                                    Identify
                                </Grid>

                                <Grid item container height="45px" justifyContent="center" alignItems="center" marginTop={{ xs: 0, md: 2 }}>
                                    <span className={classes["Create-Roadmap"]}> Product Requirements </span>
                                </Grid>

                                <Grid item container justifyContent="center" alignItems="center" paddingTop={{ xs: 0, md: 3 }} className={classes["In-this-phase"]}>
                                    <span>
                                        The core of this phase is to document the product features
                                        based on the product mapping. We identify
                                        all workflows and conduct workshops to validate and finalize the user journey.
                                    </span>
                                </Grid>
                            </Grid>

                            <Grid item container zIndex={"1"} backgroundColor="white" marginLeft={1.1} direction="column" md={2.7} xl={2.8} className={classes["box"]}>
                                <Grid item container justifyContent="center" alignItems="center" className={classes["Plan"]}>
                                    Design
                                </Grid>

                                <Grid item container height="45px" justifyContent="center" alignItems="center" marginTop={{ xs: 0, md: 2 }}>
                                    <span className={classes["Create-Roadmap"]}>Architecture & launch </span>
                                </Grid>

                                <Grid item container justifyContent="center" alignItems="center" paddingTop={{ xs: 0, md: 3 }} className={classes["In-this-phase"]}>
                                    <span>
                                        In this phase, we gather all product documentation to define the technology
                                        architecture, begin UX/UI design and start developing the software.
                                        We launch your first MVP
                                        or your scaled digital product within a limited time period.
                                    </span>
                                </Grid>
                            </Grid>

                            <Grid item container zIndex={"1"} backgroundColor="white" marginLeft={1.1} direction="column" md={2.7} xl={2.8} className={classes["box"]}>
                                <Grid item container justifyContent="center" alignItems="center" className={classes["Plan"]}>
                                    Grow
                                </Grid>

                                <Grid item container height="45px" justifyContent="center" alignItems="center" marginTop={{ xs: 0, md: 2 }}>
                                    <span className={classes["Create-Roadmap"]}>Post Launch Planning </span>
                                </Grid>

                                <Grid item container justifyContent="center" alignItems="center" paddingTop={{ xs: 0, md: 3 }} className={classes["In-this-phase"]}>
                                    <span>
                                        We provide the growth and scalability plan with
                                        required resources, for achieving all milestones.
                                    </span>
                                </Grid>
                            </Grid>

                        </Grid>
                        :
                        <Grid item container marginTop={8} justifyContent={"center"} paddingRight={3}>
                            <Carousel duration={600} animation="fade" activeIndicatorIconButtonProps={{
                                style: {
                                    color: '#c8a047'
                                }

                            }}
                                swipeable showIndicators className={classes["carousel"]} >
                                {
                                    items.map((item, i) => <Item key={i} item={item} />)
                                }
                            </Carousel>
                        </Grid>
                    }
                </Grid>

            </Grid>
        </>
    )
}

function Item(props) {
    return (
        <Grid item container alignItems="center" direction="column">

            <Grid item className={classes["plan2"]} >
                {props.item.title}
            </Grid>

            <Grid item marginTop={2} className={classes["name2"]} >
                {props.item.name}
            </Grid>

            <Grid item marginTop={3} height="320px" className={classes["description2"]}>
                {props.item.description}
            </Grid>

        </Grid>
    )
}

export default OurPhases;