import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react';
import classes from '../../Assets/Styles/PileCubesPage.module.css';
import pilecubesImg from "../../Assets/Images/PilecubesScreen.png";
import pilecubesStory from "../../Assets/Images/pilStory.png";
import visitPilecubes from "../../Assets/Images/visitCircle.png"
import windowDimensions from "../WindowDimension";

const PilecubesPage = (props) => {

    const { windowWidth } = windowDimensions();
    const [hover, setHover] = useState(false);

    return (
        <>
            <Grid container direction="row" marginTop={25}>
                <Grid item container md={1.4} xs={1} />
                <Grid item container md={10.6} xs={11}>

                    <Grid item md={6} lg={5} xs={11} position="relative" >
                        <img src={pilecubesImg} alt="PileCubes" width="105%" height="100%" />
                    </Grid>

                    <Grid item marginTop={{ xs: 5, md: 4 }} marginLeft={{ xs: 0, md: 5, lg: 10 }} xs={11} md={5}>
                        <Grid item >
                            <span className={classes["Pilecubes"]}>
                                Pilecubes
                            </span>
                        </Grid>

                        <Grid item md={8}>
                            <span className={classes["Industry-SaaS"]}>
                                Industry SaaS Inception
                            </span>
                        </Grid>

                        <Grid item paddingTop={4}>
                            <span className={classes["year"]}> Year </span>
                        </Grid>
                        <Grid item paddingTop={2} className={classes["date"]}>
                            2022
                        </Grid>

                        <Grid item paddingTop={4}>
                            <span className={classes["year"]}> Role </span>
                        </Grid>

                        <Grid item paddingTop={2} className={classes["role"]}>
                            <span>
                                Roadmap - Product Map <br></br>
                                Requirements Creation - UX/UI <br></br>
                                Technology Architecture - Product Launch
                            </span>
                        </Grid>


                        <Grid item marginTop={4}>
                            <a href="https://www.pilecubes.com/"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}>
                                    {/* <img src={hover ? visitPilecubes : pilecubesStory} onMouseOver={() => { setHover(true) }} onMouseOut={() => { setHover(false) }} alt="visit story" /> */}
                                    <img src={visitPilecubes} alt="visit story" />

                            </a>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>)
}

export default PilecubesPage;