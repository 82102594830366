import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react';
import ClientStoryId from "../Components/DPStories/ClientStories/ClientStoryId";
import classes from "../Assets/Styles/DPStories.module.css";
import Fade from '@mui/material/Fade';
import ClientStoriesNavbar from "../Components/DPStories/ClientStories/ClientStoriesNavbar";
import Footer from "../Components/DPStories/ClientStories/ClientStoriesFooter";
import { useNavigate } from "react-router-dom";
import windowDimensions from "../Components/WindowDimension";


const ClientStories = (props) => {

    let navigate = useNavigate();

    const { windowWidth, windowHeight } = windowDimensions();

    useEffect(() => {
        setMove(false);
        window.scroll(0, 0)
    }, [])

    const [openPhoneMenuModal, setOpenPhoneMenuModal] = useState(false)
    const barWidth = windowWidth > 800 ? 7 : 9.5;
    const [moveAni, setMove] = useState(false);
    const [moveAni2, setMove2] = useState(false);
    const [moveAni3, setMove3] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);
    const [moveWhiteBar, setMoveWhiteBar] = useState(false);
    const [moveOurStories, setMoveOurStories] = useState(false);
    const [moveOurStories2, setMoveOurStories2] = useState(false);
    const [moveOurStories3, setMoveOurStories3] = useState(false);

    useEffect(() => {
        setMoveWhiteBar(true);
        props.setHideNav(true);
    }, [])

    const handleClick = () => {
        setMove(true);
        setFadeIn(true);
        props.setMoveVerticalLines(false);
        setTimeout(() => {
            setMove2(true);
        }, 700);

        setTimeout(() => {
            setMove3(true)
        }, 1000)
        setTimeout(() => { navigate("/dp-stories") }, 1650)
    }

    const handleClick2 = () => {
        setMoveOurStories(true);
        setFadeIn(true);
        props.setMoveVerticalLines(false);
        setTimeout(() => {
            setMoveOurStories2(true);
        }, 700);

        setTimeout(() => {
            setMoveOurStories3(true)
        }, 1050)

        setTimeout(() => {
            navigate("/our-stories");
        }, 1500)

    }

    return (
        <Grid container>
            <Grid item container direction="row">
                {windowWidth > 900
                    ?
                    <>
                        {/* first blue bar */}
                        <Grid item
                            container
                            justifyContent="center"
                            position="fixed"
                            height={windowHeight}
                            sx={{ cursor: "pointer" }}
                            paddingTop={4}
                            paddingBottom={4}
                            onClick={() => { handleClick2() }}
                            bgcolor="#1F96C2"
                            zIndex="3"
                            className={moveOurStories2 ? classes["our-stories-motion"] : ""}
                            width={moveOurStories2 ? 100 - barWidth * 2 + "%" : barWidth + "%"}
                        >

                            <Grid item className={moveAni || moveOurStories ? classes["shrink-circle2"] : classes["grow-circle2"]} />
                            <Fade in={!fadeIn} timeout={1000}>
                                <Grid item container
                                    alignItems="center"
                                    className={classes["stories"]}>
                                    Our Stories
                                </Grid>
                            </Fade>
                        </Grid>

                        {/* Client Stories page */}
                        <Grid item
                            zIndex={1}
                            position="absolute"
                            left={barWidth + "%"}
                            width={100 - barWidth * 2 + "%"}
                        >
                            <Fade in={!fadeIn} timeout={600} zIndex={0}>
                                <Grid container >
                                    <ClientStoriesNavbar setOpenPhoneMenuModal={props.setOpenPhoneMenuModal} />
                                    <ClientStoryId setScrolled={props.setScrolled} scrolled={props.scrolled} />
                                    <Footer setHideNav={props.setHideNav} />
                                </Grid>
                            </Fade>
                        </Grid>

                        {/* right black bar */}
                        <Grid item
                            container
                            justifyContent="center"
                            right={0}
                            position="fixed"
                            bgcolor="black"
                            onClick={() => { handleClick() }}
                            paddingTop={4}
                            zIndex="2"
                            paddingBottom={4}
                            width={moveAni2 ? 100 - barWidth * 2 + "%" : barWidth + "%"}
                            height={windowHeight}
                            className={moveAni2 ? classes["client-transition3"] : classes["client-transition4"]}
                        >
                            <Grid item className={moveAni || moveOurStories ? classes["shrink-circle2"] : classes["grow-circle2"]} />

                            <Fade in={!moveAni} timeout={1000}>
                                <Grid item container
                                    alignItems="center"
                                    className={classes["stories"]}>
                                    Home
                                </Grid>
                            </Fade>
                        </Grid>

                        {/* white bar */}
                        <Grid item
                            container
                            bgcolor="white"
                            zIndex="-1"
                            width={moveAni ? "0%" : "100%"}
                            height={windowHeight}
                            className={moveAni ? classes["client-transition"] : classes["client-transition2"]}
                        />

                        {/* our stories white bar */}
                        <Grid item
                            container
                            bgcolor="white"
                            left={0}
                            width={moveOurStories3 ? (100 - barWidth * 2) + "%" : "0%"}
                            height={windowHeight}
                            className={moveOurStories3 ? classes["motion4"] : classes["motion5"]}
                        />

                        {/* hidden our stories red bar */}
                        <Grid item
                            container
                            left={barWidth + "%"}
                            bgcolor="#ee401b"
                            width={moveOurStories ? (100 - barWidth * 2) + "%" : "0%"}
                            height={windowHeight}
                            className={moveOurStories ? classes["client-stories-motion2"] : classes["motion"]}
                        />

                        {/* hidden red bar */}
                        <Grid item
                            container
                            bgcolor="#ee401b"
                            zIndex="-2"
                            position="fixed"
                            width={"100%"}
                            height={windowHeight}
                        />

                        {/* last white bar */}
                        <Grid item
                            container
                            bgcolor="white"
                            zIndex="3"
                            right="0"
                            width={moveAni3 ? 100 - barWidth * 2 + "%" : "0%"}
                            height={windowHeight}
                            className={moveAni3 ? classes["client-transition5"] : classes["client-transition6"]}
                        />
                    </>
                    :

                    <Grid item
                        width={"100%"}
                    >
                        <ClientStoriesNavbar setOpenPhoneMenuModal={props.setOpenPhoneMenuModal} />
                        <ClientStoryId moveAni={moveAni} setScrolled={props.setScrolled} scrolled={props.scrolled} />
                        <Footer setHideNav={props.setHideNav} />
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default ClientStories;