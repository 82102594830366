import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react';
import classes from "../../Assets/Styles/BottomPage.module.css";
import imgStyle1 from "../../Assets/Images/TriangleImg.png";
import imgStyle3 from "../../Assets/Images/ImgStyle2.png"
import imgStyle2 from "../../Assets/Images/ImgStyle3.png"
import windowDimensions from "../WindowDimension";
import useWindowDimensions from "../WindowDimension";


const BottomPage = (props) => {

    const {windowWidth} = windowDimensions();

    return (
        <>
            <Grid container direction={"row"} marginTop={20} marginBottom={25} >
                <Grid item container md={1.4} xs={1} />
                {windowWidth > 900 ?
                    <Grid item container direction="column" md={10.5} xs={11}>
                        <Grid item container justifyContent="center" alignItems="center">
                            <Grid item sm={4}>
                                <img src={imgStyle1} alt="design1" />

                            </Grid>

                            <Grid item sm={4}>
                                <img src={imgStyle2} width={windowWidth > 1100 ? windowWidth >= 1400 ? "33%" :  "45%" : "60%"} alt="design2" />
                            </Grid>

                            <Grid item sm={4}>
                                <img src={imgStyle3} alt="design3" />
                            </Grid>
                        </Grid>
                        <Grid item container marginTop={5}>
                            <Grid item className={classes["title"]} md={4}>
                                Architectural
                            </Grid>

                            <Grid item className={classes["title"]} md={4}>
                                Accessibility
                            </Grid>

                            <Grid item className={classes["title"]} md={4}>
                                Assistive <br></br>
                                Technologies

                            </Grid>
                        </Grid>

                        <Grid item container marginTop={5}>
                            <Grid item md={4}>
                                <Grid item md={9} xl={8} className={classes["text"]} >
                                    Design of specialist spaces and
                                    environments that consider the
                                    needs of those with accessibility
                                    needs.
                                </Grid>
                            </Grid>
                            <Grid item md={4}>
                                <Grid item md={9} className={classes["text"]} >
                                    Research, test, design and
                                    implantation for accessibility in the
                                    areas of UX/UI, web design,
                                    application design and usability testing.
                                </Grid>
                            </Grid>
                            <Grid item md={4}>
                                <Grid item md={9} lg={7} className={classes["text"]} >
                                    We research, design and implement
                                    products (both physical and digital)
                                    that reduce limitations and assist
                                    people in accessing the information
                                    and services they need.
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid item container direction="column" xs={11}>
                        <Grid item container direction="column" marginBottom={8} alignItems="center">
                            <Grid item >
                                <img src={imgStyle1} alt="design1" />
                            </Grid>

                            <Grid item marginTop={3} className={classes["title"]}>
                                Architectural
                            </Grid>

                            <Grid item width="80%" marginTop={2} className={classes["text"]} >
                                Design of specialist spaces and
                                environments that consider the
                                needs of those with accessibility
                                needs.
                            </Grid>

                        </Grid>

                        <Grid item container direction="column" marginBottom={8} alignItems="center">
                            <Grid item>
                                <img src={imgStyle2} alt="design2" />
                            </Grid>

                            <Grid item marginTop={3} className={classes["title"]}>
                                Accessibility
                            </Grid>

                            <Grid item width="80%" marginTop={2} className={classes["text"]} >
                                Research, test, design and
                                implantation for accessibility in the
                                areas of UX/UI, web design,
                                application design and usability testing.
                            </Grid>

                        </Grid>

                        <Grid item container direction="column" alignItems="center">
                            <Grid item>
                                <img src={imgStyle3} alt="design3" />
                            </Grid>

                            <Grid item marginTop={3} className={classes["title"]}>
                                Assistive <br></br>
                                Technologies
                            </Grid>

                            <Grid item width="80%" marginTop={2} className={classes["text"]} >
                                We research, design and implement
                                products (both physical and digital)
                                that reduce limitations and assist
                                people in accessing the information
                                and services they need.
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default BottomPage;