import MissionPage1 from "../Components/OurMission/MissionPage1";
import OurExperience from "../Components/OurMission/OurExperience";
import OurPhases from "../Components/OurMission/OurPhases";
import Grid from "@mui/material/Grid";
import { Helmet, HelmetProvider } from "react-helmet-async";


const OurMissionPage = (props) => {
    return (
        <Grid container zIndex="1">
            <HelmetProvider>
                <Helmet>
                    <title> {`Our Values`} </title>
                    <meta name="description" content="Through our 25 years of technological and methodological expertise, we want to deliver high achievements for our clients, who range from start-ups to multinational companies." />
                    <meta name="image" property="og:image" content="https://testimgg.s3.eu-west-1.amazonaws.com/DPLogo.png" />
                    <meta property="og:url" content="https://www.digitalprestige.com/" />
                    <meta
                        name="keywords"
                        content="leading digital transformation, misson, methodological expertise, high achievements, clients, services, phases, Create Roadmap, business needs, stakeholders, product vision, Product Requirements, document product features, product map, workflows, Architecture launch, gather product documentation, technology architecture, developing the software, scaled digital, Launch Planning, growth, scalability plan, required resources, milestones" />
                    <link rel="canonical" href={`/our-values`} />
                </Helmet>
            </HelmetProvider>
            <MissionPage1 setHeight={props.setHeight} />
            <OurExperience />
            <OurPhases />
        </Grid>
    )
}

export default OurMissionPage;