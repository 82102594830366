import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react';
import Home from "../Components/DPStories/OurStories/BTSById";
import Navbar from "../Components/DPStories/OurStories/Navbar"
import classes from "../Assets/Styles/DPStories.module.css";
import Fade from '@mui/material/Fade';
import Footer from "../Components/DPStories/ClientStories/ClientStoriesFooter";
import { useNavigate } from "react-router-dom";
import windowDimensions from "../Components/WindowDimension";

const BTSById = (props) => {

    let navigate = useNavigate();

    const { windowWidth, windowHeight } = windowDimensions();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const barWidth = windowWidth > 800 ? 7 : 9.5;
    const [moveAni, setMove] = useState(false);
    const [moveAni2, setMove2] = useState(false);
    const [moveAni3, setMove3] = useState(false);
    const [moveWhiteBar, setMoveWhiteBar] = useState(false);
    const [moveDPStories, setMoveDPStories] = useState(false);
    const [moveDPStories2, setMoveDPStories2] = useState(false);


    useEffect(() => {
        if (props.moveVerticalLines) {
            setMoveWhiteBar(true);
        }
        props.setHideNav(true);

    }, [props.moveVerticalLines])

    const handleClick = (e) => {
        setMove(true);
        props.setMoveVerticalLines(false);

        setTimeout(() => {
            setMove2(true);
        }, 700);

        setTimeout(() => {
            setMove3(true)
        }, 1000)

        setTimeout(() => {
            navigate("/client-stories");
        }, 1550)
    }

    const handleClick2 = (e) => {
        setMove(true);
        props.setMoveVerticalLines(false);
        setTimeout(() => {
            setMove2(true);
        }, 700);

        setTimeout(() => {
            setMoveDPStories(true)
        }, 1000)

        setTimeout(() => {
            setMoveDPStories2(true)
        }, 1400)

        setTimeout(() => {
            navigate("/dp-stories");
        }, 1750)
    }

    return (
        <Grid container>
            <Grid item container direction="row">
                {windowWidth > 900
                    ?
                    <>
                        {/* Our Stories page */}
                        <Grid item
                            zIndex={2}
                            position="absolute"
                            left={0}
                            width={100 - barWidth * 2 + "%"}
                        >
                            <Fade in={!moveAni} timeout={600} zIndex={0}>
                                <Grid container>
                                    <Navbar setHideNav={props.setHideNav}/>
                                    <Home />
                                    <Footer setHideNav={props.setHideNav} />
                                </Grid>
                            </Fade>
                        </Grid>

                        {/* static first blue bar */}
                        <Grid item
                            container
                            justifyContent="center"
                            position="fixed"
                            right={0}
                            sx={{ cursor: "pointer" }}
                            height={windowHeight}
                            paddingTop={4}
                            onClick={() => { handleClick2() }}
                            paddingBottom={4}
                            bgcolor="black"
                            zIndex="3"
                            className={moveDPStories ? classes["our-stories-motion"] : ""}
                            width={moveDPStories ? 100 - barWidth * 2 + "%" : barWidth + "%"}
                        >

                            <Grid item className={moveAni ? classes["shrink-circle3"] : classes["grow-circle"]} />
                            <Fade in={!moveAni} timeout={1000}>
                                <Grid item container
                                    alignItems="center"
                                    className={classes["stories"]}>
                                    Home
                                </Grid>
                            </Fade>
                        </Grid>

                        {/* Second red bar */}
                        <Grid item
                            container
                            justifyContent="center"
                            position="fixed"
                            right={barWidth + "%"}
                            bgcolor="#ee401b"
                            onClick={() => { handleClick() }}
                            paddingTop={4}
                            zIndex="2"
                            paddingBottom={4}
                            width={moveAni2 ? 100 - barWidth * 2 + "%" : barWidth + "%"}
                            height={windowHeight}
                            className={moveAni2 ? classes["motion3"] : classes["motion"]}
                        >
                            <Grid item className={moveAni ? classes["shrink-circle3"] : classes["grow-circle"]} />

                            <Fade in={!moveAni} timeout={1000}>
                                <Grid item container
                                    alignItems="center"
                                    className={classes["stories"]}>
                                    Client Stories
                                </Grid>
                            </Fade>
                        </Grid>


                        {/* first client stories white bar */}
                        <Grid item
                            container
                            bgcolor="white"
                            zIndex={-1}
                            width={moveAni ? "0%" : "100%"}
                            height={windowHeight}
                            className={moveAni ? classes["client-transition"] : classes["client-transition2"]}
                        />

                        {/* second client stories white bar */}
                        <Grid item
                            container
                            bgcolor="white"
                            zIndex="3"
                            right={barWidth + "%"}
                            width={moveAni3 ? 100 - barWidth * 2 + "%" : "0%"}
                            height={windowHeight}
                            className={moveAni3 ? classes["client-transition5"] : classes["client-transition6"]}
                        />

                        {/* home page white bar */}
                        <Grid item
                            container
                            bgcolor="white"
                            zIndex="4"
                            right={0}
                            width={moveDPStories2 ? 100 - barWidth * 2 + "%" : "0%"}
                            height={windowHeight}
                            className={moveDPStories2 ? classes["client-transition7"] : classes["client-transition8"]}
                        />

                        {/* hidden black bar */}
                        {/* <Grid item
                            container
                            left={barWidth * 2 + "%"}
                            bgcolor="#030303"
                            width={moveAni ? (100 - barWidth * 2) + "%" : "0%"}
                            height={height2}
                            className={moveAni ? classes["motion2"] : classes["motion"]}
                        /> */}

                        {/* hidden blue bar */}
                        <Grid item
                            container
                            bgcolor="#1F96C2"
                            zIndex={-2}
                            position="fixed"
                            width={"100%"}
                            height={windowHeight}
                        />


                        {props.moveVerticalLines &&
                            <>
                                {/* white bar over the first bar */}
                                <Grid item
                                    position="absolute"
                                    left={0}
                                    bgcolor="white"
                                    height={moveWhiteBar ? "0" : windowHeight}
                                    zIndex={3}
                                    width={barWidth + "%"}
                                    className={classes["white-bars"]}
                                />
                                {/* white bar over the second bar */}
                                <Grid item
                                    position="absolute"
                                    left={barWidth + "%"}
                                    bgcolor="white"
                                    height={moveWhiteBar ? "0" : windowHeight}
                                    zIndex={3}
                                    width={barWidth + "%"}
                                    className={classes["white-bars"]}
                                />
                            </>
                        }

                    </>
                    :
                    <>
                        <Grid item
                            width="100%"
                        >
                            <Navbar setHideNav={props.setHideNav} setOpenPhoneMenuModal={props.setOpenPhoneMenuModal} />
                            <Home />
                            <Footer setHideNav={props.setHideNav} />
                        </Grid>
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default BTSById;