import React, {useState} from "react";
import { useLottie } from "lottie-react";
import BodyAnimation3 from "../../Assets/Animations/BodyAnimaion3";
import windowDimensions from "../WindowDimension";


export default function LoadingAnimation() {
  const { windowWidth, windowHeight } = windowDimensions();

  const options = {
    animationData: BodyAnimation3,
    loop: true,
    autoplay: true,
  };

  const style={
      position:"absolute",
      padding: 0,
      right:0,
      height: windowWidth > 1000 ? "150%" : "100%",
      zIndex: "-1",
      
  }
  const { View } = useLottie(options, style);
  return (
    <>
      {View}
    </>
  );
}