import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react';
import classes from '../../Assets/Styles/PileCubesPage.module.css';
import ChapChap from "../../Assets/Images/ChapChapImg.png";
import StoryCircle from "../../Assets/Images/pilecubesStory.png";
import comingSoon from "../../Assets/Images/coming-sooon.png";
import windowDimensions from "../WindowDimension";


const ChapChapPage = (props) => {

    const [source, setSource] = useState(StoryCircle)
    const {windowWidth} = windowDimensions();

    return (
        <>
            <Grid container direction={"row"} marginTop={20}>
                <Grid item container md={1.4} xs={1} />
                {windowWidth > 900 ?
                    <Grid item container md={10.5} xs={11}>

                        <Grid item md={5} lg={5} xs={11}>
                            <Grid item>
                                <span className={classes["Pilecubes"]}>
                                    Chapchap
                                </span>
                            </Grid>

                            <Grid item md={8} xs={11}>
                                <span className={classes["Industry-SaaS"]}>
                                    Regional Online Marketplace
                                </span>
                            </Grid>

                            <Grid item paddingTop={4}>
                            <span className={classes["year"]}> Year </span>
                            </Grid>
                            <Grid item paddingTop={2} className={classes["date"]}>
                                2022
                            </Grid>

                            <Grid item paddingTop={4}>
                                <span className={classes["year"]}> Role </span>
                            </Grid>

                            <Grid item paddingTop={2} className={classes["role"]}>
                                <span>
                                    Roadmap - Product Map <br></br>
                                    Requirements Creation - UX/UI <br></br>
                                    Product Launch Web and App
                                </span>
                            </Grid>

                        </Grid>

                        <Grid item md={5.5} lg={5} position="relative">
                            <Grid item onMouseOut={() => { setSource(StoryCircle) }} onMouseOver={() => { setSource(comingSoon) }}>
                                <img src={source} width="20%" alt="story-design" className={classes["img-poistion"]} style={{ position: "absolute", right: 30, bottom: -30 }} />
                            </Grid>
                            <img src={ChapChap} alt="ChapChap" width="100%" height="100%" />
                        </Grid>
                    </Grid>
                    :
                    <Grid item container md={10.5} xs={11}>

                        <Grid item md={5} xs={11} position="relative">
                            <Grid item onMouseOut={() => { setSource(StoryCircle) }} onMouseOver={() => { setSource(comingSoon) }}>
                            <img src={source} width="20%" alt="story-design" className={classes["img-poistion"]} style={{ position: "absolute", right: 30, bottom: -30 }} />
                            </Grid>
                            <img src={ChapChap} alt="ChapChap" width="100%" height="100%" />
                        </Grid>

                        <Grid item md={5} marginTop={5} xs={11}>
                            <Grid item>
                                <span className={classes["Pilecubes"]}>
                                    Chapchap
                                </span>
                            </Grid>

                            <Grid item md={8} xs={11}>
                                <span className={classes["Industry-SaaS"]}>
                                    Regional Online Marketplace
                                </span>
                            </Grid>

                            <Grid item paddingTop={4}>
                                <span className={classes["year"]}> Year </span>
                            </Grid>
                            <Grid item paddingTop={2} className={classes["date"]}>
                                2022
                            </Grid>

                            <Grid item paddingTop={4}>
                                <span className={classes["year"]}> Role </span>
                            </Grid>

                            <Grid item paddingTop={2} className={classes["role"]}>
                                <span>
                                    Roadmap - Product Map <br></br>
                                    Requirements Creation - UX/UI <br></br>
                                    Product Launch Web and App
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default ChapChapPage;