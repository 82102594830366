import Grid from "@mui/material/Grid";
import React, { useState, useEffect, useRef } from 'react';
import classes from "../../Assets/Styles/MissionPage1.module.css";
import OurMissionAnimation from "./Page1Animation";
import phoneImage2 from "../../Assets/Images/bgImage2.png"
import Fade from '@mui/material/Fade';
import windowDimensions from "../WindowDimension";


const MissionPage1 = (props) => {

    const ref = useRef(null)

    useEffect(() => {
        props.setHeight(ref.current.clientHeight);
    }, [])

    const { windowWidth, windowHeight } = windowDimensions();

    const style = {
        backgroundImage: `url(${phoneImage2})`,
        backgroundRepeat: "no-Repeat",
        backgroundSize: "cover",
    };
    const style2 = {
        zIndex: "1",
    }


    return (<>
        <Grid ref={ref} style={windowWidth > 1100 ? style2 : style} container direction="row" height={{ xs: "80vh", md: "90vh" }}>


            {windowWidth > 1100 ?
                <>
                    <Grid item className={classes["overlay2"]} />
                    <OurMissionAnimation />
                </>
                :
                <Grid item className={classes["overlay"]} />
            }
            <Grid item container md={1.5} xs={1} />
            <Grid item container className={classes["container"]} xs={11} md={10.5} >
                <Grid item container paddingBottom={5}>
                    <span className={classes["Launch-your-next-tech-innovation"]} >
                        Technology and methodology <br></br> are at the heart
                        of everything <br></br> we do
                    </span>
                </Grid>
            </Grid>

        </Grid>
    </>)
}

export default MissionPage1;