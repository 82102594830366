import Grid from "@mui/material/Grid";
import classes from "../../../Assets/Styles/ClientStoriesId.module.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import openingPhoto from "../../../Assets/Images/openingPhoto.png";
import redDesign from "../../../Assets/Images/redDesign.png";
import redDesign2 from "../../../Assets/Images/redDesign22.png";
import blackNextArrow from "../../../Assets/Images/blackNextArrow.png";
import goldNextArrow from "../../../Assets/Images/goldNextArrow.png";
import CircularProgress from "@mui/material/CircularProgress";
import goBack from "../../../Assets/Images/goBack.png";
import windowDimensions from "../../WindowDimension";

import * as clientServices from '../../../Services/client-story-services.proxy';

const clientStoriesList = clientServices.clientStoriesList;
const getClientStoryById = clientServices.getClientStoryById;
const nextClientStory = clientServices.nextClientStory;

const ClientStoryId = (props) => {

    let navigate = useNavigate();
    let { storyId } = useParams();

    const goBackHandler = () => {
        navigate(-1);
        props.setScrolled(true)
    }

    const [loadingAni, setLoadingAni] = useState(false);
    const [nextImageSrc, setNextImageSrc] = useState(blackNextArrow);
    const [pageNumber, setPageNumber] = useState(1);
    const [clientStories, setClientStories] = useState({});
    const [clientStory, setClientStory] = useState({});
    const [pageSize, setPageSize] = useState(1);
    const [nextClientStoryId, setNextClientStoryId] = useState("");
    const { windowWidth, windowHeight } = windowDimensions();

    useEffect(() => {
        let isApiSubscribed = true;
        window.scrollTo({ top: 0 });
        setLoadingAni(true)
        setNextImageSrc(blackNextArrow);
        clientStoriesList().then((response) => {
            if (!response.error && isApiSubscribed) {
                setClientStories(response)
            }
        })

        getClientStoryById(storyId).then((response) => {
            if (!response.error && isApiSubscribed) {
                setClientStory(response)
                setLoadingAni(false);
            }
        })

        nextClientStory(storyId).then((response) => {
            if (!response.error) {
                setNextClientStoryId(response)
            }
        })
        return () => {
            isApiSubscribed = false;
        };

    }, [pageNumber, storyId])



    return (
        <>
            <Grid container overflow="hidden" position="relative">
                {loadingAni ?
                    <Grid item container paddingBottom={15} justifyContent="center" alignItems="center" height={windowHeight}>
                        <CircularProgress size={40} sx={{ color: "#c8a047" }} />
                    </Grid>
                    :
                    <>
                        <img src={redDesign2} alt="red design" style={{ zIndex: "-1", position: "absolute", width: windowWidth < 700 ? "90%" : "60%", zIndex: "-1", bottom: 0, right: 0 }} />

                        <Grid item container direction="column">
                            <Grid item paddingLeft={{ xs: 6, md: 4 }} paddingTop={{ xs: 2, md: 0 }} paddingBottom={{ sm: 3, md: 0 }} onClick={() => goBackHandler()}>
                                <img src={goBack} width={windowWidth > 900 ? "auto" : "8%"} alt="go back" style={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item container justifyContent="center" paddingTop={2} position="relative">
                                <img src={redDesign} width="85%" className={classes["red-design"]} alt="red design" />
                                <Grid item xs={8}>
                                    <img width="100%" height="90%" src={openingPhoto} alt="opening image" />
                                </Grid>


                                <Grid item container md={10} xs={10} alignContent="center" justifyContent="center" paddingTop={20} className={classes["story-title"]}>
                                    <span style={{ overflowWrap: "break-word" }}> {clientStory?.Title} </span>
                                </Grid>

                                <Grid item container paddingTop={{ md: 20, xs: 10 }} xs={9.5} md={8.5} gap={{ md: 20, xs: 13 }}>
                                    <Grid item container>
                                        <Grid item xs={12} md={3.5} lg={3} alignSelf="flex-start" className={classes["Idea"]}>
                                            IDEA
                                        </Grid>
                                        <Grid item paddingTop={{ xs: 2, md: 0 }} md={8.5} lg={9} xs={12} className={classes["Idea-text"]}>
                                            <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: clientStory.Idea }} />
                                        </Grid>
                                    </Grid>

                                </Grid>

                                {clientStory.IdeaPhoto ?
                                    <Grid item container marginTop={{ md: 20, xs: 13 }} xs={12} md={8.5} height={{ xs: "310px", sm: "480px", md: "450px", lg: "480px", xl: "570px" }} bgcolor="#fb9c87">
                                        <Grid item md={5} xs={3} />
                                        <Grid item container justifyContent="center" alignItems="center" md={6} xs={8} >
                                            <Grid item xs={12} height={{ xs: "220px", sm: "350px", md: "320px", lg: "350px", xl: "390px" }} className={classes["Rectangle-948"]}>
                                                <img src={clientStory.IdeaPhoto && clientStory.IdeaPhoto.URL} alt="cover image" width="100%" height="100%" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    <></>
                                }
                                <Grid item container paddingTop={{ md: 20, xs: 10 }} xs={9.5} md={8.5} gap={{ md: 20, xs: 13 }}>
                                    <Grid item container>
                                        <Grid item xs={12} alignSelf="flex-start" className={classes["Idea"]}>
                                            SOLUTION
                                        </Grid>
                                        <Grid item xs={12} paddingTop={{ xs: 2, md: 0 }} className={classes["Idea-text"]}>
                                            <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: clientStory.Solution }} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container>
                                        <Grid item xs={12} alignSelf="flex-start" className={classes["Idea"]}>
                                            DESCRIPTION
                                        </Grid>
                                        <Grid item xs={12} paddingTop={{ xs: 2, md: 0 }} className={classes["Idea-text"]}>
                                            <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: clientStory.Description }} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item container xs={12} justifyContent="center" alignItems="center" marginTop={{ md: 20, xs: 15 }} height={{ sm: "600px", md: "600px", xs: "310px", lg: "650px", xl: "700px" }} bgcolor="#fb9c87">
                                    <Grid item xs={9} height={{ xs: "190px", sm: "470px", md: "470px", lg: "520px", xl: "620px" }} className={classes["img-contain"]}>
                                        <img src={clientStory.DescriptionPhoto && clientStory.DescriptionPhoto.URL} alt="cover image" width="100%" height="100%" />
                                    </Grid>
                                </Grid>

                                <Grid item container paddingBottom={{ md: 18, xs: 13 }} paddingTop={{ md: 20, xs: 15 }} xs={9.5} md={8.5}>
                                    <Grid item xs={12} md={3.5} lg={3} alignSelf="flex-start" className={classes["Idea"]}>
                                        DESIGN
                                    </Grid>
                                    <Grid item xs={12} md={8.5} lg={9} paddingTop={{ xs: 2, md: 0 }} className={classes["Idea-text"]}>
                                        <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: clientStory.Design }} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {clientStories?.Total !== 1 &&
                                <Grid item container paddingBottom={{ xs: 3, sm: 5 }} paddingRight={{ xs: 3, sm: 10 }} justifyContent="flex-end">
                                    <Grid item xs={6} lg={4}>
                                        <Link to={`/client-stories/${nextClientStoryId}`}>
                                            <img src={nextImageSrc}
                                                width="100%"
                                                onMouseOver={(e) => { e.target.style.cursor = "pointer"; setNextImageSrc(goldNextArrow) }}
                                                onMouseOut={() => { setNextImageSrc(blackNextArrow) }}
                                                alt="next story"
                                            />
                                        </Link>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </>
                }
            </Grid>
        </>
    )
}

export default ClientStoryId;