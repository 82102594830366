import axios from 'axios';
import * as constFile from '../dpConst';

const SEND_INQUIRY_EMAIL = constFile.SEND_INQUIRY_EMAIL;
export const sendInquiryEmail = (data) => {
    return axios
        .post(`${process.env.REACT_APP_URL + SEND_INQUIRY_EMAIL}`, data
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}