import Grid from "@mui/material/Grid";
import React, { useState, useEffect, useRef } from 'react';
import classes from "../Assets/Styles/Industries.module.css";
import industriesBuildingImg from "../Assets/Images/industriesBuildingImg.png";
import industriesBackground from "../Assets/Images/industriesBackground.png";
import Fade from '@mui/material/Fade';
import windowDimensions from '../Components/WindowDimension';

const style = {
    position: "absolute",
    top: 0,
    backgroundColor: "black",
    zIndex: 9
}


const Industries = (props) => {

    const { windowWidth, windowHeight } = windowDimensions();
    const [extraBuildingImgHeight, setExtraImgBuildingHeight] = useState(100);
    const [gridWidth, setGridWidth] = useState(0);
    const [gridHeight, setGridHeight] = useState(0)

    let ref = useRef()

    useEffect(() => {
        setTransition(true);
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
        setGridWidth(ref?.current?.clientWidth);
        setGridHeight(ref?.current?.clientHeight)
    }, [windowWidth, windowHeight])

    const style2 = {
        position: "absolute",
        top: props.navHeight,
        zIndex: 10,
        width: "100%",
        height: windowHeight > 1100 ? windowHeight : windowWidth < 580 ? "80vh" : windowHeight + extraBuildingImgHeight
    }

    const [transition, setTransition] = useState(false);

    return (
        <>
            <Grid container bgcolor="black">
                <Grid item container style={{ overflowX: "hidden" }} height={{ xs: "80vh", md: "90vh" }}>
                    <Grid item className={classes["overlay"]} />
                    <Fade in={transition} timeout={1150}>
                        <img src={industriesBackground} style={style} width="100%" height="100%" alt="front services image" />
                    </Fade>
                    <Fade in={transition} timeout={1150}>
                        <img src={industriesBuildingImg} style={style2} width={windowWidth < 700 ? "auto" : "78%"} height={windowWidth < 700 ? "100%" : "78%"} alt="front services image" />
                    </Fade>
                </Grid>

                <Grid item container xs={12} paddingTop={extraBuildingImgHeight + 80 + "px"}>
                    <Grid item container xs={0.9} md={0.9} lg={1.4} />
                    <Grid item container xs={10.2} md={10.5} lg={9.1} xl={9}>
                        <Grid item xs={12} className={classes["industries-text"]}>
                            Our rich team structure and Agile methodology help us develop solutions for various
                            industries, turning prospects into success stories. Business Analysts and a highly trained
                            team of Designers and Engineers will perform deep business analysis to deliver a top
                            product giving you an edge over your competitors. Automate your industry.
                        </Grid>

                        <Grid item container position="relative" xs={12} justifyContent="center" alignItems="center" padding="2% 2% 2% 2%" ref={ref} marginTop={18} marginBottom={20} className={classes["Rectangle-591"]}>
                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Capital Markets</span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={{ xs: 2, md: 2 }}>
                                <span> Automative </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2} >
                                <span> Logistics </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2} >
                                <span> Food & Beverages </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Insurance </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={{ xs: 2, md: 2 }}>
                                <span> Agribusiness </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Health Care </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Travel & Hospitality </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Real Estate </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Banking </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Ministries </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Beauty & Personal Care </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Construction </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Public Service </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Law Firm </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Software & Platform </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Manufacturing </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Higher Education </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Telecom </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Retail & Distribution </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Utilities </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> High Tech </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Communication & Media </span>
                            </Grid>

                            <Grid item container justifyContent="center" sm={6} md={3} paddingBottom={2}>
                                <span> Consumer Goods & Services </span>
                            </Grid>

                            <Grid item container width={gridWidth} height={gridHeight} className={classes["design-grid"]} />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>)
}

export default Industries;