import React, { useState, Children } from "react";
import styled from "@emotion/styled";
import Card from "./Card";

import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom';

// basic default styles for container
const Frame = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Stack = ({ onVote, Id, arrayLength, setPictureBriefNum, pictureBriefNum, children, ...props }) => {

  const navigate = useNavigate()


  const [stack, setStack] = useState(Children.toArray(children));
  const [stack2, setStack2] = useState(Children.toArray(children));
  const [moveAni, setMoveAni] = useState(false);
  const [moveAwayBy, setMoveAwayBy] = useState(2000);
  const [constrained, setConstrained] = useState(true);

  // return new array with last item removed
  const pop = (array) => {
    return array.filter((_, index) => {
      return index < array.length - 1;
    });
  };

  const handleVote = (item, vote) => {
    // update the stack

    let newStack = pop(stack2);
    setMoveAwayBy(moveAwayBy + 800);
    setStack2(newStack);
    setConstrained(true)

    if (newStack.length === 0) {
      setTimeout(() => {
        setPictureBriefNum(arrayLength)
        setMoveAni(!moveAni);
        setStack2(Children.toArray(children));
        setMoveAwayBy(2000);
        setConstrained(true)
      }, 800)
    }
    else {
      setPictureBriefNum(pictureBriefNum - 1)
    }
    // run function from onVote prop, passing the current item and value of vote
    onVote(item, vote);
  };

  return (
    <Grid paddingTop={{ md: 5, xs: 17 }} onDoubleClick={() => { Id.IsClientStory ? navigate(`/client-stories/${Id.Id}`) : Id.CategoryId === 1 ? navigate(`/Insight?Id=${Id.Id}`) : navigate(`/BTS?Id=${Id.Id}`) }}>
      <Frame {...props}>
        {stack.map((item, index) => {
          return (
            <Card
              drag={true} // Only top card is draggable
              key={item.key || index}
              moveAni={moveAni}
              onVote={(result) => handleVote(item, result)}
              moveAwayBy={moveAwayBy}
              constrained={constrained}
              setConstrained={setConstrained}
            >
              {item}
            </Card>
          );
        })}
      </Frame>
    </Grid>
  );
};

export default Stack;