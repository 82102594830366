import axios from 'axios';
import * as constFile from '../dpConst';

const CLIENT_STORIES_LIST = constFile.CLIENT_STORIES_LIST;
export const clientStoriesList = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + CLIENT_STORIES_LIST}`)
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}



const CLIENT_STORIES_TITLES_LIST = constFile.CLIENT_STORIES_TITLES_LIST;
export const clientStoriesTitlesList = (data) => {
    return axios
        .post(`${process.env.REACT_APP_URL + CLIENT_STORIES_TITLES_LIST}`, data
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}

const CLIENT_STORY_BY_ID = constFile.CLIENT_STORY_BY_ID;
export const getClientStoryById = (id) => {
    return axios
        .get(`${process.env.REACT_APP_URL + CLIENT_STORY_BY_ID}`,

            {
                params: {
                    Id: id
                },
            },
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}

const NEXT_CLIENT_STORY = constFile.NEXT_CLIENT_STORY;
export const nextClientStory = (id) => {
    return axios
        .get(`${process.env.REACT_APP_URL + NEXT_CLIENT_STORY}`,
            {
                params: {
                    Id: id
                },
            },
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}

const DISPLAY_RANDOM_STORIES = constFile.DISPLAY_RANDOM_STORIES;
export const displayRandomStories = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + DISPLAY_RANDOM_STORIES}`,
            {},
        )
        .then(res => { return res.data })
        .catch((err) => { return err.response.data; });
}
