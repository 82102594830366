import React, { useState, useEffect, useRef } from 'react';
import classes from '../Assets/Styles/navbar.module.css'
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Link, useLocation } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Industries from './NavbarButtons/Industries';
import Services from './NavbarButtons/Services';
import windowDimensions from "../Components/WindowDimension";


const Navbar = (props) => {
    const location = useLocation();
    const { windowWidth } = windowDimensions();

    const [cssStyle, setCssStyle] = useState("nav-button");
    const [cssStyle2, setCssStyle2] = useState("nav-button");
    const [cssStyle3, setCssStyle3] = useState("nav-button");
    const [servicesButtonCss, setServicesButtonCss] = useState("nav-button");
    const [industriesButtonCss, setIndustriesButtonCss] = useState("nav-button");
    const [navBackGroundColor, setNavBackGroundColor] = useState("transparent");
    const [cssTermsStyle, setTermsStyle] = useState(false);

    useEffect(() => {
        if (location.pathname.toString() === "/") {

            setNavBackGroundColor("transparent")
            setCssStyle("bold-nav-button");
            setCssStyle2("nav-button");
            setCssStyle3("nav-button");
            setServicesButtonCss("nav-button");
            setIndustriesButtonCss("nav-button");
            setTermsStyle(false);
            if (props.scrollPosition > props.height) {
                setNavBackGroundColor("white")
            }
            else if (props.scrollPosition !== 0) {
                setNavBackGroundColor("black")
            }
        }
        else if (location.pathname.toString() === "/our-values") {

            setNavBackGroundColor("black")
            setCssStyle("nav-button");
            setCssStyle2("bold-nav-button");
            setCssStyle3("nav-button");
            setServicesButtonCss("nav-button");
            setIndustriesButtonCss("nav-button");
            setTermsStyle(false);
            if (props.scrollPosition > props.height) {
                setNavBackGroundColor("white")
            }
            else if (props.scrollPosition !== 0) {
                setNavBackGroundColor("black")
            }
        }
        else if (location.pathname.toString() === "/case-studies") {

            setCssStyle("nav-button");
            setNavBackGroundColor("transparent");
            setCssStyle2("nav-button");
            setCssStyle3("bold-nav-button");
            setServicesButtonCss("nav-button");
            setIndustriesButtonCss("nav-button");
            setTermsStyle(false);
            if (props.scrollPosition > props.height) {
                setNavBackGroundColor("white")
            }
            else if (props.scrollPosition !== 0) {
                setNavBackGroundColor("black")
            }
        }
        else if (location.pathname.toString() === "/privacy-policy-and-terms") {
            setCssStyle("nav-button");
            setCssStyle2("nav-button");
            setCssStyle3("nav-button");
            setServicesButtonCss("nav-button");
            setIndustriesButtonCss("nav-button");
            setNavBackGroundColor("white");
            setTermsStyle(true);
        }

        else if (location.pathname.toString() === "/services") {
            setCssStyle("nav-button");
            setCssStyle2("nav-button");
            setCssStyle3("nav-button");
            setServicesButtonCss("bold-nav-button");
            setIndustriesButtonCss("nav-button");
            setNavBackGroundColor("transparent");
            setTermsStyle(false);
            if (props.scrollPosition > props.height) {
                setNavBackGroundColor("white")
            }
            else if (props.scrollPosition !== 0) {
                setNavBackGroundColor("black")
            }
        }

        else if (location.pathname.toString() === "/industries") {
            setCssStyle("nav-button");
            setCssStyle2("nav-button");
            setCssStyle3("nav-button");
            setServicesButtonCss("nav-button");
            setIndustriesButtonCss("bold-nav-button");
            setNavBackGroundColor("transparent");
            setTermsStyle(false);
            if (props.scrollPosition !== 0) {
                setNavBackGroundColor("black")
            }
        }

        else if (location.pathname.toString() === "/a-message-from-the-founder") {
            setCssStyle("nav-button");
            setCssStyle2("nav-button");
            setCssStyle3("nav-button");
            setServicesButtonCss("nav-button");
            setIndustriesButtonCss("nav-button");
            setNavBackGroundColor("rgba(209, 181, 117)");
            setTermsStyle(false);
        }
    });

    const [openIndustries, setOpenIndustries] = useState(false);
    const [openServices, setOpenServices] = useState(false);

    const ref = useRef(null)
    const [navHeight, setNavHeight] = useState(0);

    useEffect(() => {
        setNavHeight(ref.current.clientHeight);
        props.setNavHeight(ref.current.clientHeight)
    }, [navHeight, windowWidth])

    const style = {
        boxShadow: "0px 0px",
        backgroundColor: openIndustries && props.scrollPosition < navHeight ? "black" : navBackGroundColor,
        transition: "all .5s ease",
        WebkitTransition: "all .5s ease",
        MozTransition: "all .5s ease",
        zIndex: 11
    }

    return (<>

        <AppBar ref={ref} position="sticky" style={style}>
            <Container
                maxWidth="100%"

            >
                <Toolbar disableGutters >
                    {windowWidth > 1199 ?
                        <Grid container paddingTop={4} paddingBottom={2.5}>
                            <Grid item container direction="row" md={3.4}  >
                                <Grid item container direction="column" paddingLeft={3.5} alignItems="center">
                                    <Link to="/" className={classes["dp-title"]} style={{ color: window.location.pathname.toString() !== "/a-message-from-the-founder" ? window.location.pathname.toString() === "/industries" ? "white" : (props.scrollPosition > props.height) || cssTermsStyle ? "black" : "white" : "white" }}>
                                        <Grid item onClick={() => { window.scrollTo(0, 0) }}
                                        >
                                            <Grid item container justifyContent="center">
                                                DIGITAL
                                            </Grid>
                                            <Grid item>
                                                PRESTIGE
                                            </Grid>
                                        </Grid>
                                    </Link>

                                </Grid>
                            </Grid>

                            <Grid
                                item
                                container
                                md={7.6}
                                alignItems={"center"}
                                gap={1}
                                className={classes["nav-menu"]}
                            >
                                <Grid item md={1.9} lg={1.55} xl={1.8} container justifyContent="left" className={openServices || window.location.pathname.toString() === "/services" ? classes["bold-nav-button"] : classes["nav-button"]}
                                    style={{ color: window.location.pathname.toString() !== "/a-message-from-the-founder" ? window.location.pathname.toString() === "/industries" ? "white" : (props.scrollPosition > props.height) || cssTermsStyle ? "black" : "white" : "white" }}
                                    onClick={() => {
                                        setOpenIndustries(false);
                                        setOpenServices(!openServices);
                                    }}
                                >
                                    SERVICES
                                </Grid>

                                <Grid item md={1.9} lg={1.8} xl={1.8} container justifyContent="left" className={openIndustries || window.location.pathname.toString() === "/industries" ? classes["bold-nav-button"] : classes["nav-button"]}
                                    style={{ color: window.location.pathname.toString() !== "/a-message-from-the-founder" ? window.location.pathname.toString() === "/industries" ? "white" : (props.scrollPosition > props.height) || cssTermsStyle ? "black" : "white" : "white" }}
                                    onClick={() => {
                                        setOpenIndustries(!openIndustries);
                                        setOpenServices(false);
                                    }}
                                >
                                    INDUSTRIES
                                </Grid>


                                <Grid item md={1.9} lg={1.9} xl={1.8} container justifyContent="left"

                                    onClick={() => {
                                        setTimeout(() => { props.setMyModalBoldButton(false) }, 100); window.scrollTo(0, 0)
                                    }}
                                >
                                    <Link to="/our-values" className={classes[cssStyle2]}
                                        style={{ color: window.location.pathname.toString() !== "/a-message-from-the-founder" ? window.location.pathname.toString() === "/industries" ? "white" : (props.scrollPosition > props.height) || cssTermsStyle ? "black" : "white" : "white" }}
                                    >
                                        <span> OUR VALUES </span>
                                    </Link>

                                </Grid>
                                <Grid item md={1.9} lg={2} xl={1.8} container justifyContent="left"

                                    onClick={() => {
                                        setTimeout(() => { props.setMyModalBoldButton(false) }, 100); window.scrollTo(0, 0);
                                    }}
                                >
                                    <Link to="/case-studies"
                                        style={{ color: window.location.pathname.toString() !== "/a-message-from-the-founder" ? window.location.pathname.toString() === "/industries" ? "white" : (props.scrollPosition > props.height) || cssTermsStyle ? "black" : "white" : "white" }}
                                        className={classes[cssStyle3]}>
                                        <span> OUR CLIENTS </span>
                                    </Link>
                                </Grid>

                                <Grid item md={4} lg={3.5} xl={3} container justifyContent="left"
                                    onClick={() => { props.setMyModalBoldButton(false); window.scrollTo(0, 0) }}
                                >
                                    <Link to="/"
                                        style={{ color: window.location.pathname.toString() !== "/a-message-from-the-founder" ? window.location.pathname.toString() === "/industries" ? "white" : (props.scrollPosition > props.height) || cssTermsStyle ? "black" : "white" : "white" }}
                                        className={classes[props.myModalBoldButton ? "nav-button" : cssStyle]}>
                                        <span> ABOUT DIGITAL PRESTIGE </span>
                                    </Link>

                                </Grid>
                            </Grid>
                        </Grid>

                        :

                        <Grid container paddingTop={4} paddingBottom={2.5}>
                            <Grid item container xs={0.6} />

                            <Grid item container xs={11.4} justifyContent="flex-start" alignItems="center">
                                <Link to="/" className={window.location.pathname.toString() !== "/a-message-from-the-founder" ? window.location.pathname.toString() === "/industries" ? classes['dp-title'] : props.scrollPosition > props.height ? classes['black-dp-title'] : classes['dp-title'] : classes['dp-title']}
                                    style={{ color: window.location.pathname.toString() !== "/a-message-from-the-founder" ? window.location.pathname.toString() === "/industries" ? "white" : (props.scrollPosition > props.height) || cssTermsStyle ? "black" : "white" : "white" }}
                                >
                                    <Grid item>
                                        <Grid item container justifyContent="center">
                                            DIGITAL
                                        </Grid>
                                        <Grid item>
                                            PRESTIGE
                                        </Grid>
                                    </Grid>
                                </Link>
                                <ExpandMoreIcon sx={{ color: window.location.pathname.toString() !== "/a-message-from-the-founder" ? window.location.pathname.toString() === "/industries" ? "white" : (props.scrollPosition > props.height) || cssTermsStyle ? "black" : "white" : "white" }}
                                    cursor="pointer" onClick={() => { props.setOpenModal(true) }} className={classes["material"]} fontSize='large' />
                            </Grid>
                        </Grid>
                    }
                </Toolbar>
                <Industries open={openIndustries} scrollPosition={props.scrollPosition} height={props.height} setOpenIndustries={setOpenIndustries} navHeight={navHeight} />
                <Services open={openServices} scrollPosition={props.scrollPosition} height={props.height} setOpenServices={setOpenServices} navHeight={navHeight} />
            </Container>
        </AppBar>
    </>)
}

export default Navbar;