import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import contactUsIcon from "../Assets/Images/contactUsIcon.png";
import classes from '../Assets/Styles/ContactUs.module.css'
import { useNavigate } from "react-router-dom";

const StickyContactUs = (props) => {

    let navigate = useNavigate();

    const handleClick = () => {
        navigate("/")
        setTimeout(() => {
            props.setScrollToContactUsForm(true)
        }, 200)
    }

    return (<>
        <Grid container>
            <Grid item container justifyContent="flex-end" xs={12}>
                <Grid item position="relative" onClick={handleClick}>
                    <img style={{ cursor: "pointer" }} src={contactUsIcon} width="140px" height="80px" />
                    <Grid item className={classes["project-box"]}>
                        <span className={classes["A-project-Contact-Us"]}>
                            A project? <br></br>
                            <span className={classes["text-style-2"]}>Contact Us</span>
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>)
}

export default StickyContactUs;