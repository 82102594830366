import Grid from "@mui/material/Grid";
import Page1 from "../Components/CaseStudies/Page1";
import PileCubesPage from "../Components/CaseStudies/PilecubesPage";
import ChapChapPage from "../Components/CaseStudies/ChapChapPage";
import BlueringPage from "../Components/CaseStudies/BlueringPage";
import EnotecaPage from "../Components/CaseStudies/EnotecaPage";
import LereumPage from "../Components/CaseStudies/LereumPage";
import BottomPage from "../Components/CaseStudies/BottomPage";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CaseStudies = (props) => {
    return (
        <Grid container zIndex="1">
            <HelmetProvider>
                <Helmet>
                    <title> {`Case Studies`} </title>
                    <meta name="description" content="A selection of products in various stages of ongoing development." />
                    <meta name="image" property="og:image" content="https://testimgg.s3.eu-west-1.amazonaws.com/DPLogo.png" />
                    <meta property="og:url" content="https://www.digitalprestige.com/" />
                    <meta
                        name="keywords"
                        content="Client, Pilecubes, Industry SaaS, Chapchap, Online Marketplace, Bluering, Legacy Platform, Enoteca, Retail Digital Transformation, Lereum, Crypto Trading Platform, Roadmap Product Map, Requirements Creation, Technology Architecture, Product Launch, Team Agile Training, Research, testing, design, implantation, enterprise resource planning, e-commerce, maintenance, support, blockchain, Product Launch Web and App, Business Process Automation, Business Process Management, case management software, cloud, decision making, digital ecosystem, customer, qr code, digital innovation, Digital Process Automation, machine learning, artificial intelligence, UX/UI" />
                    <link rel="canonical" href={`/case-studies`} />
                </Helmet>
            </HelmetProvider>

            <Page1 setHeight={props.setHeight} />
            <PileCubesPage />
            <ChapChapPage />
            <LereumPage />
            <EnotecaPage />
            <BlueringPage />
            <BottomPage />
        </Grid>
    )
}

export default CaseStudies;
