import Grid from "@mui/material/Grid";
import classes from "../../../Assets/Styles/Home.module.css";
import Stack from "./Motion/Stack"
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import goldDesign2 from "../../../Assets/Images/goldDesign2.png";
import goldDesign from "../../../Assets/Images/goldDesign.png";
import { useNavigate } from "react-router-dom";
import windowDimensions from "../../WindowDimension";

import * as clientServices from '../../../Services/client-story-services.proxy';

const Divv = styled.div`
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100%;
    `;

const displayRandomStories = clientServices.displayRandomStories;

const RecentStories = (props) => {
    const navigate = useNavigate();

    const { windowWidth, windowHeight } = windowDimensions();
    const [randomStories, setRandomStories] = useState();
    const [pictureBriefNum, setPictureBriefNum] = useState();
    const [arrayLength, setArrayLength] = useState(0);

    const Item = styled.div`
    width: ${windowWidth > 700 ? "400px" : "300px"};
    height: ${windowWidth > 700 ? "270px" : "200px"};
    cursor:grab;
    align-items: center;
    background-size:cover;
    justify-content: center;
    text-shadow: 0 10px 10px #d1d5db;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    transform: ${() => {
            let rotation = Math.random() * (12) - 7;
            return `rotate(${rotation}deg)`;
        }};
    `;

    useEffect(() => {
        let isApiSubscribed = true

        displayRandomStories().then((response) => {
            if (!response.error && isApiSubscribed) {
                setRandomStories(response);
                setPictureBriefNum(response.length - 1);
                setArrayLength(response.length - 1)
            }
        })
        return () => {
            isApiSubscribed = false
        }
    }, [])


    const style = {
        backgroundImage: `url(${goldDesign2})`,
        backgroundRepeat: "no-Repeat",
        backgroundSize: "cover",
        position: "relative",
        overflowX: "hidden"
    }

    const style2 = {
        position: "relative",
    }

    return (
        <Grid container style={windowWidth > 500 ? style2 : style} height={windowWidth > 900 ? windowHeight : "100%"} alignItems="center" >
            {windowWidth > 500 &&
                <img src={goldDesign} width="100%" alt="design gold" className={classes["gold-design2"]} />
            }

            <Grid item container paddingBottom={10} paddingTop={{ md: 8, xs: 5 }}>
                <Grid item container position="relative" xs={12} justifyContent="flex-start" >
                    <Grid item xs={0.8} />

                    <Grid item xs={11.2} >
                        <span className={classes["idea"]}> Every idea has a story</span>

                        <Grid item container xs={12} alignItems="center" paddingBottom={{ xs: 0, md: 10, lg: 5 }} paddingTop={{ xs: 4, md: 15, lg: 19 }}>
                            <Grid item xs={12} md={7} lg={6} >
                                {randomStories ?
                                    <Divv>
                                        <Stack Id={randomStories[pictureBriefNum]} pictureBriefNum={pictureBriefNum} arrayLength={arrayLength} setPictureBriefNum={setPictureBriefNum} onVote={(item, vote) => { }}>
                                            {randomStories?.map((story, index) => (
                                                <Item key={index} style={{ backgroundColor: story.IsClientStory ? "red" : "#1F96C2", border: `11px solid ${story.IsClientStory ? "red" : "#1F96C2"}`, backgroundImage: `url(${story?.CoverPhoto?.URL})` }} whileTap={{ scale: 1.2 }} >
                                                </Item>
                                            ))}
                                        </Stack>
                                    </Divv>
                                    :
                                    <></>
                                }
                            </Grid>

                            <Grid item xs={12} md={4} lg={5} container justifyContent={{ xs: "flex-start", md: "center" }} alignContent="center" paddingTop={{ md: 6, xs: 4 }} >
                                <Grid item xs={0.8} md={1} lg={0} />
                                <Grid item xs={11.2} md={11} lg={12} paddingTop={{ xs: 17, md: 0 }}>
                                    {windowWidth < 900 ? <Grid item width="90px" paddingBottom={1} borderTop="2px solid black" /> : <></>}
                                    <Grid item onClick={() => { randomStories[pictureBriefNum].IsClientStory ? navigate(`/client-stories/${randomStories[pictureBriefNum].Id}`) : randomStories[pictureBriefNum].CategoryId === 1 ? navigate(`/Insight?Id=${randomStories[pictureBriefNum].Id}`) : navigate(`/BTS?Id=${randomStories[pictureBriefNum].Id}`) }} paddingBottom={{ sm: 0.8, xs: 0 }} xs={11} md={12} lg={10} className={classes["rulers-text"]} >
                                        {randomStories && pictureBriefNum > -1 ? randomStories[pictureBriefNum].Description : ""}
                                    </Grid >
                                    {windowWidth > 900 && <Grid item width="90px" borderBottom="2px solid black" />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RecentStories;