import React, { useState, useEffect, useRef } from 'react';
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import classes from "../../../Assets/Styles/Menu.module.css";
import Fade from '@mui/material/Fade';
import windowDimensions from "../../WindowDimension";


const style2 = {
    position: "absolute",
    right: "5%",
}

const style3 = {
    position: "absolute",
    left: "5%",
}

const style4 = {
    position: "absolute",
    right: "-1%",
}


const ClientStoriesNavbar = (props) => {

    let navigate = useNavigate();

    const [scrollPosition, setScrollPosition] = useState(0);
    const { windowWidth, windowHeight } = windowDimensions();

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const style = {
        boxShadow: "0px 0px",
        backgroundColor: "white",
        padding: 0,

    }
    const [hidePath, setHidePath] = useState(true);

    return (<>

        <AppBar id="stories" position="sticky" style={style}>
            <Container
                maxWidth="100%"
            >
                <Toolbar disableGutters>
                    <Grid container paddingTop={{ xs: 2, md: 3.8 }} paddingBottom={{ md: 4, xs: 3 }} position="relative" alignItems="center" >

                        {windowWidth > 900 ?
                            <Grid item paddingTop={{ sm: 1, xs: 0.5 }} style={style4}>
                                <Grid item container alignItems="center">
                                    {hidePath ? <></>
                                        :
                                        <Grid item paddingRight={2} className={classes["Our-Stories"]}>
                                            <span> Client Stories</span>
                                        </Grid>
                                    }

                                    <Grid item onClick={() => { navigate("/client-stories") }} onMouseOver={() => { setHidePath(false); }} onMouseOut={() => { setHidePath(true) }} className={classes["red-circle"]} />

                                </Grid>
                            </Grid>
                            :
                            <Grid item paddingTop={{ sm: 1, xs: 0.5 }} style={style3}>
                                <Grid item className={classes["circle-menu2"]} onClick={() => { props.setOpenPhoneMenuModal(true) }} />
                            </Grid>
                        }
                        <Grid item container xs={12} sm={12} justifyContent="center" alignItems="center">
                            <Grid item style={{ cursor: "pointer" }} position="relative" onClick={() => { navigate("/dp-stories") }}>
                                <Grid item className={classes["dp"]}  >
                                    DIGITAL PRESTIGE
                                    <Grid item className={classes["dp-stories"]}>
                                        Stories
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* </Fade> */}
                </Toolbar>
            </Container>
        </AppBar>
    </>)
}

export default ClientStoriesNavbar;