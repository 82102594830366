import Grid from "@mui/material/Grid";
import classes from '../../Assets/Styles/WhoAreWe.module.css';
import React, { useEffect, useRef } from 'react'
import BodyAnimation2 from './BodyAnimation2'
import BodyAnimation22 from './BodyAnimation22'
import windowDimensions from "../WindowDimension";

const WhoAreWe = (props) => {

    let ref = useRef();

    const {windowWidth} = windowDimensions();

    useEffect(() => {
        props.setWhoAreWhePosition(ref?.current?.clientHeight);
    }, [windowWidth, props.scrollToContactUsForm])

    return (<>
        <Grid container ref={ref} zIndex="1" marginTop={{ md: 15, xs: 15 }} direction={"row"} maxWidth={'xl'}>
            {windowWidth > 750 && <BodyAnimation2 />}

            <Grid item container md={1.5} xs={1} />
            <Grid item container md={10.5} xs={11}>

                <Grid item paddingBottom={{ xs: 3, md: 0 }} xs={12} paddingTop={1} md={2.5} lg={2} >
                    <span className={classes["WHO-ARE-WE"]}>
                        WHO ARE WE?
                    </span>
                </Grid>

                <Grid item md={9.5} lg={10}>
                    <Grid item>
                        <span className={classes["NOT-ANOTHER-FULL-STACK-PRODUCT-COMPANY"]}>
                            FULL STACK PRODUCT <br></br>
                            <span className={classes['company']}>COMPANY</span>
                        </span>
                    </Grid>
                    <Grid item paddingTop={{ md: 6, xs: 4 }} md={8.5} lg={8} sm={10.5} xs={11} >
                        <span className={classes["WhoAreWe-text"]}>
                            We specialize in end-to-end development of high impact systems, providing technological solutions to start-ups and companies that are seeking to innovate and need support in developing their ideas. <br></br> <br></br>
                            We engrain high standard tech delivery processes in your company. We
                            take your idea from concept to real, at lower cost, higher quality and faster pace. <br></br><br></br>
                            A software conceptualizer and creator, our fully trained end to end agile methodology-oriented teams will guide you through your solutions.
                        </span>
                    </Grid>

                    {windowWidth < 750 &&
                        <Grid item >
                            <BodyAnimation22 />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    </>)
}

export default WhoAreWe;