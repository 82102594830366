import Grid from "@mui/material/Grid";
import React, { useEffect, useState, useRef } from 'react';
import classes from "../Assets/Styles/Industries.module.css";
import servicesFrontImages2 from "../Assets/Images/servicesFrontImage2.png";
import firstImage from "../Assets/Images/firstImage.png";
import HandImage from "../Assets/Images/handImage.png"
import robotImage from "../Assets/Images/robotImage.png"
import saasImage from "../Assets/Images/saasImage.png"
import cloudImage from "../Assets/Images/cloudImage.png"
import automationImage from "../Assets/Images/automationImage.png"
import blockChainImage from "../Assets/Images/blockChainImage.png"
import webPlatformImage from "../Assets/Images/webPlatformImage.png"
import AIimage from "../Assets/Images/AIimage.png"
import smallTrain from "../Assets/Images/smallTrain.png";
import rightServicesArrow from "../Assets/Images/rightServicesArrow.png"
import leftServicesArrow from "../Assets/Images/leftServicesArrow.png"
import smallFirstServicesImage from "../Assets/Images/smallFirstServicesImage.jpg";
import windowDimensions from '../Components/WindowDimension';
import servicesFrontImages from "../Assets/Images/firstImage.jpg";
import Fade from '@mui/material/Fade';
import { Helmet, HelmetProvider } from "react-helmet-async";

const Services = (props) => {

    useEffect(()=>{
        window.scrollTo({top:0})
    },[])
    
    const style = {
        position: "absolute",
        top: 0,
        backgroundColor: "black",
        zIndex: 9
    }

    const { windowWidth, windowHeight } = windowDimensions();

    const style2 = {
        position: "absolute",
        right: 0,
        top: windowWidth < 700 ? 0 : "unset",
        zIndex: 10
    }

    const ref = useRef(null);
    const [nextPage, setNextPage] = useState(false);

    useEffect(() => {
        props.setHeight(ref.current.clientHeight);
        window.scroll(0, 0)
        setTransition(true);
    }, [windowWidth])

    const [transition, setTransition] = useState(false);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title> {`Services`} </title>
                    <meta property="og:description"
                        content="Digital Prestige’s global standards in digitization and technological solutions is fostered by the industry’s most innovative, collaborative and diverse talent. Together our people deliver custom, innovative, scalable and sustainable products based on actionable deep business analysis." />
                    <meta name="image" property="og:image" content="https://testimgg.s3.eu-west-1.amazonaws.com/DPLogo.png" />
                    <meta property="og:url" content="https://www.digitalprestige.com/" />
                    <meta
                        name="keywords"
                        content="Technology Innovation, custom software development, product development, Saas, industry digitalization, agile transformation advisory, machine leaning, lebanon, dubai, UAE, MENA, automation, cloud, blockchain, technology process advisory, enterprise solutions, web platform, mobile platform, digital commerce, artificial intelligence, complimentory marketing services, enterprise content management system, application services"
                    />
                    <link rel="canonical" href={`/services`} />
                </Helmet>
            </HelmetProvider>

            <Grid container>
                <Grid item ref={ref} container style={{ overflowX: "hidden" }} height={{ xs: "80vh", md: "90vh" }}>
                    <Grid item className={classes["overlay"]} />
                    <Fade in={transition} timeout={1150}>
                        <img src={windowWidth < 700 ? smallFirstServicesImage : servicesFrontImages} style={style} width="100%" height="100%" alt="front services image" />
                    </Fade>
                    <Fade in={transition} timeout={1150}>
                        <img src={windowWidth < 700 ? smallTrain : servicesFrontImages2} style={style2} width={windowWidth < 700 ? "auto" : "78%"} height={windowWidth < 700 ? "100%" : "78%"} alt="front services image" />
                    </Fade>
                </Grid>

                <Grid item container paddingBottom={8} style={{ overflowX: "hidden" }}>
                    <Grid item container xs={0.9} md={0.9} lg={1.4} />
                    <Grid item container xs={10.2} md={10.5} lg={9.1} xl={9}>
                        {windowWidth < 899
                            ?
                            <> {/* Welcome to digitalization */}
                                {!nextPage ?
                                    <>
                                        <Grid item container paddingTop={{ xs: 8, xl: 12 }} paddingBottom={{ xs: 9, md: 17 }} md={8.3} lg={8.3} xl={8.8} gap={3} position="relative">
                                            <Grid item xs={12} paddingBottom={{ xs: 15, md: 0 }} className={classes["WELCOME-TO-Digitization"]}>
                                                Welcome to Digitization
                                            </Grid>

                                            {windowWidth < 900
                                                ?
                                                <>
                                                    <Grid item >
                                                        <Grid item container alignItems="flex-end" className={classes["WELCOME-TO-Digitization-grid2"]}>
                                                            <Grid item alignItems="flex-end" container height={{ xs: "150px", sm: "148px" }}>
                                                                <Grid item >
                                                                    <span className={classes["WELCOME-TO-Digitization-text"]}>
                                                                        Digital Prestige’s global standards in digitization and technological
                                                                    </span>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                        <Grid item className={classes["WELCOME-TO-Digitization-grid"]}>
                                                            <span className={classes["WELCOME-TO-Digitization-text"]}>
                                                                solutions is fostered by the industry’s most innovative, collaborative and diverse talent. Together our people deliver custom, innovative, scalable and sustainable products based on actionable deep business analysis.
                                                                <br></br> <br></br>
                                                                <span>
                                                                    Our experience and highly trained teams use the latest technologies and languages tailoring delicately designed solutions, aiming to uplift your businesses to the next digital level.
                                                                </span>
                                                            </span>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item alignSelf="center" className={classes["first-image-position"]}>
                                                        <img src={firstImage} className={classes["first-image"]} alt="first image" />
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    <Grid item className={classes["WELCOME-TO-Digitization-grid"]}>
                                                        <span className={classes["WELCOME-TO-Digitization-text"]}>
                                                            Digital Prestige’s global standards in digitization and technological solutions is fostered by the industry’s most innovative, collaborative and diverse talent. Together our people deliver custom, innovative, scalable and sustainable products based on actionable deep business analysis.
                                                            <br></br> <br></br>
                                                            <span>
                                                                Our experience and highly trained teams use the latest technologies and languages tailoring delicately designed solutions, aiming to uplift your businesses to the next digital level.
                                                            </span>
                                                        </span>
                                                    </Grid>
                                                    <Grid item alignSelf="center" className={classes["first-image-position"]}>
                                                        <img src={firstImage} className={classes["first-image"]} alt="first image" />
                                                    </Grid>
                                                </>
                                            }

                                        </Grid>

                                        {/* technology innovation */}
                                        <Grid item container position="relative">
                                            <Grid item xs={0} md={0.4} lg={0.7} />
                                            <Grid item container xs={12} md={11.6} lg={11.3} gap={2}>
                                                <Grid item container md={6.8} lg={6.8} className={classes["technology-innovation-grid"]} gap={{ xs: 0, md: 3 }}>
                                                    <Grid item sm={12} xs={6} className={classes["title"]}>
                                                        <span>Technology Innovation </span>
                                                    </Grid>
                                                    <Grid item container height={{ sm: 89, md: 0 }} alignItems="flex-end">

                                                        <Grid item xs={12} paddingTop={3}>
                                                            <span className={classes["textt"]}>
                                                                With the rapid track
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <span className={classes["textt"]}>
                                                            of market trends changes, companies must develop scalable custom software and use innovative technologies to turn their needs into business opportunities. Our Agile methodology can help you build products with a long term vision up to ten years and more.
                                                        </span>
                                                    </Grid>
                                                </Grid>

                                                <Grid item container className={classes["white-back-grid"]} md={4.8} lg={4.8} gap={3}>
                                                    <Grid item xs={10} sm={12} className={classes["title"]}>
                                                        <span> Custom Software Development </span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span className={classes["textt"]}>
                                                            We use the latest technologies and secure cloud services combined with the knowledge of our business analysts, proficiency of our developers and the creativity of our designers to deliver global standards custom software that will uplift your business.                                     </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item alignSelf="center" className={classes["hand-image-position"]}>
                                                <img src={HandImage} className={classes["hand-image"]} alt="first image" />
                                            </Grid>
                                        </Grid>

                                        {/* product development */}
                                        <Grid item container paddingTop={4} paddingBottom={3} position="relative">
                                            <Grid item container md={10} lg={10} gap={3} className={classes["product-development-grid"]}>
                                                <Grid item paddingLeft="138px" className={classes["title"]}>
                                                    <span>Product Development</span>
                                                </Grid>
                                                <Grid item md={10.5} lg={10.5} className={classes["textt"]}>
                                                    We shape interactive and efficient solutions that help businesses achieve their goals, by covering the whole life cycle from strategizing and designing a product to developing, testing, deploying and supporting scalable solutions. Growth services offe additional releases, digital marketing content and many others.
                                                </Grid>
                                                <Grid item alignSelf="center" className={classes["robot-image-position"]}>
                                                    <img src={robotImage} className={classes["robot-image"]} alt="first image" />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* saas */}
                                        <Grid item container paddingBottom={3}>
                                            <Grid item container md={4.5} lg={4.5} direction="row">
                                                <Grid item container className={classes["saas-grid"]}>
                                                    <Grid item xs={12} className={classes["title"]}>
                                                        SaaS
                                                    </Grid>

                                                    <Grid item container paddingTop={3} className={classes["textt2"]} position="relative">
                                                        <> <Grid item md={7.4} lg={7.4} >
                                                            <span>The SaaS (Software
                                                                as a Service) generates
                                                                cash inflows through
                                                                payments done by
                                                                subscribers frequently.
                                                                It is compatible on all devices with the perfect look and feel designed by highly trained designers and executed by teams of highly trained engineers.

                                                            </span>
                                                        </Grid>
                                                            <Grid item alignSelf="center" className={classes["saas-image-position"]}>
                                                                <img src={saasImage} className={classes["saas-image"]} alt="saas image" />
                                                            </Grid>
                                                        </>
                                                    </Grid>
                                                </Grid>

                                                <Grid item container paddingTop={{ xs: 2, md: 3 }} paddingBottom={{ xs: 2, md: 0 }}>
                                                    <Grid item container className={classes["machine-learning-grid"]} gap={3}>
                                                        <Grid item xs={12} className={classes["title"]}>
                                                            Machine Learning
                                                        </Grid>
                                                        <Grid item className={classes["textt"]}>
                                                            We develop a method of data analysis that automates analytical model building where the solution can process data and make decisions with a minimal human intervention which will enhance the resources allocation in a more efficient way.
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item container md={7.3} lg={7.3} direction="row" gap={{ xs: 2, md: 3 }}>
                                                <Grid item container>
                                                    <Grid item md={1.5} lg={1.5} />
                                                    <Grid item md={10.5} lg={10.5}>
                                                        <Grid item container gap={3} className={classes["saas-grid"]}>
                                                            <Grid item xs={12} className={classes["title"]}>
                                                                Industry Digitization
                                                            </Grid>
                                                            <Grid item className={classes["textt"]}>
                                                                Digital transformation is the headline of every industry. Our full stack developers team along with the business analysts build innovative solutions for various sectors including the entire industrial process, resource extraction, supply chain management, production and delivery of goods.
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item container paddingLeft={{ xs: 0, md: 3 }}>
                                                    <Grid item container className={classes["machine-learning-grid"]} gap={3}>
                                                        <Grid item xs={12} className={classes["title"]}>
                                                            Agile Transformation Advisory
                                                        </Grid>
                                                        <Grid item className={classes["textt"]}>
                                                            The agile methodology synchronizes structure, culture and behavior. The Agile approach unleashes the human and business potential while adapting to any change in the market trends. Our highly trained teams assist businesses in their agile transformation process.
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item container marginLeft={{ xs: 1, md: 3 }} position="relative" >
                                                    <Grid item container marginLeft={2} className={classes["saas-grid"]}>
                                                        <Grid item md={4.5} lg={4.5} />
                                                        <Grid item md={7.5} lg={7.5} container>
                                                            <Grid item paddingLeft="150px" xs={12} className={classes["title"]}>
                                                                Cloud
                                                            </Grid>
                                                            <Grid item container alignItems="flex-end" height="70px">


                                                                <Grid item paddingLeft="150px" className={classes["textt"]}>
                                                                    We adopt cloud hosting
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className={classes["textt"]}>
                                                                for our clients, which ensures high level of security and content availability.
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item alignSelf="center" className={classes["cloud-image-position"]}>
                                                        <img src={cloudImage} className={classes["cloud-image"]} alt="first image" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item container paddingTop={1} justifyContent="flex-end">
                                                    <Grid item onClick={() => {
                                                        setNextPage(!nextPage);
                                                        window.scrollTo(0, windowHeight - 90)
                                                    }}>
                                                        <img style={{ cursor: "pointer" }} src={rightServicesArrow} alt="right arrow" />
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </>

                                    :
                                    <>
                                        {/* automation */}
                                        <Grid item container paddingBottom={3} paddingTop={9} gap={3}>
                                            <Grid item container md={5} lg={5} direction="row">
                                                <Grid item container md={10.85} lg={10.85} className={classes["saas-grid"]} position="relative">
                                                    <Grid item alignSelf="center" className={classes["automation-image-position"]}>
                                                        <img src={automationImage} className={classes["automation-image"]} alt="saas image" />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes["title"]}>
                                                        Automation
                                                    </Grid>

                                                    <Grid item container paddingTop={3} className={classes["textt"]}>
                                                        <Grid item container height="55px" alignItems="flex-end">
                                                            <Grid item paddingRight="125px" >
                                                                <span>
                                                                    We develop high end solutions to
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item md={8} lg={9}>
                                                            <span> automate the internal
                                                                operations and adapt to various business processes.
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item container paddingTop={{ xs: 2, md: 3 }}>
                                                    <Grid item container className={classes["advisory-grid"]} gap={3}>
                                                        <Grid item xs={12} className={classes["title"]}>
                                                            Technology Process Advisory
                                                        </Grid>
                                                        <Grid item className={classes["textt"]}>
                                                            <span>To achieve the ultimate return on tech investments, companies must find the appropriate approach to build and manage technology process and transformation.
                                                                <br></br> <br></br>
                                                                <span>Our team is ready to help organizations find the suitable process to uplift their businesses and to adopt digital transformation in the most effective and efficient approach to re-create their business.</span>
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {windowWidth > 899 &&
                                                    <Grid item container paddingTop={3}>
                                                        <Grid item container marginBottom={3} className={classes["mobile-grid"]} gap={3}>
                                                            <Grid item xs={12} className={classes["title"]}>
                                                                Mobile Platform
                                                            </Grid>
                                                            <Grid item className={classes["textt"]}>
                                                                <span>
                                                                    The world is being run through smartphones. Using edge technologies, our team can deliver a cross-platform mobile application that will help businesses run their operations anywhere anytime.
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>}

                                            </Grid>

                                            <Grid item container gap={{ xs: 2, md: 3 }} md={6.55} lg={6.55}>
                                                <Grid item container marginTop={2} className={classes["blockChain-grid"]} position="relative">
                                                    <Grid item alignSelf="center" className={classes["blockChain-image-position"]}>
                                                        <img src={blockChainImage} className={classes["blockchain-image"]} alt="saas image" />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes["title"]}>
                                                        Blockchain
                                                    </Grid>

                                                    <Grid item container paddingTop={3} className={classes["textt"]}>
                                                        <Grid item xs={12} md={6.5} lg={6.5} xl={7.4}>
                                                            <span>Equip your business
                                                                with block chain systems that
                                                                stores data in a way allowing organizations and individuals to securely share access to the same data in real-time.
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item container className={classes["enterprise-grid"]}>
                                                    <Grid item xs={12} className={classes["title"]}>
                                                        Enterprise Solutions
                                                    </Grid>

                                                    <Grid item container paddingTop={3} className={classes["textt"]}>
                                                        <Grid item xs={12}>
                                                            <span>Our teams of highly trained business analysts and
                                                                engineers deliver a scalable user friendly enterprise
                                                                solution that guarantees an efficient proper work process.
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {windowWidth < 900 &&
                                                    <Grid item container >
                                                        <Grid item container marginBottom={{ xs: 0, md: 3 }} className={classes["mobile-grid"]} gap={3}>
                                                            <Grid item xs={12} className={classes["title"]}>
                                                                Mobile Platform
                                                            </Grid>
                                                            <Grid item className={classes["textt"]}>
                                                                <span>
                                                                    The world is being run through smartphones. Using edge technologies, our team can deliver a cross-platform mobile application that will help businesses run their operations anywhere anytime.
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }


                                                <Grid item marginLeft={{ xs: 2, md: 4.5 }} container className={classes["blockChain-grid"]} position="relative">
                                                    <Grid item alignSelf="center" className={classes["web-image-position"]}>
                                                        <img src={webPlatformImage} className={classes["web-image"]} alt="first image" />
                                                    </Grid>
                                                    <Grid item md={4.5} lg={4.5} />
                                                    <Grid item container md={7.5} lg={7.5}>
                                                        <Grid item xs={12} paddingLeft="120px" className={classes["title"]}>
                                                            Web Platform
                                                        </Grid>

                                                        <Grid item container paddingTop={3} className={classes["textt"]}>
                                                            <Grid item xs={12}>
                                                                <span>Create your own solutions that reside on remote servers and that
                                                                    could be accessed by users over the internet
                                                                    without the need of being downloaded using the latest technologies.
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item marginLeft={{ xs: 0, md: 4.5 }} container className={classes["enterprise-grid"]}>
                                                    <Grid item xs={12} className={classes["title"]}>
                                                        Digital Commerce
                                                    </Grid>

                                                    <Grid item container paddingTop={3} className={classes["textt"]}>
                                                        <Grid item xs={12}>
                                                            <span>Revenue generating market places that responds to
                                                                the latest market trends and widens the exposure to the consumers.
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* artificial intelligence */}
                                        <Grid item container gap={{ xs: 2, md: 3 }} marginTop={{ xs: "-8px", md: "-40px" }}>
                                            <Grid item container md={6.8} lg={6.8} direction="row">
                                                <Grid item container md={9} lg={9} position="relative" className={classes["ai-grid"]}>
                                                    <Grid item alignSelf="center" className={classes["ai-position"]}>
                                                        <img src={AIimage} className={classes["ai-image"]} alt="first image" />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes["title"]}>
                                                        Artificial Intelligence
                                                    </Grid>

                                                    <Grid item container alignItems="flex-end" height="134px">
                                                        <Grid item paddingTop={3} paddingRight="160px" className={classes["textt"]}>
                                                            AI is the bridge to convert the
                                                            companies’ data
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes["textt"]}>
                                                        into business value.
                                                        Our team will make cloud, data and AI operate together to take smart
                                                        and fast decisions that will help you uplift your organization and enable growth.
                                                    </Grid>
                                                </Grid>

                                                <Grid item container paddingTop={{ xs: 2, md: 3 }} marginBottom={{ xs: 0, md: 1.5 }} >
                                                    <Grid item container className={classes["mobile-grid"]} gap={3}>
                                                        <Grid item xs={12} className={classes["title"]}>
                                                            Application Services
                                                        </Grid>
                                                        <Grid item className={classes["textt"]}>
                                                            <span>
                                                                We develop application services that help you reimagine your application landscape, with speed and agility, while continuous innovation will help companies realize their business value without restricting the ability to drive growth.                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item container md={4.75} lg={4.75} direction="row" gap={{ xs: 2, md: 3 }}>
                                                <Grid item container marginTop={{ xs: 0, md: 4 }} className={classes["saas-grid"]} position="relative">
                                                    <Grid item xs={12} className={classes["title"]}>
                                                        Complimentary Marketing Services
                                                    </Grid>

                                                    <Grid item container paddingTop={3} className={classes["textt"]}>
                                                        <Grid item >
                                                            <span>We design the brand guidelines and set a custom digital marketing strategy that reflects the brand identity and the business vision.
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item container>
                                                    <Grid item container className={classes["mobile-grid"]} gap={3}>
                                                        <Grid item xs={12} className={classes["title"]}>
                                                            <span>
                                                                Enterprise Content Management System
                                                            </span>
                                                        </Grid>
                                                        <Grid item className={classes["textt"]}>
                                                            <span>
                                                                A content management system is a software application that enables users to create, edit, collaborate on, publish and store digital content where businesses can control all the permissions, processes and operations using the latest technologies.
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item container justifyContent="flex-start">
                                                <Grid item paddingTop={1} onClick={() => {
                                                    setNextPage(!nextPage);
                                                    window.scrollTo(0, windowHeight - 90)
                                                }}>
                                                    <img style={{ cursor: "pointer" }} src={leftServicesArrow} alt="previous Arrow" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            </>
                            :
                            <>
                                {/* Welcome to digitalization */}
                                <Grid item container paddingTop={15} paddingBottom={{ xs: 9, md: 17 }} md={8.3} lg={8.3} xl={8.8} gap={3} position="relative">
                                    <Grid item xs={12} paddingBottom={{ xs: 15, md: 0 }} className={classes["WELCOME-TO-Digitization"]}>
                                        Welcome to Digitization
                                    </Grid>

                                    {windowWidth < 900
                                        ?
                                        <>
                                            <Grid item >
                                                <Grid item container className={classes["WELCOME-TO-Digitization-grid2"]}>
                                                    <Grid item height={{ xs: "100px", sm: "80px" }}>
                                                        <span className={classes["WELCOME-TO-Digitization-text"]}>
                                                            Digital Prestige’s global standards in digitization and technological
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes["WELCOME-TO-Digitization-grid"]}>
                                                    <span className={classes["WELCOME-TO-Digitization-text"]}>
                                                        solutions is fostered by the industry’s most innovative, collaborative and diverse talent. Together our people deliver custom, innovative, scalable and sustainable products based on actionable deep business analysis.
                                                        <br></br> <br></br>
                                                        <span>
                                                            Our experience and highly trained teams use the latest technologies and languages tailoring delicately designed solutions, aiming to uplift your businesses to the next digital level.
                                                        </span>
                                                    </span>
                                                </Grid>

                                            </Grid>
                                            <Grid item alignSelf="center" className={classes["first-image-position"]}>
                                                <img src={firstImage} className={classes["first-image"]} alt="first image" />
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item className={classes["WELCOME-TO-Digitization-grid"]}>
                                                <span className={classes["WELCOME-TO-Digitization-text"]}>
                                                    Digital Prestige’s global standards in digitization and technological solutions is fostered by the industry’s most innovative, collaborative and diverse talent. Together our people deliver custom, innovative, scalable and sustainable products based on actionable deep business analysis.
                                                    <br></br> <br></br>
                                                    <span>
                                                        Our experience and highly trained teams use the latest technologies and languages tailoring delicately designed solutions, aiming to uplift your businesses to the next digital level.
                                                    </span>
                                                </span>
                                            </Grid>
                                            <Grid item alignSelf="center" className={classes["first-image-position"]}>
                                                <img src={firstImage} className={classes["first-image"]} alt="first image" />
                                            </Grid>
                                        </>
                                    }

                                </Grid>

                                {/* technology innovation */}
                                <Grid item container position="relative">
                                    <Grid item xs={0} md={0.4} lg={0.7} />
                                    <Grid item container xs={12} md={11.6} lg={11.3} gap={2}>
                                        <Grid item container md={6.8} lg={6.8} className={classes["technology-innovation-grid"]} gap={{ xs: 0, md: 3 }}>
                                            <Grid item sm={12} xs={6} className={classes["title"]}>
                                                <span>Technology Innovation </span>
                                            </Grid>

                                            {windowWidth < 899 ?
                                                <>
                                                    <Grid item xs={12} height={{ sm: 89, md: 0 }} paddingTop={3}>
                                                        <span className={classes["textt"]}>
                                                            With the rapid track
                                                        </span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span className={classes["textt"]}>
                                                            of market trends changes, companies must develop scalable custom software and use innovative technologies to turn their needs into business opportunities. Our Agile methodology can help you build products with a long term vision up to ten years and more.
                                                        </span>
                                                    </Grid>
                                                </>

                                                :
                                                <Grid item>
                                                    <span className={classes["textt"]}>
                                                        With the rapid track of market trends changes, companies must develop scalable custom software and use innovative technologies to turn their needs into business opportunities. Our Agile methodology can help you build products with a long term vision up to ten years and more.
                                                    </span>
                                                </Grid>
                                            }

                                        </Grid>

                                        <Grid item container className={classes["white-back-grid"]} md={4.8} lg={4.8} gap={3}>
                                            <Grid item xs={10} sm={12} className={classes["title"]}>
                                                <span> Custom Software Development </span>
                                            </Grid>
                                            <Grid item>
                                                <span className={classes["textt"]}>
                                                    We use the latest technologies and secure cloud services combined with the knowledge of our business analysts, proficiency of our developers and the creativity of our designers to deliver global standards custom software that will uplift your business.                                        </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item alignSelf="center" className={classes["hand-image-position"]}>
                                        <img src={HandImage} className={classes["hand-image"]} alt="first image" />
                                    </Grid>
                                </Grid>

                                {/* product development */}
                                <Grid item container paddingTop={{ xs: 2, md: 3 }} paddingBottom={{ xs: 2, md: 3 }} position="relative">
                                    <Grid item container md={10} lg={10} gap={3} className={classes["product-development-grid"]}>
                                        <Grid item className={classes["title"]}>
                                            <span>Product Development</span>
                                        </Grid>
                                        <Grid item md={10.5} lg={10.5} className={classes["textt"]}>
                                            We shape interactive and efficient solutions that help businesses achieve their goals, by covering the whole life cycle from strategizing and designing a product to developing, testing, deploying and supporting scalable solutions. Growth services offer additional releases, digital marketing content and many others.
                                        </Grid>
                                        <Grid item alignSelf="center" className={classes["robot-image-position"]}>
                                            <img src={robotImage} className={classes["robot-image"]} alt="first image" />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* saas */}
                                <Grid item container paddingBottom={3}>
                                    <Grid item container md={4.5} lg={4.5} direction="row">
                                        <Grid item container className={classes["saas-grid"]}>
                                            <Grid item xs={12} className={classes["title"]}>
                                                SaaS
                                            </Grid>

                                            <Grid item container paddingTop={3} className={classes["textt2"]} position="relative">
                                                <> <Grid item md={7.4} lg={7.4} >
                                                    <span>The SaaS (Software
                                                        as a Service) generates
                                                        cash inflows through
                                                        payments done by
                                                        subscribers frequently.
                                                    </span>
                                                </Grid>
                                                    <Grid item className={classes["textt2"]}>
                                                        It is compatible on all devices with the perfect look and feel designed by highly trained designers and executed by teams of highly trained engineers.
                                                    </Grid>
                                                    <Grid item alignSelf="center" className={classes["saas-image-position"]}>
                                                        <img src={saasImage} className={classes["saas-image"]} alt="saas image" />
                                                    </Grid>
                                                </>
                                            </Grid>
                                        </Grid>

                                        <Grid item container paddingTop={{ xs: 2, md: 3 }} paddingBottom={{ xs: 2, md: 0 }}>
                                            <Grid item container className={classes["machine-learning-grid"]} gap={3}>
                                                <Grid item xs={12} className={classes["title"]}>
                                                    Machine Learning
                                                </Grid>
                                                <Grid item className={classes["textt"]}>
                                                    We develop a method of data analysis that automates analytical model building where the solution can process data and make decisions with a minimal human intervention which will enhance the resources allocation in a more efficient way.
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item container md={7.3} lg={7.3} direction="row" gap={{ xs: 2, md: 3 }}>
                                        <Grid item container>
                                            <Grid item md={1.5} lg={1.5} />
                                            <Grid item md={10.5} lg={10.5}>
                                                <Grid item container gap={3} className={classes["saas-grid"]}>
                                                    <Grid item xs={12} className={classes["title"]}>
                                                        Industry Digitization
                                                    </Grid>
                                                    <Grid item className={classes["textt"]}>
                                                        Digital transformation is the headline of every industry. Our full stack developers team along with the business analysts build innovative solutions for various sectors including the entire industrial process, resource extraction, supply chain management, production and delivery of goods.
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item container paddingLeft={{ xs: 0, md: 3 }}>
                                            <Grid item container className={classes["machine-learning-grid"]} gap={3}>
                                                <Grid item xs={12} className={classes["title"]}>
                                                    Agile Transformation Advisory
                                                </Grid>
                                                <Grid item className={classes["textt"]}>
                                                    The agile methodology synchronizes structure, culture and behavior. The Agile approach unleashes the human and business potential while adapting to any change in the market trends. Our highly trained teams assist businesses in their agile transformation process.
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item container marginLeft={{ xs: 1, md: 3 }} position="relative" >
                                            <Grid item container marginLeft={2} className={classes["saas-grid"]}>
                                                <Grid item md={4.5} lg={4.5} />
                                                <Grid item md={7.5} lg={7.5} container gap={3}>
                                                    <Grid item xs={12} className={classes["title"]}>
                                                        Cloud
                                                    </Grid>
                                                    <Grid item className={classes["textt"]}>
                                                        We adopt cloud hosting for our clients, which ensures
                                                        high level of security and content availability.
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item alignSelf="center" className={classes["cloud-image-position"]}>
                                                <img src={cloudImage} className={classes["cloud-image"]} alt="first image" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* automation */}
                                <Grid item container paddingBottom={3} gap={3}>
                                    <Grid item container md={5} lg={5} direction="row">
                                        <Grid item container md={10.85} lg={10.85} className={classes["automation-grid"]} position="relative">
                                            <Grid item alignSelf="center" className={classes["automation-image-position"]}>
                                                <img src={automationImage} className={classes["automation-image"]} alt="saas image" />
                                            </Grid>
                                            <Grid item xs={12} className={classes["title"]}>
                                                Automation
                                            </Grid>

                                            <Grid item container paddingTop={2} >
                                                <Grid item md={8} lg={9} className={classes["textt"]}>
                                                    <span> We develop high end solutions to automate the internal operations and adapt to various business processes.</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item container paddingTop={{ xs: 2, md: 3 }}>
                                            <Grid item container className={classes["advisory-grid"]} gap={3}>
                                                <Grid item xs={12} className={classes["title"]}>
                                                    Technology Process Advisory
                                                </Grid>
                                                <Grid item className={classes["textt"]}>
                                                    <span>To achieve the ultimate return on tech investments, companies must find the appropriate approach to build and manage technology process and transformation.
                                                        <br></br> <br></br>
                                                        <span>Our team is ready to help organizations find the suitable process to uplift their businesses and to adopt digital transformation in the most effective and efficient approach to re-create their business.</span>
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container paddingTop={3}>
                                            <Grid item container marginBottom={3} className={classes["mobile-grid"]} gap={3}>
                                                <Grid item xs={12} className={classes["title"]}>
                                                    Mobile Platform
                                                </Grid>
                                                <Grid item className={classes["textt"]}>
                                                    <span>
                                                        The world is being run through smartphones. Using edge technologies, our team can deliver a cross-platform mobile application that will help businesses run their operations anywhere anytime.
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid item container gap={{ xs: 2, md: 3 }} md={6.55} lg={6.55}>
                                        <Grid item container marginTop={4} className={classes["blockChain-grid"]} position="relative">
                                            <Grid item alignSelf="center" className={classes["blockChain-image-position"]}>
                                                <img src={blockChainImage} className={classes["blockchain-image"]} alt="saas image" />
                                            </Grid>
                                            <Grid item xs={12} className={classes["title"]}>
                                                Blockchain
                                            </Grid>

                                            <Grid item container paddingTop={3} className={classes["textt"]}>
                                                <Grid item md={6.5} lg={6.5} xl={7.4}>
                                                    <span>Equip your business
                                                        with block chain systems that
                                                    </span>
                                                </Grid>

                                                <Grid item xs={10.5}>
                                                    <span> stores data in a way allowing organizations and individuals to securely share access to the same data in real-time.
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item container className={classes["enterprise-grid"]}>
                                            <Grid item xs={12} className={classes["title"]}>
                                                Enterprise Solutions
                                            </Grid>

                                            <Grid item container paddingTop={3} className={classes["textt"]}>
                                                <Grid item xs={12}>
                                                    <span>Our teams of highly trained business analysts and
                                                        engineers deliver a scalable user friendly enterprise
                                                        solution that guarantees an efficient proper work process.
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item marginLeft={{ xs: 2, md: 4.5 }} container className={classes["blockChain-grid"]} position="relative">
                                            <Grid item alignSelf="center" className={classes["web-image-position"]}>
                                                <img src={webPlatformImage} className={classes["web-image"]} alt="first image" />
                                            </Grid>
                                            <Grid item md={4.5} lg={4.5} />
                                            <Grid item container md={7.5} lg={7.5}>
                                                <Grid item xs={12} className={classes["title"]}>
                                                    Web Platform
                                                </Grid>

                                                <Grid item container paddingTop={3} className={classes["textt"]}>
                                                    <Grid item xs={12}>
                                                        <span>Create your own solutions that reside on remote servers and that
                                                            could be accessed by users over the internet
                                                            without the need of being downloaded using the latest technologies.
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item marginLeft={{ xs: 0, md: 4.5 }} container className={classes["enterprise-grid"]}>
                                            <Grid item xs={12} className={classes["title"]}>
                                                Digital Commerce
                                            </Grid>

                                            <Grid item container paddingTop={3} className={classes["textt"]}>
                                                <Grid item xs={12}>
                                                    <span>Revenue generating market places that responds to
                                                        the latest market trends and widens the exposure to the consumers.
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* artificial intelligence */}
                                <Grid item container gap={{ xs: 2, md: 3 }} paddingTop={1} marginTop={{ xs: "-8px", md: "-40px" }}>
                                    <Grid item container md={6.8} lg={6.8} direction="row">
                                        <Grid item container md={9} lg={9} position="relative" className={classes["ai-grid"]}>
                                            <Grid item alignSelf="center" className={classes["ai-position"]}>
                                                <img src={AIimage} className={classes["ai-image"]} alt="first image" />
                                            </Grid>
                                            <Grid item xs={12} paddingTop={1} className={classes["title"]}>
                                                Artificial Intelligence
                                            </Grid>

                                            <Grid item xs={9} paddingTop={5} className={classes["textt"]}>
                                                <span> AI is the bridge to convert the
                                                    companies’ data into business value.
                                                </span>

                                            </Grid>

                                            <Grid item xs={12} paddingTop={1.5} paddingBottom={5} className={classes["textt"]}>
                                                <span>
                                                    Our team will make cloud, data and AI operate together to take smart
                                                    and fast decisions that will help you uplift your organization and enable growth.
                                                </span>
                                            </Grid>
                                        </Grid>

                                        <Grid item container paddingTop={{ xs: 2, md: 3 }} marginBottom={{ xs: 0, md: 1.5 }} >
                                            <Grid item container className={classes["mobile-grid"]} gap={3}>
                                                <Grid item xs={12} className={classes["title"]}>
                                                    Application Services
                                                </Grid>
                                                <Grid item className={classes["textt"]}>
                                                    <span>
                                                        We develop application services that help you reimagine your application landscape, with speed and agility, while continuous innovation will help companies realize their business value without restricting the ability to drive growth.                                            </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item container md={4.75} lg={4.75} direction="row" gap={{ xs: 2, md: 3 }}>
                                        <Grid item container marginTop={{ xs: 0, md: 4 }} className={classes["saas-grid"]} position="relative">
                                            <Grid item xs={12} className={classes["title"]}>
                                                Complimentary Marketing Services
                                            </Grid>

                                            <Grid item container paddingTop={3} className={classes["textt"]}>
                                                <Grid item >
                                                    <span>We design the brand guidelines and set a custom digital marketing strategy that reflects the brand identity and the business vision.
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item container>
                                            <Grid item container className={classes["mobile-grid"]} gap={3}>
                                                <Grid item xs={12} className={classes["title"]}>
                                                    <span>
                                                        Enterprise Content Management System
                                                    </span>
                                                </Grid>
                                                <Grid item className={classes["textt"]}>
                                                    <span>
                                                        A content management system is a software application that enables users to create, edit, collaborate on, publish and store digital content where businesses can control all the permissions, processes and operations using the latest technologies.
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>}
                    </Grid>
                </Grid>
            </Grid>
        </>)
}

export default Services;