import React from 'react';
import classes from "../../Assets/Styles/Industries.module.css";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";

const Services = (props) => {

    const handleClose = () => {
        props.setOpenServices(false)
    }
    
    return (
        <>
            <Modal
                disableEnforceFocus
                keepMounted
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={handleClose}
                onClick={handleClose}
                closeAfterTransition
                style={{ top: props.navHeight, overflowY: "hidden" }}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    style: { backgroundColor: "transparent", overflow: "scroll" },

                }}
            >
                <Grid container direction="column" className={props.scrollPosition > 0 ? classes["bordered-main-grid"] : classes["main-grid"]} top={props.open ? 0 : "-500px"}>
                    <Grid item container padding="2% 4% 2% 12%" >
                        <Grid item md={3} paddingBottom={4.2} paddingTop={4}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Custom Software Development</span>
                            </Link>
                        </Grid>

                        <Grid item md={3} paddingTop={4}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Product Development </span>
                            </Link>
                        </Grid>

                        <Grid item md={3} paddingTop={4} >
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Digital Commerce</span>
                            </Link>
                        </Grid>

                        <Grid item md={3} paddingTop={4} >
                            <Link to={"/services"} className={classes["text"]}>
                                <span > Application Services</span>
                            </Link>
                        </Grid>

                        <Grid item md={3} >
                            <Link to={"/services"} className={classes["text"]}>
                                <span > Technology Innovation</span>
                            </Link>
                        </Grid>

                        <Grid item md={3} paddingBottom={4.2}>

                            <Link to={"/services"} className={classes["text"]}>
                                <span> Machine Learning</span>
                            </Link>
                        </Grid>

                        <Grid item md={3}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span className={classes["text"]}> Web Platform</span>
                            </Link>
                        </Grid>

                        <Grid item md={3}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Complimentary Marketing Services</span>
                            </Link>
                        </Grid>

                        <Grid item md={3}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Mobile Platform</span>
                            </Link>
                        </Grid>

                        <Grid item md={3} paddingBottom={4.2}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Blockchain</span>
                            </Link>
                        </Grid>

                        <Grid item md={3}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Automation</span>
                            </Link>
                        </Grid>

                        <Grid item md={3}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span > Artificial Intelligence</span>
                            </Link>
                        </Grid>

                        <Grid item md={3} paddingBottom={4.2}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> SaaS</span>
                            </Link>
                        </Grid>

                        <Grid item md={3}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Enterprise Solution</span>
                            </Link>
                        </Grid>

                        <Grid item md={3}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Technology Process Advisory</span>
                            </Link>
                        </Grid>

                        <Grid item md={3} >
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Agile Transformation Advisory</span>
                            </Link>
                        </Grid>

                        <Grid item md={3} paddingBottom={4.2}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Industry Digitization</span>
                            </Link>
                        </Grid>

                        <Grid item md={3}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Cloud</span>
                            </Link>
                        </Grid>

                        <Grid item md={4} paddingBottom={4.2}>
                            <Link to={"/services"} className={classes["text"]}>
                                <span> Enterprise Content Management System</span>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}

export default Services;