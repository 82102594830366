import Grid from "@mui/material/Grid";
import classes from "../../../Assets/Styles/OurStoriesHomePage.module.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import blueDesign from "../../../Assets/Images/blueDesign.png";
import nextBlackArrow from "../../../Assets/Images/newBlackArrow.png";
import nextBlueArrow from "../../../Assets/Images/blueNextButton.png";
import cutBlueBg from "../../../Assets/Images/cutBlueBg.png";
import CircularProgress from "@mui/material/CircularProgress";
import windowDimensions from "../../WindowDimension";

import * as ourServices from '../../../Services/our-story-services.proxy';

const homePageList = ourServices.homePageList;
const shuffledBTSStory = ourServices.shuffledBTSStory;


const Home = (props) => {


    const [changingStory, setChangingStory] = useState(null);
    const [previousChangingStory, setPreviousChangingStory] = useState("");
    const [fadeIn, setFadeIn] = useState(false);
    const [loadingAni, setLoadingAni] = useState(false);
    const [btsArrowSrc, setBtsArrowSrc] = useState(nextBlackArrow);
    const [insightArrowSrc, setInsightArrowSrc] = useState(nextBlackArrow);
    const { windowWidth, windowHeight } = windowDimensions();

    const style = {
        backgroundImage: `url(${windowWidth > 470 ? blueDesign : cutBlueBg})`,
        backgroundRepeat: "no-Repeat",
        backgroundSize: windowWidth > 470 ? "contain" : "cover",
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [loadingAni])

    useEffect(() => {
        let isApiSubscribed = true;
        window.scrollTo(0, 0);
        setLoadingAni(true);
        homePageList().then((response) => {
            if (!response.error && isApiSubscribed) {
                setBtsStoriesNum(response.BTSLength);
                setInsightStoriesNum(response.InsightsLength);
                setInsightStoriesList(response.InsightList);
                setBtsStoriesList(response.BTSList);
                setLoadingAni(false);
            }
        })

        return () => {
            isApiSubscribed = false;
        };
    }, [])

    useEffect(() => {

        function getStory() {
            shuffledBTSStory().then((response) => {
                if (!response.error) {
                    if (previousChangingStory) {
                        if (response.Id !== previousChangingStory.Id) {
                            setFadeIn(false);
                            setTimeout(() => { setChangingStory(response); }, 400)
                            setPreviousChangingStory(response)
                        }
                    }
                    else {
                        setPreviousChangingStory(response)
                        setFadeIn(false);
                        setTimeout(() => { setChangingStory(response); }, 400)
                    }
                }
            })
        }
        getStory();
        const interval = setInterval(() => getStory(), 8700)

        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        setTimeout(() => { setFadeIn(true) }, 400)

    }, [changingStory])

    const [insightStoriesList, setInsightStoriesList] = useState([]);
    const [btsStoriesList, setBtsStoriesList] = useState([]);
    const [btsStoriesNum, setBtsStoriesNum] = useState("");
    const [insightStoriesNum, setInsightStoriesNum] = useState("");

    return (
        <>
            <Grid container >
                <Grid item container style={style} padding={windowWidth > 470 ? "10% 0% 20% 4%" : "10% 0% 3% 4%"} >
                    <Grid item paddingTop={2} paddingLeft={4} className={classes["OUR-STORIES"]} xs={12}>
                        OUR STORIES
                    </Grid>

                    <Grid item paddingTop={windowWidth < 470 ? 5 : 8} paddingBottom={10} className={classes["parag"]} xs={10} md={8.5} lg={6.5} xl={6}>
                        We have chosen to tell you our stories in different ways, named Insights and Behind the Scenes .
                        In Insights stories, you will be introduced to new topics and analyses by sharing our knowledge based on our business's experience.
                        In Behind the Scenes stories, we will share with you real life case studies where Digital Prestige team has worked closely with clients to enhance their business processes.
                    </Grid>

                </Grid>
                {loadingAni ?
                    <Grid item container justifyContent="center" alignItems="center" paddingTop={20} paddingBottom={20}>
                        <CircularProgress size={40} sx={{ color: "#1F96C2" }} />
                    </Grid>

                    :

                    <Grid item container paddingTop={{ xs: 0, md: 6 }} paddingLeft={{ lg: "4%", md: "4%", xs: "5%" }} paddingRight={{ lg: "4%", md: "4%", xs: "5%" }}>
                        {changingStory && btsStoriesNum > 0 ? <Grid item container justifyContent={{ sm: "center", md: "flex-start" }} className={fadeIn ? classes["change-opacity"] : classes["change-opacity2"]} gap={{ xs: 2, sm: 3 }} alignItems="center" paddingTop={{ xs: 0, sm: 10 }} paddingBottom={{ xs: 10, sm: 18 }}>
                            <Grid item sm={9} md={6.5} lg={5.7} xl={5} className={classes["Rectangle-1018"]} >
                                <Link to={`/BTS?Id=${changingStory.Id}`}>
                                    <img src={changingStory ? changingStory?.CoverPhoto?.URL : ""} width="100%" height="100%" alt="behind scenes" />
                                </Link>
                            </Grid>

                            <Grid item sm={9} md={5} lg={5} xl={5}>
                                <Grid item container gap={{ xs: 1, sm: 2 }}>
                                    <Grid item className={classes["behind-scenes"]}>
                                        BEHIND THE SCENE
                                    </Grid>

                                    <Grid item xs={12} md={9.5}>
                                        <Link to={`/BTS?Id=${changingStory.Id}`} className={classes["behind-scenes-title"]}>
                                            {changingStory ? changingStory?.Title : ""}
                                        </Link>
                                    </Grid>

                                    <Grid item xs={12} paddingTop={{ xs: 1, sm: 0 }} md={11} className={classes["behind-scenes-text"]}>
                                        {changingStory ? changingStory?.Brief : ""}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                            :
                            <></>}

                        {insightStoriesNum > 0 ? <Grid item paddingTop={{ xs: 0, sm: 10 }}>
                            <Grid item container >
                                <Grid item xs={12} className={classes["BEHIND-THE-SCENE"]} paddingBottom={1} >
                                    INSIGHTS
                                </Grid>

                                <Grid item className={classes["Path-1168"]} />
                            </Grid>
                            <Grid item container paddingTop={3.5} gap={{ sm: 2, md: 1.5, lg: 2, xl: 2.4 }} justifyContent="center">
                                {windowWidth > 600 ?
                                    <>
                                        {insightStoriesList?.map((item) => (
                                            <Grid item container key={item.Id} alignSelf="flex-start" md={3.85} sm={5.8} paddingBottom={5}>
                                                <Grid item className={classes["Rectangle-982"]}  >
                                                    <Link to={`/Insight?Id=${item.Id}`}>
                                                        <img src={item.CoverPhoto && item.CoverPhoto?.URL} width="100%" height="100%" alt="cover image" />
                                                    </Link>
                                                </Grid>

                                                <Grid item container gap={1} alignSelf="flex-start" marginTop={2} className={classes["tech"]} >
                                                    {item.Tags?.map((tag, index) => (
                                                        <span key={index} className={classes["Rectangle-988"]}> {tag} </span>
                                                    ))}
                                                </Grid>

                                                <Grid item container alignSelf="flex-start" paddingTop={0.7} xs={12} >
                                                    <Link to={`/Insight?Id=${item.Id}`} className={classes["THE-TITLE-OF-THE-PODCAST"]}>
                                                        {item.Title}
                                                    </Link>
                                                </Grid>

                                                <Grid item paddingTop={1} xs={12} className={classes["small-description"]}>
                                                    {item.Brief}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                    :
                                    <>
                                        {insightStoriesList?.slice(0, 1)?.map((item) => (
                                            <Grid item container key={item.Id} alignSelf="flex-start" md={3.85} sm={5.8} paddingBottom={5}>
                                                <Grid item className={classes["Rectangle-982"]} >
                                                    <Link to={`/Insight?Id=${item.Id}`}>
                                                        <img src={item.CoverPhoto && item.CoverPhoto?.URL} width="100%" height="100%" alt="cover image" />
                                                    </Link>
                                                </Grid>
                                                <Grid item container gap={1} alignSelf="flex-start" marginTop={2} className={classes["tech"]} >
                                                    {item.Tags?.map((tag, index) => (
                                                        <span key={index} className={classes["Rectangle-988"]}> {tag} </span>
                                                    ))}
                                                </Grid>

                                                <Grid item container alignSelf="flex-start" paddingTop={0.7} xs={12} >
                                                    <Link to={`/Insight?Id=${item.Id}`} className={classes["THE-TITLE-OF-THE-PODCAST"]}>
                                                        {item.Title}
                                                    </Link>
                                                </Grid>

                                                <Grid item paddingTop={1} xs={12} className={classes["small-description"]}>
                                                    {item.Brief}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>}

                            </Grid>

                            {windowWidth > 600 ? insightStoriesNum > 3 ?
                                <Grid item container paddingBottom={6} paddingTop={2} justifyContent="flex-end">
                                    <Grid item onMouseOver={() => { setInsightArrowSrc(nextBlueArrow) }} onMouseOut={() => { setInsightArrowSrc(nextBlackArrow) }} style={{ cursor: "pointer" }}>
                                        <Link to={"/Insights-List"}>

                                            <img src={insightArrowSrc} width="90%" alt="next arrow" />
                                        </Link>
                                    </Grid>
                                </Grid>
                                :
                                <></>
                                :
                                insightStoriesNum > 1 ?
                                    <Grid item container paddingBottom={6} paddingTop={2} justifyContent="flex-end">
                                        <Grid item onMouseOver={() => { setInsightArrowSrc(nextBlueArrow) }} onMouseOut={() => { setInsightArrowSrc(nextBlackArrow) }} style={{ cursor: "pointer" }}>
                                            <Link to={"/Insights-List"}>
                                                <img src={insightArrowSrc} width="90%" alt="next arrow" />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    :
                                    <></>
                            }

                        </Grid> :
                            <></>}

                        {btsStoriesNum > 0 ? <Grid item paddingTop={{ xs: insightStoriesNum ? 5 : 0, sm: insightStoriesNum ? 17 : 10 }}>
                            <Grid item container >
                                <Grid item xs={12} className={classes["BEHIND-THE-SCENE"]} paddingBottom={1} >
                                    BEHIND THE SCENE
                                </Grid>

                                <Grid item className={classes["Path-1168"]} />
                            </Grid>
                            <Grid item container paddingBottom={btsStoriesNum ? btsStoriesNum < 4 ? 10 : 0 : 0} paddingTop={3.5} gap={{ sm: 2, md: 1.5, lg: 2, xl: 2.4 }} justifyContent="center">
                                {windowWidth > 600 ?
                                    <>
                                        {btsStoriesList?.map((item) => (
                                            <Grid item container key={item.Id} alignSelf="flex-start" md={3.85} sm={5.8} paddingBottom={5}>
                                                <Grid item className={classes["Rectangle-982"]} >
                                                    <Link to={`/BTS?Id=${item.Id}`}>
                                                        <img src={item.CoverPhoto && item.CoverPhoto?.URL} width="100%" height="100%" alt="cover image" />
                                                    </Link>
                                                </Grid>
                                                <Grid item container gap={1} alignSelf="flex-start" marginTop={2} className={classes["tech"]} >
                                                    {item.Tags?.map((tag, index) => (
                                                        <span key={index} className={classes["Rectangle-988"]}> {tag} </span>
                                                    ))}
                                                </Grid>
                                                <Grid item container alignSelf="flex-start" paddingTop={0.7} xs={12} >
                                                    <Link to={`/BTS?Id=${item.Id}`} className={classes["THE-TITLE-OF-THE-PODCAST"]}>
                                                        {item.Title}
                                                    </Link>
                                                </Grid>

                                                <Grid item paddingTop={1} xs={12} className={classes["small-description"]}>
                                                    {item.Brief}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                    :
                                    <>
                                        {btsStoriesList?.slice(0, 1)?.map((item) => (
                                            <Grid item container key={item.Id} alignSelf="flex-start" md={3.85} sm={5.8} paddingBottom={5}>
                                                <Grid item className={classes["Rectangle-982"]} >
                                                    <Link to={`/BTS?Id=${item.Id}`}>
                                                        <img src={item.CoverPhoto && item.CoverPhoto?.URL} width="100%" height="100%" alt="cover image" />
                                                    </Link>
                                                </Grid>
                                                <Grid item container gap={1} alignSelf="flex-start" marginTop={2} className={classes["tech"]} >
                                                    {item.Tags?.map((tag, index) => (
                                                        <span key={index} className={classes["Rectangle-988"]}> {tag} </span>
                                                    ))}
                                                </Grid>
                                                <Grid item container alignSelf="flex-start" paddingTop={0.7} xs={12}>
                                                    <Link to={`/BTS?Id=${item.Id}`} className={classes["THE-TITLE-OF-THE-PODCAST"]}>
                                                        {item.Title}
                                                    </Link>
                                                </Grid>

                                                <Grid item paddingTop={1} xs={12} className={classes["small-description"]}>
                                                    {item.Brief}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>}

                            </Grid>
                            {windowWidth > 600 ? btsStoriesNum > 3 ?
                                <Grid item container paddingBottom={6} paddingTop={2} justifyContent="flex-end">
                                    <Grid item onMouseOver={() => { setBtsArrowSrc(nextBlueArrow) }} onMouseOut={() => { setBtsArrowSrc(nextBlackArrow) }} style={{ cursor: "pointer" }}>
                                        <Link to={"/BTS-List"}>
                                            <img src={btsArrowSrc} width="90%" alt="next arrow" />
                                        </Link>
                                    </Grid>
                                </Grid>
                                :
                                <></>
                                :
                                btsStoriesNum > 1 ?
                                    <Grid item container paddingBottom={6} paddingTop={2} justifyContent="flex-end">
                                        <Grid item onMouseOver={() => { setBtsArrowSrc(nextBlueArrow) }} onMouseOut={() => { setBtsArrowSrc(nextBlackArrow) }} style={{ cursor: "pointer" }}>
                                            <Link to={"/BTS-List"}>
                                                <img src={btsArrowSrc} width="90%" alt="next arrow" />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    :
                                    <></>
                            }

                        </Grid> :
                            <> </>}

                    </Grid>
                }

            </Grid>
        </>
    )
}

export default Home;