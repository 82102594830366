import React from "react";
import { useLottie } from "lottie-react";
import BodyAnimation1 from "../../Assets/Animations/BodyAnimation1";


export default function LoadingAnimation() {

  const options = {
    animationData: BodyAnimation1,
    loop: true,
    autoplay: true,
  };

  const style={
      height: '90%',
      width: '90%',
      padding: 0,
      right:0,      
  }

  const { View } = useLottie(options, style);

  return (
    <>
    
      {View}
    </>
  );
}